import { css, useTheme } from '@emotion/react'
import React, { useCallback, useMemo } from 'react'
import { UnstyledButton } from './button'

const Arrow: React.FC<{ color: string; className?: string }> = ({ color, className }) => {
  return (
    <span
      css={css`
        display: block;
        position: relative;
        width: 16px;
        height: 16px;
      `}
      className={className}
    >
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
        <g id="Ellipse_85" data-name="Ellipse 85" fill="none" stroke={color} strokeWidth="1">
          <circle cx="8" cy="8" r="8" stroke="none" />
          <circle cx="8" cy="8" r="7.5" fill="none" />
        </g>
        <path
          id="Path_984"
          data-name="Path 984"
          d="M2.83-4.22H-4.6v.84H2.83A7.431,7.431,0,0,0,1.34-1.86l.73.41A11.043,11.043,0,0,1,4.65-3.8,11.043,11.043,0,0,1,2.07-6.15l-.73.41A7.431,7.431,0,0,0,2.83-4.22Z"
          transform="translate(8 12)"
          fill={color}
        />
      </svg>
    </span>
  )
}

type PaginationItem = {
  page: number
  offsetStart: number
  offsetEnd: number
  isCurrent: boolean
  visible: boolean
}

export type PaginationParams = {
  offset: number
}

type PaginationButtonParams = {
  item: PaginationItem | null
  disabled?: boolean
  className?: string
  children?: React.ReactNode
  onClick: (params: PaginationParams) => void
}

const PaginationButton: React.FC<PaginationButtonParams> = ({ item, disabled, className, children, onClick }) => {
  const handleClick = useCallback<React.MouseEventHandler>(
    (e) => {
      e.preventDefault()

      if (item != null) {
        onClick({
          offset: item.offsetStart,
        })
      }
    },
    [onClick, item],
  )

  return (
    <UnstyledButton disabled={disabled} className={className} onClick={handleClick}>
      {children}
    </UnstyledButton>
  )
}

export type PageChangeHandler = (params: PaginationParams) => void

export type PaginationProps = {
  offset: number
  limit: number
  totalCount: number
  arround: number
  onChange: PageChangeHandler
}

export const Pagination: React.FC<PaginationProps> = ({ offset, limit, totalCount, arround, onChange }) => {
  if (totalCount <= 0) {
    throw new Error(`Invalid value ${totalCount} for the prop "total".`)
  }

  const { v1: theme } = useTheme()

  const items = useMemo<PaginationItem[]>(() => {
    return new Array(Math.ceil(totalCount / limit)).fill(null).map((_, i) => {
      const offsetStart = i * limit
      const offsetEnd = Math.min(i * limit + limit - 1, totalCount - 1)
      const isCurrent = offset >= offsetStart && offset <= offsetEnd

      return {
        page: i + 1,
        offsetStart,
        offsetEnd,
        isCurrent,
        visible: false,
      }
    })
  }, [offset, limit, totalCount])

  const currentIndex = items.findIndex((item) => item.isCurrent)

  if (currentIndex > -1) {
    items.forEach((item, i) => {
      if (currentIndex - arround <= i && currentIndex + arround >= i) {
        item.visible = true
      }
    })
  }

  const previous = items[currentIndex - 1] ?? null
  const next = items[currentIndex + 1] ?? null
  const colorEnabled = theme.baseColor.blue
  const colorDisabled = theme.baseColor.gray
  const previousColor = previous ? colorEnabled : colorDisabled
  const nextColor = next ? colorEnabled : colorDisabled

  return (
    <div
      css={css`
        display: flex;
        align-items: stretch;
        justify-content: space-between;
      `}
    >
      <div>
        <PaginationButton
          item={previous}
          css={css`
            ${theme.text.base};
            color: ${previousColor};
            display: flex;
            align-items: center;
            height: 100%;
          `}
          disabled={!previous}
          onClick={onChange}
        >
          <Arrow
            css={css`
              transform: rotate(180deg);
              margin-right: 0.6rem;

              @media (min-width: ${theme.breakpoint}) {
                transform: rotate(180deg) translateY(-0.07rem);
              }
            `}
            color={previousColor}
          />
          <span>BACK</span>
        </PaginationButton>
      </div>
      <div
        css={css`
          display: flex;
          justify-content: space-between;
        `}
      >
        {items
          .filter((item) => item.visible)
          .map((item) => (
            <span key={item.page}>
              <PaginationButton
                item={item}
                css={css`
                  ${theme.text.base};
                  color: ${item.isCurrent ? colorDisabled : colorEnabled};
                  padding: 0.5rem;
                  display: block;
                `}
                disabled={item.isCurrent}
                onClick={onChange}
              >
                {item.page}
              </PaginationButton>
            </span>
          ))}
      </div>
      <div>
        <PaginationButton
          item={next}
          css={css`
            ${theme.text.base};
            color: ${nextColor};
            display: flex;
            align-items: center;
            height: 100%;
          `}
          disabled={!next}
          onClick={onChange}
        >
          <span>NEXT</span>
          <Arrow
            css={css`
              margin-left: 0.6rem;
              transform: translateY(-0.13rem);

              @media (min-width: ${theme.breakpoint}) {
                transform: translateY(-0.08rem);
              }
            `}
            color={nextColor}
          />
        </PaginationButton>
      </div>
    </div>
  )
}
