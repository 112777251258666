import { css, useTheme } from '@emotion/react'
import React from 'react'
import { HeadingV1 } from './heading'
import { Inner, Section } from './layout'
import { Locale } from './locale'

export type CompanySectionProps = {
  className?: string
}

export const CompanySection: React.FC<CompanySectionProps> = ({ className }) => {
  const { v1: theme } = useTheme()

  return (
    <Section id="company" className={className}>
      <Inner>
        <HeadingV1>COMPANY</HeadingV1>
        <div
          css={css`
            table {
              ${theme.text.sm};
              ${theme.fontWeight.medium};
              border-collapse: collapse;
              width: 100%;
            }

            tr {
              border-bottom: 1px solid ${theme.baseColor.black};
            }

            th {
              white-space: nowrap;
              text-align: left;
              width: 20%;
            }

            td {
              padding-left: 0.5625rem;
            }

            th,
            td {
              padding-top: 0.5625rem;
              padding-bottom: 0.5625rem;
            }

            @media (min-width: ${theme.breakpoint}) {
              th {
                width: 34%;
              }
            }
          `}
        >
          <Locale lang="ja">
            <table>
              <tbody>
                <tr>
                  <th>会社概要</th>
                  <td>株式会社イノカ / Innoqua Inc.</td>
                </tr>
                <tr>
                  <th>代表者</th>
                  <td>高倉葉太</td>
                </tr>
                <tr>
                  <th>所在地</th>
                  <td>〒112-0004 東京都文京区後楽二丁目3番21号 住友不動産飯田橋ビル1階</td>
                </tr>
                <tr>
                  <th>設立</th>
                  <td>2019年4月</td>
                </tr>
                <tr>
                  <th>事業内容</th>
                  <td>環境コンサルティング / 教育サービス / AI開発</td>
                </tr>
                <tr>
                  <th>資本金</th>
                  <td>¥22,000,000</td>
                </tr>
              </tbody>
            </table>
          </Locale>
          <Locale lang="en">
            <table>
              <tbody>
                <tr>
                  <th>About Us</th>
                  <td>株式会社イノカ / Innoqua Inc.</td>
                </tr>
                <tr>
                  <th>Representative</th>
                  <td>Yota Takakura</td>
                </tr>
                <tr>
                  <th>Address</th>
                  <td>Sumitomo Fudosan Iidabashi Building 1F, 2-3-21 Koraku, Bunkyo-ku, Tokyo 112-0004, Japan</td>
                </tr>
                <tr>
                  <th>Founded</th>
                  <td>April 2019</td>
                </tr>
                <tr>
                  <th>Business</th>
                  <td>Environmental Consulting / Educational Services / AI Development</td>
                </tr>
                <tr>
                  <th>Capital Stock</th>
                  <td>¥22,000,000</td>
                </tr>
              </tbody>
            </table>
          </Locale>
        </div>
      </Inner>
    </Section>
  )
}
