import { css } from '@emotion/react'

const baseColor = {
  black: '#2b2b2b',
  white: '#ffffff',
  blue: '#009bff',
  paleBlue: '#edfdff',
  yellow: '#ffcd5d',
  green: '#0cc9c2',
  gray: '#aaaaaa',
} as const

const baseBackgroundColor = {
  light: {
    primary: baseColor.paleBlue,
    secondary: baseColor.white,
  },
  dark: {
    primary: baseColor.black,
  },
} as const

const breakpoint = '1154px' as const
const innerWidth = '1004px' as const
const baseTextSize = 16 as const

const basePadding = {
  breakpoint: {
    x: '18px',
    y: '18px',
  },
  x: '36px',
  y: '18px',
} as const

const createTextCss = ({ sizeS, sizeL, lineHeight }: { sizeS: number; sizeL: number; lineHeight: number }) => {
  return css`
    font-size: ${sizeS / baseTextSize}rem;
    line-height: ${lineHeight};
    letter-spacing: 0.1em;

    @media (min-width: ${breakpoint}) {
      font-size: ${sizeL / baseTextSize}rem;
    }
  `
}

export const theme = {
  text: {
    xxs: createTextCss({
      sizeS: 10,
      sizeL: 10,
      lineHeight: 1.8,
    }),
    xs: createTextCss({
      sizeS: 12,
      sizeL: 12,
      lineHeight: 1.8,
    }),
    sm: createTextCss({
      sizeS: 14,
      sizeL: 14,
      lineHeight: 1.8,
    }),
    base: createTextCss({
      sizeS: 14,
      sizeL: 16,
      lineHeight: 1.8,
    }),
    lg: createTextCss({
      sizeS: 22,
      sizeL: 27,
      lineHeight: 1.45,
    }),
    xl: createTextCss({
      sizeS: 27,
      sizeL: 32,
      lineHeight: 1.4,
    }),
  },
  fontWeight: {
    medium: css`
      font-weight: 500;
    `,
    bold: css`
      font-weight: 700;
    `,
  },
  color: {
    black: css`
      color: ${baseColor.black};
    `,
    white: css`
      color: ${baseColor.white};
    `,
  },
  colorMode: {
    light: css`
      background-color: ${baseBackgroundColor.light.primary};
    `,
    dark: css`
      background-color: ${baseBackgroundColor.dark.primary};
    `,
  },
  atBreakpoint: {
    showBlock: css`
      display: none;

      @media (min-width: ${breakpoint}) {
        display: block;
      }
    `,
    showFlex: css`
      display: none;

      @media (min-width: ${breakpoint}) {
        display: flex;
      }
    `,
    hide: css`
      @media (min-width: ${breakpoint}) {
        display: none;
      }
    `,
    replaceWithSpace: css`
      @media (min-width: ${breakpoint}) {
        content: '';

        ::after {
          content: ' ';
        }
      }
    `,
  },
  padding: (() => {
    const top = css`
      padding-top: ${basePadding.y};
    `
    const bottom = css`
      padding-bottom: ${basePadding.y};
    `
    const left = css`
      padding-left: ${basePadding.x};

      @media (min-width: ${breakpoint}) {
        padding-left: ${basePadding.breakpoint.x};
      }
    `
    const right = css`
      padding-right: ${basePadding.x};

      @media (min-width: ${breakpoint}) {
        padding-right: ${basePadding.breakpoint.x};
      }
    `

    return {
      x: css`
        ${left};
        ${right};
      `,
      y: css`
        ${top};
        ${bottom};
      `,
      top,
      right,
      bottom,
      left,
    }
  })(),
  margin: (() => {
    const top = css`
      margin-top: ${basePadding.y};
    `
    const bottom = css`
      margin-bottom: ${basePadding.y};
    `
    const left = css`
      margin-left: ${basePadding.x};

      @media (min-width: ${breakpoint}) {
        margin-left: ${basePadding.breakpoint.y};
      }
    `
    const right = css`
      margin-right: ${basePadding.x};

      @media (min-width: ${breakpoint}) {
        margin-right: ${basePadding.breakpoint.y};
      }
    `

    return {
      x: css`
        ${left};
        ${right};
      `,
      y: css`
        ${top};
        ${bottom};
      `,
      top,
      right,
      bottom,
      left,
    }
  })(),
  baseColor,
  baseBackgroundColor,
  basePadding,
  breakpoint,
  innerWidth,
  createTextCss,
} as const
