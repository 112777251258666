import React, { useEffect, useRef } from 'react'
import { css } from '@emotion/react'
import { useMatchQueryDeepCompare } from '../hooks/use-match-query'
import { useMergeRefs } from '../hooks/use-merge-refs'

export type SlippingImageSource = {
  src: string
  minWidth: number
  minHeight: number
  query?: string | null
}

export type SlippingImageProps = {
  src?: string
  minWidth?: number
  minHeight?: number
  sources?: SlippingImageSource[]
  responsive?: 'vertical' | 'horizontal' | 'none'
  className?: string
  amount?: number
}

export const SlippingImage = React.forwardRef<HTMLDivElement, SlippingImageProps>(function ZoomingImage(
  { src, minWidth, minHeight, sources = [], responsive = 'vertical', className, amount = 0.3 },
  ref,
) {
  const sources_: SlippingImageSource[] = (() => {
    if (src != null && minWidth != null && minHeight != null) {
      return [...sources, { src, minWidth, minHeight, query: null }]
    }

    return sources
  })()

  const containerRef = useRef<HTMLDivElement | null>(null)
  const imageRef = useRef<HTMLDivElement | null>(null)

  useMatchQueryDeepCompare(() => {
    return sources_.map((options) => ({
      query: options.query,
      onMatch: () => {
        const container = containerRef.current
        const image = imageRef.current

        if (container == null || image == null) {
          return
        }

        if (responsive === 'vertical') {
          container.style.width = '100%'
          container.style.height = 'auto'
        } else if (responsive === 'horizontal') {
          container.style.width = 'auto'
          container.style.height = '100%'
        } else {
          container.style.width = `${options.minWidth}px`
          container.style.height = `${options.minHeight}px`
        }

        container.style.minWidth = `${options.minWidth}px`
        container.style.minHeight = `${options.minHeight}px`
        container.style.aspectRatio = `${options.minWidth} / ${options.minHeight}`

        image.style.backgroundImage = `url(${options.src})`
      },
    }))
  }, [responsive, sources_])

  useEffect(() => {
    const run = () => {
      const container = containerRef.current
      const image = imageRef.current

      if (container == null || image == null) {
        return
      }

      const rect = container.getBoundingClientRect()
      const start = -rect.height
      const end = window.innerHeight
      const isInViewport = rect.top > start && rect.top < end

      if (!isInViewport) {
        return
      }

      const current = (end - rect.top) / (end - start)
      const distance = rect.height * amount * current
      const scale = (rect.height + rect.height * amount) / rect.height

      image.style.transform = `translateY(${distance}px) scale(${scale})`
    }

    run()

    window.addEventListener('scroll', run)
    window.addEventListener('resize', run)

    return () => {
      window.removeEventListener('scroll', run)
      window.removeEventListener('resize', run)
    }
  }, [amount])

  return (
    <div
      ref={useMergeRefs(ref, containerRef)}
      css={css`
        position: relative;
        overflow: hidden;
      `}
      className={className}
    >
      <div
        ref={imageRef}
        css={css`
          width: 100%;
          height: 100%;
          background-repeat: no-repeat;
          background-position: center;
          background-size: cover;
          transform-origin: bottom;
        `}
      />
    </div>
  )
})
