import React, { useMemo } from 'react'
import { ContactSectionV1 } from './contact-section'
import { css, useTheme } from '@emotion/react'
import { I18nLink } from '../components/i18n-link'
import {
  EmailLink,
  AccessLink,
  MediaKitLink,
  XLink,
  InstagramLink,
  FacebookLink,
  PrivacyPolicyLink,
  CompanyLink,
  useLinkColorScheme,
} from './links'

export type FooterProps = {
  className?: string
}

export const FooterV1: React.FC<FooterProps> = ({ className }) => {
  return (
    <footer className={className}>
      <ContactSectionV1 />
    </footer>
  )
}

export const Footer: React.FC<FooterProps> = ({ className }) => {
  const { v2: theme } = useTheme()
  const { styles: linkStyles } = useLinkColorScheme('dark')

  const styles = useMemo(() => {
    return {
      container: css`
        display: flex;
        flex-direction: column;
        padding: 59px 0;
        gap: 100px;

        @media (min-width: ${theme.breakpoint}) {
          flex-direction: row;
          justify-content: space-between;
          align-items: end;
          gap: 0;
        }
      `,
      links: css`
        display: flex;
        flex-direction: column;
        gap: 20px;

        @media (min-width: ${theme.breakpoint}) {
          width: 255px;
        }
      `,
      externalLinks: css`
        display: grid;
        grid-auto-flow: column;
        grid-template-rows: repeat(3, 1fr);
        grid-template-columns: repeat(2, 1fr);
        gap: 20px 9px;

        @media (min-width: ${theme.breakpoint}) {
          gap: 20px 82px;
        }
      `,
      sectionGroup: css`
        display: flex;
        flex-direction: column;
        gap: 50px;

        @media (min-width: ${theme.breakpoint}) {
          flex-direction: row;
          align-items: end;
          gap: 0;
        }
      `,
      misc: css`
        display: flex;
      `,
      miscInner: css`
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 9px;
        width: 100%;

        @media (min-width: ${theme.breakpoint}) {
          gap: 65px;
          width: auth;
        }
      `,
      linksTitle: css`
        ${theme.text.sm};
        ${theme.fontWeight.bold};
        color: ${theme.baseColor.white};
        margin: 0;
      `,
    }
  }, [theme])

  return (
    <footer css={styles.container} className={className}>
      <div css={styles.sectionGroup}>
        <section css={styles.links}>
          <div>
            <I18nLink css={linkStyles.link} to="/projects-and-services">
              Projects & Services
            </I18nLink>
          </div>
          <div>
            <I18nLink css={linkStyles.link} to="/thinking">
              Thinking
            </I18nLink>
          </div>
          <div>
            <I18nLink css={linkStyles.link} to="/articles/s-jrv8z0ql">
              Careers
            </I18nLink>
          </div>
          <div>
            <I18nLink css={linkStyles.link} to="/articles">
              Articles
            </I18nLink>
          </div>
        </section>
        <section css={styles.externalLinks}>
          <div>
            <EmailLink colorScheme="dark" />
          </div>
          <div>
            <AccessLink colorScheme="dark" />
          </div>
          <div>
            <MediaKitLink colorScheme="dark" />
          </div>
          <div>
            <XLink colorScheme="dark" />
          </div>
          <div>
            <InstagramLink colorScheme="dark" />
          </div>
          <div>
            <FacebookLink colorScheme="dark" />
          </div>
        </section>
      </div>
      <section css={styles.misc}>
        <div css={styles.miscInner}>
          <div>
            <PrivacyPolicyLink colorScheme="dark" />
          </div>
          <div>
            <CompanyLink colorScheme="dark" />
          </div>
        </div>
      </section>
    </footer>
  )
}
