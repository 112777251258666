import { useTheme } from '@emotion/react'
import { Helmet } from 'react-helmet-async'
import { Breadcrumbs } from '../../components/breadcrumbs'
import { CaseAndVoiceSection } from '../../components/case-and-voice-section'
import { Locale } from '../../components/locale'
import {
  Service,
  Content,
  ContentBody,
  ContentImageContainer,
  ContentSolution,
  ContentFuture,
  ContentSubTitle,
  ContentTitle,
  HeroImage,
  HeroImageContent,
  HeroImageTitle,
  PinnedScrollPrompt,
} from '../../components/service'
import { FooterV1 } from '../../components/footer'
import { Header } from '../../components/header'
import { ServicesSection } from '../../components/services-section'
import { TechnologySection } from '../../components/technology-section'
import { Slideshow, SlideshowItemOption } from '../../components/slideshow'
import { useCallback, useMemo } from 'react'
import { Typewriter, TypewriterNewLineHandler } from '../../components/typewriter'
import { OuterV1 } from '../../components/layout'
import { useCaseAndVoices } from '../../hooks/use-case-and-voices'
import { darkBgClassName } from '../../constants'
import { useLocale } from '../../hooks/use-locale'
import { useTitle } from '../../hooks/use-title'

const overlayColor = 'rgba(0, 72, 116, 0.6)'
const serviceId = 'marine-clinical-trial-services'
const title = '海洋治験サービス'
const titleEn = 'Marine Clinical Trial Service'

export const MarineClinicalTrialServicesPage: React.FC = () => {
  const { v1: theme } = useTheme()
  const color = theme.baseColor.blue
  const locale = useLocale()
  const casesAndVoices = useCaseAndVoices(serviceId, locale)

  const slideshowItems = useMemo<SlideshowItemOption[]>(() => {
    return [
      {
        sources: [[null, `/images/${serviceId}-1d.jpg`]],
      },
      {
        sources: [[null, `/images/${serviceId}-2d.jpg`]],
      },
      {
        sources: [[null, `/images/${serviceId}-3d.jpg`]],
      },
    ]
  }, [])

  const handleNewLine = useCallback<TypewriterNewLineHandler>(
    (i) => {
      return i === 1 ? <br css={theme.atBreakpoint.hide} /> : <br />
    },
    [theme.atBreakpoint.hide],
  )

  const currentTitle = useTitle({
    ja: title,
    en: titleEn,
  })

  return (
    <>
      <Helmet>
        <title>{currentTitle}</title>
      </Helmet>
      <OuterV1>
        <Header darkBgSelector={`.${darkBgClassName}`} />
        <Service>
          <HeroImage
            $srcS={`/images/${serviceId}-hero-s.jpg`}
            $srcL={`/images/${serviceId}-hero-d.jpg`}
            $overlayColor={overlayColor}
            className={darkBgClassName}
          >
            <HeroImageTitle>
              <Locale lang="ja">
                <Typewriter
                  text={'海への優しさを、\n客観的に評価する時代が\nもう来ている。'}
                  onNewLine={handleNewLine}
                />
              </Locale>
              <Locale lang="en">
                <Typewriter text={'The time has already come to objectively evaluate the kindness to the sea.'} />
              </Locale>
            </HeroImageTitle>
            <HeroImageContent>
              <Locale lang="ja">
                「自然資本（Natural
                Capital）」という概念が近年急速に広がっており、経済学やファイナンスの先端領域として、自然が持つ価値をいかに社会経済システムに組み込むかが議論されています。2021年、国連主導で
                TNFD（Taskforce on Nature-related Financial
                Disclosure；自然関連財務情報開示タスクフォース）が発足したことを契機に、上場企業には今後、自然に関連する財務リスクや、事業活動が自然に対して与える影響を開示することが義務付けられる見通しとなりました。「生物多様性」は自然資本を構成する重要な要素であり、海洋生態系にとってインフラ機能を果たすサンゴへの影響評価は、海洋生物多様性を定量的に評価できる代替指標となるポテンシャルがあるのです。
              </Locale>
              <Locale lang="en">
                In recent years, the concept of natural capital has spread rapidly, and as a cutting-edge area of
                economics and finance, the method to incorporate the value of nature into socioeconomic systems is being
                discussed. In 2021, the United Nations-led Task Force on Nature-related Financial Disclosure (TNFD) was
                established, and it is expected that listed companies will be required to disclose financial risks and
                the impact of business activities on nature. Biodiversity is an important component of natural capital,
                and assessing the impact on corals, which serve an infrastructure function for marine ecosystems, has
                the potential to become an alternative indicator for assessing marine biodiversity quantitatively.
              </Locale>
            </HeroImageContent>
          </HeroImage>
          <PinnedScrollPrompt color={color} />
          <Content>
            <ContentSolution color={color}>
              <ContentTitle>
                <Locale lang="ja">{title}</Locale>
                <Locale lang="en">{titleEn}</Locale>
              </ContentTitle>
              <ContentImageContainer>
                <Slideshow items={slideshowItems} />
              </ContentImageContainer>
              <ContentSubTitle>
                <Locale lang="ja">
                  製品の生態系への
                  <br css={theme.atBreakpoint.hide} />
                  影響度を定量評価し、
                  <br />
                  サステナブルブランドを高める。
                </Locale>
                <Locale lang="en">Quantify the ecological impact of products and enhance sustainable brands.</Locale>
              </ContentSubTitle>
              <ContentBody>
                <Locale lang="ja">
                  「海洋治験サービス」とは、海洋生態系にポジティブ /
                  ネガティブな影響を与える可能性のある物質について、自然の海洋に添加する /
                  流出する前段階として、水槽環境で科学的に評価する、という事業コンセプトであり、創薬最終段階において新薬の候補となる物質の効果・安全性を試験する「治験」に見立てたものです。イノカは環境移送技術&reg;により、従来ラボ環境において飼育が困難だったサンゴ生体の取り扱いや、画像解析AIカメラやIoTによるパラメータ解析が可能となり、国内初の「サンゴに対する影響評価のための対照実験システム」を構築しました。日焼け止めのクリーム、洗剤・バス用品など消費財、船舶の表面塗装、衣類からのマイクロプラスチックなど、海洋に流出する可能性がある製品原料などがサンゴに与える影響を科学的エビデンスを取りながら解析・評価し、IR
                  / 研究開発 / 事業戦略等に活用可能なレポートとして提供いたします。
                </Locale>
                <Locale lang="en">
                  The concept of Marine Clinical Trial Service is to scientifically evaluate substances that may have
                  positive or negative impacts on marine ecosystems in an aquarium environment as a preliminary step
                  before they are added to or released into the ocean. This is analogous to a clinical trial to test the
                  efficacy and safety of a new drug candidate at the final stage of drug discovery. Through
                  environmental transportation technology, we have made it possible to handle coral organisms, which
                  were previously difficult to keep in a laboratory environment, and to analyze parameters using image
                  analysis AI cameras and the IoT, creating Japan's first system for evaluating effects on corals in
                  controlled experiments. The system analyzes and evaluates the impact on corals caused by raw materials
                  that may be discharged into the ocean, such as sunscreen, detergents, bath products and other consumer
                  goods, surface coating of ships, and microplastics. By utilizing this system, we will obtain
                  scientific evidence, and provide a report that can be used for IR, R&D, business strategy, etc.
                </Locale>
              </ContentBody>
            </ContentSolution>
            <ContentFuture color={color}>
              <ContentBody>
                <Locale lang="ja">
                  「自然資本（Natural
                  Capital）」という概念が近年急速に広がっており、経済学やファイナンスの先端領域として、自然が持つ価値をいかに社会経済システムに組み込むかが議論されています。2021年、国連主導で
                  TNFD（Taskforce on Nature-related Financial
                  Disclosure；自然関連財務情報開示タスクフォース）が発足したことを契機に、上場企業には今後、自然に関連する財務リスクや、事業活動が自然に対して与える影響を開示することが義務付けられる見通しとなりました。「生物多様性」は自然資本を構成する重要な要素であり、海洋生態系にとってインフラ機能を果たすサンゴへの影響評価は、海洋生物多様性を定量的に評価できる代替指標となるポテンシャルがあるのです。
                </Locale>
                <Locale lang="en">
                  While interest in biodiversity is growing as a global megatrend, especially with regard to marine
                  organisms, standards for safety assessment have yet to be fully established. Conversely, research and
                  development of solutions to make marine ecosystems sustainable must be prioritized. Japan is one of
                  the world's leading countries in terms of marine biodiversity resources, with approximately 450 of the
                  world's 800 reef-building coral species being distributed. In order to conserve the value of marine
                  biodiversity in terms of fisheries, coastal protection, tourism, and genetic resources, we expect
                  Japan to lead the world in research and development, including the establishment of research protocols
                  and safety certification, as well as rulemaking. The vision of the Marine Clinical Trial Service is to
                  gain the international competitiveness and initiative that only a maritime nation can achieve.
                </Locale>
              </ContentBody>
            </ContentFuture>
          </Content>
        </Service>
        <CaseAndVoiceSection headingColor={color} items={casesAndVoices} />
        <TechnologySection className={darkBgClassName} />
        <ServicesSection />
        <Breadcrumbs
          items={[
            {
              name: 'TOP',
              href: '/',
            },
            {
              name: 'SERVICE',
              href: '#services',
            },
            {
              name: (
                <span>
                  <Locale lang="ja">{title}</Locale>
                  <Locale lang="en">{titleEn}</Locale>
                </span>
              ),
              href: '',
            },
          ]}
        />
        <FooterV1 className={darkBgClassName} />
      </OuterV1>
    </>
  )
}
