import React, { useCallback } from 'react'

export function useMergeRefs<T>(...refs: (React.Ref<T> | React.MutableRefObject<T> | undefined)[]) {
  return useCallback((node: T): void => {
    refs.forEach((ref) => {
      if (typeof ref === 'function') {
        ref(node)
      } else if (ref != null) {
        ;(ref as React.MutableRefObject<T>).current = node
      }
    })
  }, refs) // eslint-disable-line react-hooks/exhaustive-deps
}
