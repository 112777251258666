import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { css, useTheme } from '@emotion/react'
import { useLoaderData } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'
import { motion } from 'framer-motion'
import { Outer } from '../components/layout'
import { Locale } from '../components/locale'
import { ArticleList } from '../components/articles'
import { FooterSection } from '../components/footer-section'
import { Header } from '../components/header'
import { Heading } from '../components/heading'
import { HalfRoundedButton } from '../components/button'
import { ContactSection } from '../components/contact-section'
import { Slideshow, SlideshowItemOption, SlideshowStyleInterpolationFn } from '../components/slideshow'
import { Summary, useMicroCms } from '../hooks/use-micro-cms'
import { logger } from '../helpers'
import { darkBgClassName } from '../constants'
import { useLocale } from '../hooks/use-locale'
import { useTitle } from '../hooks/use-title'

const secondaryContentAnimationVariants = {
  hidden: {
    opacity: 0,
  },
  enter: {
    opacity: 1,
  },
}

const secondaryContentAnimationTransition = {
  delay: 1.2,
  opacity: {
    duration: 1,
  },
}

const SecondaryContent: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return (
    <motion.div
      initial="hidden"
      animate="enter"
      transition={secondaryContentAnimationTransition}
      variants={secondaryContentAnimationVariants}
    >
      {children}
    </motion.div>
  )
}

const HeroImage: React.FC<{ className?: string }> = ({ className }) => {
  const { v2: theme } = useTheme()

  const items = useMemo<SlideshowItemOption[]>(() => {
    const prymaryContentStyle = css`
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      gap: 12px;
    `

    const primaryContentLineStyle = css`
      background-color: ${theme.baseColor.white};
      color: ${theme.baseColor.black};
      width: fit-content;
      padding: 0 2px;
      line-height: 1.2;
    `

    const createPrimaryContent = () => (
      <>
        <div css={css(prymaryContentStyle, theme.atBreakpoint.hide)}>
          <Locale lang="ja">
            <div css={primaryContentLineStyle}>人類の選択肢を増やし、</div>
            <div css={primaryContentLineStyle}>人も自然も栄える</div>
            <div css={primaryContentLineStyle}>世界をつくる。</div>
          </Locale>
          <Locale lang="en">
            <div css={primaryContentLineStyle}>Increase</div>
            <div css={primaryContentLineStyle}>Human options</div>
            <div css={primaryContentLineStyle}>and</div>
            <div css={primaryContentLineStyle}>Create a World</div>
            <div css={primaryContentLineStyle}>where both people</div>
            <div css={primaryContentLineStyle}>and nature</div>
            <div css={primaryContentLineStyle}>can be better</div>
          </Locale>
        </div>
        <div css={css(prymaryContentStyle, theme.atBreakpoint.showFlex)}>
          <Locale lang="ja">
            <div css={primaryContentLineStyle}>人類の選択肢を増やし、</div>
            <div css={primaryContentLineStyle}>人も自然も栄える世界をつくる。</div>
          </Locale>
          <Locale lang="en">
            <div css={primaryContentLineStyle}>Increase Human options and</div>
            <div css={primaryContentLineStyle}>Create a World where both people and nature can be better</div>
          </Locale>
        </div>
      </>
    )

    return [
      {
        sources: [
          [`(min-width: ${theme.breakpoint})`, '/images/hero/03-l.jpg'],
          [null, '/images/hero/03-s.jpg'],
        ],
        primaryContent: createPrimaryContent(),
        secondaryContent: <SecondaryContent>Service - 研究開発事業</SecondaryContent>,
      },
      {
        sources: [
          [`(min-width: ${theme.breakpoint})`, '/images/hero/02-l.jpg'],
          [null, '/images/hero/02-s.jpg'],
        ],
        primaryContent: createPrimaryContent(),
        secondaryContent: <SecondaryContent>Service - 教育・イベント事業</SecondaryContent>,
      },
      {
        sources: [
          [`(min-width: ${theme.breakpoint})`, '/images/hero/01-l.jpg'],
          [null, '/images/hero/01-l.jpg'],
        ],
        primaryContent: createPrimaryContent(),
        secondaryContent: (
          <SecondaryContent>
            <Locale lang="ja">Core technology - 環境移送技術&reg;</Locale>
            <Locale lang="en">Core technology - Biosphere transfer technology&reg;</Locale>
          </SecondaryContent>
        ),
      },
    ]
  }, [theme])

  const handleStyle = useCallback<SlideshowStyleInterpolationFn>(
    ({ active }) => {
      return css`
        transform-origin: center;
        transform: ${active ? 'scale(1.0)' : 'scale(1.32)'};
        transition: transform linear 9000ms;

        @media (min-width: ${theme.breakpoint}) {
          transform-origin: center 60%;
          transform: ${active ? 'scale(1.0)' : 'scale(1.1)'};
        }
      `
    },
    [theme],
  )

  return (
    <div
      css={css`
        ${theme.colorMode.dark};
        height: 100vh;
        position: relative;
      `}
      className={className}
    >
      <Slideshow items={items} showIndicator={false} style={handleStyle} />
    </div>
  )
}

type MainItemProps = {
  title: React.ReactNode
  description: React.ReactNode
  link: React.ReactNode
  imageSrcL: string
  imageSrcS: string
  imageAlt?: string
  className?: string
}

const MainContent: React.FC<MainItemProps> = ({
  title,
  description,
  link,
  imageSrcL,
  imageSrcS,
  imageAlt,
  className,
}) => {
  const { v2: theme } = useTheme()

  const styles = useMemo(() => {
    return {
      container: css`
        overflow: hidden;
        display: grid;
        grid-template-columns: 1fr 0;
        grid-auto-rows: min-content 1fr;
        padding: 0 ${theme.basePadding.x};
        gap: 30px;

        @media (min-width: ${theme.breakpoint}) {
          grid-template-columns: 310px 1fr;
          width: ${theme.innerWidth};
          margin-left: auto;
          margin-right: auto;
          padding: 0;
        }
      `,
      textContainer: css`
        grid-row: 1;
        grid-column: 1 / 3;

        @media (min-width: ${theme.breakpoint}) {
          height: 100%;
          grid-column: 1 / 2;
        }
      `,
      linkContainer: css`
        z-index: 2;
        grid-row: 2;
        grid-column: 1 / 2;
      `,
      linkContainerInner: css`
        display: flex;
        justify-content: flex-start;
        align-items: center;
        height: 100%;

        @media (min-width: ${theme.breakpoint}) {
          align-items: end;
        }
      `,
      imageContainer: css`
        z-index: 1;
        grid-row: 2;
        grid-column: 2 / 3;

        @media (min-width: ${theme.breakpoint}) {
          grid-row: 1 / 3;
          grid-column: 2;
        }
      `,
      imageContainerInner: css`
        position: relative;
        box-sizing: border-box;
        width: 126vw;

        @media (min-width: ${theme.breakpoint}) {
          width: 100%;
          left: 0;
        }
      `,
      title: css`
        ${theme.text.lg};
        ${theme.fontWeight.bold};
        margin-bottom: 20px;
      `,
      description: css`
        ${theme.text.sm};
        ${theme.fontWeight.medium};
      `,
      link: css`
        display: block;
        margin-top: 20px;
        min-width: 158px;

        @media (min-width: ${theme.breakpoint}) {
          min-width: 180px;
        }
      `,
      image: css`
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
        background-color: ${theme.baseColor.white};
        position: relative;
        left: -63%;
        margin-left: 36px;
        border-radius: 26.626016% / 50%;

        @media (min-width: ${theme.breakpoint}) {
          left: 0;
          margin-left: 0;
          border-radius: 28.915663% / 50%;
        }
      `,
    }
  }, [theme])

  return (
    <div css={styles.container} className={className}>
      <div css={styles.textContainer}>
        <div css={styles.title}>{title}</div>
        <div css={styles.description}>{description}</div>
      </div>
      <div css={styles.linkContainer}>
        <div css={styles.linkContainerInner}>{link}</div>
      </div>
      <div css={styles.imageContainer}>
        <div css={styles.imageContainerInner}>
          <picture>
            <source srcSet={imageSrcL} media={`(min-width: ${theme.breakpoint})`} />
            <img css={styles.image} src={imageSrcS} alt={imageAlt ?? ''} />
          </picture>
        </div>
      </div>
    </div>
  )
}

export const HomePage: React.FC = () => {
  const locale = useLocale()
  const { v2: theme } = useTheme()
  const microCms = useMicroCms()
  const loaderData = useLoaderData()

  if (loaderData) {
    const elementData = document.getElementById(`${loaderData}`)
    if (elementData) {
      const elementDataTop = window.scrollY + elementData.getBoundingClientRect().top
      window.scroll(0, elementDataTop)
    }
  }

  const [featuredArticles, setFeatureArticles] = useState<Summary[]>(microCms.createBlogSummariesPlaceholder(3))

  useEffect(() => {
    microCms
      .getBlogSummaries({ locale, limit: 3, featured: true })
      .then((featuredArticles_) => {
        setFeatureArticles(featuredArticles_.contents)
      })
      .catch((error) => logger.error(error))
  }, [microCms, locale])

  const currentTitle = useTitle()

  const styles = useMemo(() => {
    return {
      featuredArticlesContainer: css`
        padding: 0 ${theme.basePadding.x};
        margin-top: 97px;

        @media (min-width: ${theme.breakpoint}) {
          width: ${theme.innerWidth};
          margin-left: auto;
          margin-right: auto;
          padding: 0;
        }
      `,
      featuredArticles: css``,
      featuredArticlesLinkAbove: css`
        display: none;

        @media (min-width: ${theme.breakpoint}) {
          display: block;
        }
      `,
      featuredArticlesLinkBelow: css`
        display: flex;
        justify-content: center;
        margin-top: 50px;

        @media (min-width: ${theme.breakpoint}) {
          display: none;
        }
      `,
      mainContentHeading: css`
        margin: 0;
        margin-bottom: 20px;
      `,
      mainContents: css`
        margin-top: 100px;
        display: flex;
        flex-direction: column;
        gap: 100px;
      `,
    }
  }, [theme])

  return (
    <>
      <Helmet>
        <title>{currentTitle}</title>
      </Helmet>
      <Outer headerSpace={false}>
        <Header darkBgSelector={`.${darkBgClassName}`} hasDarkBgByDefault={true} />
        <HeroImage className={darkBgClassName} />
        <div css={styles.featuredArticlesContainer}>
          <Heading
            subContent="Featured articles"
            extraContent={
              <div css={styles.featuredArticlesLinkAbove}>
                <HalfRoundedButton href="/articles">すべての記事を読む</HalfRoundedButton>
              </div>
            }
          >
            <Locale lang="ja">注目されている記事</Locale>
            <Locale lang="en">Featured articles</Locale>
          </Heading>
          <ArticleList css={styles.featuredArticles} items={featuredArticles} />
          <div css={styles.featuredArticlesLinkBelow}>
            <HalfRoundedButton href="/articles">すべての記事を読む</HalfRoundedButton>
          </div>
        </div>
        <div css={styles.mainContents}>
          <MainContent
            title={
              <Heading css={styles.mainContentHeading} subContent="What we do" customMargin={true}>
                <Locale lang="ja">皆さんとできること</Locale>
                <Locale lang="en">What we do</Locale>
              </Heading>
            }
            description={
              <>
                <Locale lang="ja">
                  イノカは、海をはじめとした
                  <br />
                  水域の自然環境を、
                  <br />
                  水槽などを用いて陸地に再現する
                  <br />
                  環境移送ベンチャーです。
                  <br />
                  これらの独自技術と企業との連携により
                  <br />
                  環境課題の解決に取り組むと共に、
                  <br />
                  自然の面白さを広める
                  <br />
                  教育活動にも力を入れています。
                </Locale>
                <Locale lang="en">
                  Innoqua Inc. is an environmental transfer venture that reproduces the natural environment of oceans
                  and other water environments on land. Through its unique technology to reproduce natural environments
                  in aquariums and other locations, and through collaboration with companies, Innoqua Inc. is not only
                  working to solve environmental issues, but also focusing on educational activities to spread the
                  fascination and knowledge of nature.
                </Locale>
              </>
            }
            link={<HalfRoundedButton href="/projects-and-services">Projects & Services</HalfRoundedButton>}
            imageSrcS="/images/home/projects-and-services-s.jpg"
            imageSrcL="/images/home/projects-and-services-l.jpg"
            imageAlt="Projects & Services"
          />
          <MainContent
            title={
              <Heading css={styles.mainContentHeading} subContent="What we think" customMargin={true}>
                <Locale lang="ja">いつも考えていること</Locale>
                <Locale lang="en">What we think</Locale>
              </Heading>
            }
            description={
              <>
                <Locale lang="ja">
                  イノカを作っているのは、
                  <br />
                  生き物をこよなく
                  <br />
                  愛するメンバーたち。
                  <br />
                  大好きな生き物のために、
                  <br />
                  自然を理解し、
                  <br />
                  環境問題と向き合いたい
                  <br />
                  という地球規模の
                  <br />
                  大きなテーマを掲げています。
                </Locale>
                <Locale lang="en">
                  Innoqua Inc. is a group of people who love living creatures. We want to understand nature and face
                  environmental issues for the sake of our favorite creatures. This is a big theme that should be
                  considered on a global scale. Through Innoqua's research and education, we hope to take the first step
                  in this direction to understand and improve the environmental issues.
                </Locale>
              </>
            }
            link={<HalfRoundedButton href="/thinking">Thinking</HalfRoundedButton>}
            imageSrcS="/images/home/thinking-s.jpg"
            imageSrcL="/images/home/thinking-l.jpg"
            imageAlt="Thinking"
          />
          {/* TODO: Image */}
          <MainContent
            title={
              <Heading css={styles.mainContentHeading} subContent="Join the philosophy" customMargin={true}>
                <Locale lang="ja">一緒に働きませんか</Locale>
                <Locale lang="en">Join the philosophy</Locale>
              </Heading>
            }
            description={
              <>
                <Locale lang="ja">
                  イノカは生き物が好きという
                  <br />
                  共通の性質を持ちながらも
                  <br />
                  専門領域の違う、視点の異なる
                  <br />
                  メンバーによって構成されています。
                  <br />
                  共に挑戦を続ける中で
                  <br />
                  あなたにとっても
                  <br />
                  イノカにとっても
                  <br />
                  新しい世界が開かれていくはずです。
                </Locale>
                <Locale lang="en">
                  <p>
                    People in Innoqua are literally crazy about creatures, having different backgrounds and point of
                    views.
                  </p>
                  <p>Our challenges shall lead both you and Innoqua to a whole new world.</p>
                </Locale>
              </>
            }
            link={<HalfRoundedButton href="/articles/s-jrv8z0ql">Careers</HalfRoundedButton>}
            imageSrcS="/images/home/careers-s.jpg"
            imageSrcL="/images/home/careers-l.jpg"
            imageAlt="Careers"
          />
        </div>
        <ContactSection />
        <FooterSection className={darkBgClassName} />
      </Outer>
    </>
  )
}
