import React from 'react'
import { css, useTheme } from '@emotion/react'
import { I18nLink } from './i18n-link'

export type DetailLinkContentProps = {
  className?: string
  children: React.ReactNode
  color: string
}

export const DetailLinkContent: React.FC<DetailLinkContentProps> = ({ children, className, color }) => {
  const { v1: theme } = useTheme()

  return (
    <span
      css={css`
        ${theme.text.sm};
        ${theme.fontWeight.regular};
        line-height: 1.375rem;
        display: inline-flex;
        gap: 0.4375rem;
        text-align: center;
        color: ${color};
        border-bottom: 1px solid ${color};
      `}
      className={className}
    >
      <span>{children}</span>
      <span
        css={css`
          display: flex;
          align-items: center;
        `}
      >
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
          <g id="Ellipse_85" data-name="Ellipse 85" fill="none" stroke={color} strokeWidth="1">
            <circle cx="8" cy="8" r="8" stroke="none" />
            <circle cx="8" cy="8" r="7.5" fill="none" />
          </g>
          <path
            id="Path_984"
            data-name="Path 984"
            d="M2.83-4.22H-4.6v.84H2.83A7.431,7.431,0,0,0,1.34-1.86l.73.41A11.043,11.043,0,0,1,4.65-3.8,11.043,11.043,0,0,1,2.07-6.15l-.73.41A7.431,7.431,0,0,0,2.83-4.22Z"
            transform="translate(8 12)"
            fill={color}
          />
        </svg>
      </span>
    </span>
  )
}

export type DetailLinkProps = {
  href: string
} & DetailLinkContentProps

export const DetailLink: React.FC<DetailLinkProps> = ({ children, href, className, color }) => {
  return (
    <I18nLink
      css={css`
        text-decoration: none;
        cursor: pointer;
      `}
      to={href}
    >
      <DetailLinkContent color={color} className={className}>
        {children}
      </DetailLinkContent>
    </I18nLink>
  )
}
