import { useTheme } from '@emotion/react'
import { Helmet } from 'react-helmet-async'
import { Breadcrumbs } from '../../components/breadcrumbs'
import { CaseAndVoiceSection } from '../../components/case-and-voice-section'
import { Locale } from '../../components/locale'
import {
  Service,
  Content,
  ContentBody,
  ContentImageContainer,
  ContentSolution,
  ContentFuture,
  ContentSubTitle,
  ContentTitle,
  HeroImage,
  HeroImageContent,
  HeroImageTitle,
  PinnedScrollPrompt,
} from '../../components/service'
import { FooterV1 } from '../../components/footer'
import { Header } from '../../components/header'
import { ServicesSection } from '../../components/services-section'
import { TechnologySection } from '../../components/technology-section'
import { useCallback, useMemo } from 'react'
import { Slideshow, SlideshowItemOption } from '../../components/slideshow'
import { Typewriter, TypewriterNewLineHandler } from '../../components/typewriter'
import { OuterV1 } from '../../components/layout'
import { useCaseAndVoices } from '../../hooks/use-case-and-voices'
import { darkBgClassName } from '../../constants'
import { useLocale } from '../../hooks/use-locale'
import { useTitle } from '../../hooks/use-title'

const overlayColor = 'rgba(48, 115, 150, 0.7)'
const color = '#7ee0ed'
const serviceId = 'innovate-aquarium-festival'
const title = 'INNOVATE AQUARIUM FESTIVAL'
const titleEn = 'INNOVATE AQUARIUM FESTIVAL'

export const InnovateAquariumFestivalPage: React.FC = () => {
  const { v1: theme } = useTheme()
  const locale = useLocale()
  const casesAndVoices = useCaseAndVoices(serviceId, locale)

  const slideshowItems = useMemo<SlideshowItemOption[]>(() => {
    return [
      {
        sources: [
          [null, `/images/${serviceId}-1s.jpg`],
          [`(min-width: ${theme.breakpoint})`, `/images/${serviceId}-1d.jpg`],
        ],
      },
      {
        sources: [
          [null, `/images/${serviceId}-2s.jpg`],
          [`(min-width: ${theme.breakpoint})`, `/images/${serviceId}-1d.jpg`],
        ],
      },
      {
        sources: [
          [null, `/images/${serviceId}-3s.jpg`],
          [`(min-width: ${theme.breakpoint})`, `/images/${serviceId}-3d.jpg`],
        ],
      },
    ]
  }, [theme])

  const handleNewLine = useCallback<TypewriterNewLineHandler>(
    (i) => {
      return i === 0 || i === 2 ? <br css={theme.atBreakpoint.hide} /> : <br />
    },
    [theme.atBreakpoint.hide],
  )

  const currentTitle = useTitle({
    ja: title,
    en: titleEn,
  })

  return (
    <>
      <Helmet>
        <title>{currentTitle}</title>
      </Helmet>
      <OuterV1>
        <Header darkBgSelector={`.${darkBgClassName}`} />
        <Service>
          <HeroImage
            $srcS={`/images/${serviceId}-hero-s.jpg`}
            $srcL={`/images/${serviceId}-hero-d.jpg`}
            $overlayColor={overlayColor}
            className={darkBgClassName}
          >
            <HeroImageTitle>
              <Locale lang="ja">
                <Typewriter
                  text={'「趣味」だと\n社会に閉じるには、\nあまりに惜しい\nヒトとワザがある。'}
                  onNewLine={handleNewLine}
                />
              </Locale>
              <Locale lang="en">
                <Typewriter
                  text={
                    'There are people and techniques that are too good to be dismissed as "hobbyists" and "hobbies" by society.'
                  }
                  onNewLine={handleNewLine}
                />
              </Locale>
            </HeroImageTitle>
            <HeroImageContent>
              <Locale lang="ja">
                2019年、イノカ創業期のお話になりますが、アクアリウム好きのAI研究者高倉(現イノカCEO)と、現役アクアリストである増田(同CAO)の出逢いから全てが始まりました。増田の生き物に対する想いに心つかまれた高倉が、「それぞれの技術を組み合わせ、新しい環境保全の在り方を提案しませんか」と声をかけ、今に至ります。当時の高倉のように、地球課題の解決を模索しつつも、生き物に関する深い知見・技術が不足している企業や研究者は確かに存在します。一方、これまで「趣味として拡がってきた」アクアリウムが、「自分たちが好きな生き物や環境を良くする仕事に変わる」と考えるアクアリストは少なく、社会にもその認識が無いのが現状です。
              </Locale>
              <Locale lang="en">
                Innoqua started back in 2019, when Takakura, an aquarium-loving AI researcher and the current CEO, met
                Masuda, an active aquarist and the current CAO. Takakura, who was touched by Masuda's passion for living
                creatures, asked Masuda if they could propose a new way of environmental conservation by combining their
                respective technologies, which lead to the foundation of the company. There are certainly companies and
                researchers who seek solutions to global issues, but unlike Takakura, they lack in-depth knowledge and
                technologies related to living organisms. On the other hand, very few aquarists think that aquariums,
                which have expanded as a hobby, will turn into a job that improves the environment and the living
                creatures they love, and the same applies to the society.
              </Locale>
            </HeroImageContent>
          </HeroImage>
          <PinnedScrollPrompt color={color} />
          <Content>
            <ContentSolution color={color}>
              <ContentTitle>
                <Locale lang="ja">{title}</Locale>
                <Locale lang="en">{titleEn}</Locale>
              </ContentTitle>
              <ContentImageContainer>
                <Slideshow items={slideshowItems} />
              </ContentImageContainer>
              <ContentSubTitle>
                <Locale lang="ja">
                  アクアリストの技術や知見を、
                  <br />
                  「発掘・育成・共有」する仕組みづくり。
                </Locale>
                <Locale lang="en">
                  Creating a system to discover, nurture, and share the skills and knowledge of aquarists.
                </Locale>
              </ContentSubTitle>
              <ContentBody>
                <Locale lang="ja">
                  「INNOVATE AQUARIUM
                  FESTIVAL」は、年に一回開かれる、アクアリウムのまだ見ぬ可能性を探求するイベント型アクアリスト・プラットフォームです。生き物への福祉性を担保した上で独自の飼育技術や水槽構成に挑戦する多様な価値観のアクアリストが一同に介し、パネル展示による発表やディスカッションを行います。また展示だけでなく、成人のアクアリストを対象にロールモデルとなるような人を発掘する「INNOVATE
                  AQUARIUM AWARD」、学生以下を対象に次世代のアクアリストを育成する「INNOVATE AQUARIUM
                  EGG」も年間通して開催されます。会場にはアクアリウム業界以外の企業の方や研究者も訪れ、アクアリストとのマッチングにも取り組みます。
                </Locale>
                <Locale lang="en">
                  "INNOVATE AQUARIUM FESTIVAL" is an event-type platform for aquarists, which is held once a year to
                  explore the undiscovered potential of aquariums. In the event, Aquarists with diverse perspectives,
                  who pursue their own breeding techniques and aquarium configurations while ensuring the welfare of
                  living creatures, gather together for panel presentations and discussions. In addition to the
                  exhibitions, the "INNOVATE AQUARIUM AWARD" will be held throughout the year to discover role models
                  for adult aquarists, and the "INNOVATE AQUARIUM EGG" will be held for youths to foster the next
                  generation of aquarists. Business people and researchers from outside the aquarium industry will also
                  visit the venue and work to connect with aquarists.
                </Locale>
              </ContentBody>
            </ContentSolution>
            <ContentFuture color={color}>
              <ContentBody>
                <Locale lang="ja">
                  本イベントの前身として2022年に開かれた「INNOVATE AQUARIUM
                  AWARD」では、想定を超える数のアクアリストの方々、またアクアリウム業界の域を超えた企業の皆様にご参加いただき、大きな盛り上がりと共に幕を閉じました。そして2023年からは「ただ競うのではなく、多様な価値観を受け入れ業界として進歩する」ことを目的に、企画をアップデート。目先の成果に捉われず、アクアリストの社会進出やジュニアアクアリストの育成を続け、彼らが憧れの仕事として世間に認知される未来を創造していきます。
                </Locale>
                <Locale lang="en">
                  The "INNOVATE AQUARIUM AWARD" held in 2022, which is the predecessor of this event, ended with a great
                  success, with the participation of a greater number of aquarists than we've expected, as well as
                  companies beyond the aquarium industry. From 2023, we will update the event with the aim of not just
                  competing, but embracing diverse values and making progress as an industry. We will continue to
                  promote the advancement of aquarists in society and the development of junior aquarists, and create a
                  future where aquarists are recognized by the public as a job they aspire to.
                </Locale>
              </ContentBody>
            </ContentFuture>
          </Content>
        </Service>
        <CaseAndVoiceSection headingColor={color} items={casesAndVoices} />
        <TechnologySection className={darkBgClassName} />
        <ServicesSection />
        <Breadcrumbs
          items={[
            {
              name: 'TOP',
              href: '/',
            },
            {
              name: 'SERVICE',
              href: '#services',
            },
            {
              name: (
                <span>
                  <Locale lang="ja">{title}</Locale>
                  <Locale lang="en">{titleEn}</Locale>
                </span>
              ),
              href: '',
            },
          ]}
        />
        <FooterV1 className={darkBgClassName} />
      </OuterV1>
    </>
  )
}
