import React, { useRef } from 'react'
import { css } from '@emotion/react'
import { useMatchQueryDeepCompare } from '../hooks/use-match-query'
import { useMergeRefs } from '../hooks/use-merge-refs'

export type ZoomingImageSource = {
  src: string
  minWidth: number
  minHeight: number
  query?: string | null
}

export type ZoomingImageProps = {
  src?: string
  minWidth?: number
  minHeight?: number
  sources?: ZoomingImageSource[]
  responsive?: 'vertical' | 'horizontal' | 'none'
  className?: string
}

export const ZoomingImage = React.forwardRef<HTMLDivElement, ZoomingImageProps>(function ZoomingImage(
  { src, minWidth, minHeight, sources = [], responsive = 'vertical', className },
  ref,
) {
  const sources_: ZoomingImageSource[] = (() => {
    if (src != null && minWidth != null && minHeight != null) {
      return [...sources, { src, minWidth, minHeight }]
    }

    return sources
  })()

  const containerRef = useRef<HTMLDivElement | null>(null)
  const imageRef = useRef<HTMLDivElement | null>(null)
  const defaultSource: ZoomingImageSource | undefined = sources_.find((s) => !s.query) ?? sources_[0]

  useMatchQueryDeepCompare(() => {
    return sources_.map((source) => ({
      query: source.query,
      onMatch: () => {
        const container = containerRef.current
        const image = imageRef.current

        if (container == null || image == null) {
          return
        }

        if (responsive === 'vertical') {
          container.style.width = '100%'
          container.style.height = 'auto'
        } else if (responsive === 'horizontal') {
          container.style.width = 'auto'
          container.style.height = '100%'
        } else {
          container.style.width = `${source.minWidth}px`
          container.style.height = `${source.minHeight}px`
        }

        container.style.minWidth = `${source.minWidth}px`
        container.style.minHeight = `${source.minHeight}px`
        container.style.aspectRatio = `${source.minWidth} / ${source.minHeight}`

        image.style.backgroundImage = `url(${source.src})`
      },
    }))
  }, [responsive, sources_])

  return (
    <div
      ref={useMergeRefs(ref, containerRef)}
      css={css`
        position: relative;
        overflow: hidden;
        min-width: ${defaultSource?.minWidth ?? 0};
        min-height: ${defaultSource?.minHeight ?? 0};
        aspect-ratio: ${defaultSource?.minWidth ?? 1} / ${defaultSource?.minHeight ?? 1};
      `}
      className={className}
    >
      <div
        ref={imageRef}
        css={css`
          width: 100%;
          height: 100%;
          background-color: #ffffff;
          background-repeat: no-repeat;
          background-position: center;
          background-size: cover;
          background-image: url(${defaultSource?.src});
          transition: transform 300ms linear;

          :hover {
            transform: scale(1.1);
          }
        `}
      />
    </div>
  )
})
