import React from 'react'
import { useTitle } from '../../hooks/use-title'
import { Layout } from './_layout'

export const Project01Page: React.FC = () => {
  const currentTitle = useTitle({
    ja: '地域を良くしていく',
    en: '地域を良くしていく',
  })

  return (
    <Layout
      title={currentTitle}
      intro1Title={<>地域を良くしていく</>}
      intro1SubTitle="Project 01"
      intro1Content={
        <>
          同じ地球で生きている限り、事業領域や生活圏を超えて、
          <br />
          すべての人が環境問題の当事者です。
          <br />
          また、だからこそ全員の力を結集させる必要があると考えます。
          <br />
          海を皮切りに地域を良化していく研究開発に取り組みながら、
          <br />
          地域住民の自然や生き物への感度をイベント等コミュニケーションを通して高め、
          <br />
          彼らに参加してもらうことで研究成果をより良いものにし、
          <br />
          最終的にはその成果を地域へと還元していく。
          <br />
          それが、イノカが皆さんと一緒にできることです。
        </>
      }
      detail1Title={<>サンゴ礁生態系の消失</>}
      detail2Title={<>藻場</>}
      detail3Title={<>干潟、フジツボ</>}
      intro2Title={<>企業と良くしていく</>}
      intro2SubTitle="Project 02"
      intro2Content={
        <>
          同じ地球で生きている限り、事業領域や生活圏を超えて、
          <br />
          すべての人が環境問題の当事者です。
          <br />
          また、だからこそ全員の力を結集させる必要があると考えます。
          <br />
          海を皮切りに地域を良化していく研究開発に取り組みながら、
          <br />
          地域住民の自然や生き物への感度をイベント等コミュニケーションを通して高め、
          <br />
          彼らに参加してもらうことで研究成果をより良いものにし、
          <br />
          最終的にはその成果を地域へと還元していく。
          <br />
          それが、イノカが皆さんと一緒にできることです。
          <br />
        </>
      }
      intro2LinkContent="地域を良くしていく"
      intro2LinkTo="/projects-and-services/project-02"
    />
  )
}
