import React from 'react'
import { css, Theme, useTheme } from '@emotion/react'
import styled from '@emotion/styled'
import { ScrollPrompt } from './scroll-prompt'
import { Inner } from './layout'
import { theme } from '../theme'

const _paddingCss = ({ v1: theme }: Theme) => css`
  ${theme.padding.x};
  padding-top: 0;
  padding-bottom: 0;

  @media (min-width: ${theme.breakpoint}) {
    padding: 0;
  }
`

const _DivBase = styled.div`
  ${(props) => _paddingCss(props.theme)};
`

const _H2Base = styled.h2`
  ${(props) => _paddingCss(props.theme)};
`

export const Service = styled.article`
  background-color: ${(props) => props.theme.v1.baseBackgroundColor.light.secondary};
`

type HeroImageProps = {
  $srcS: string
  $srcL: string
  $overlayColor: string
  $minHeightS?: string
  $minHeightL?: string
}

export const HeroImage = styled.header<HeroImageProps>`
  ${(props) => props.theme.v1.colorMode.dark};
  background-image: url(${(props) => props.$srcS});
  background-position: center;
  background-size: cover;
  position: relative;
  ${(props) => props.theme.v1.padding.x};
  padding-top: 5.375rem;
  padding-bottom: 5.375rem;
  color: #fff;
  min-height: ${(props) => props.$minHeightS ?? '550px'};
  display: flex;
  justify-content: center;
  flex-direction: column;

  ::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: ${(props) => props.$overlayColor};
  }

  @media (min-width: ${(props) => props.theme.v1.breakpoint}) {
    background-image: url(${(props) => props.$srcL});
    min-height: ${(props) => props.$minHeightL ?? '550px'};
  }
`

export const HeroImageTitle: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { v1: theme } = useTheme()

  return (
    <div
      css={css`
        position: relative;

        @media (min-width: ${theme.breakpoint}) {
          width: 671px;
          margin-left: auto;
          margin-right: auto;
        }
      `}
    >
      <div
        css={css`
          ${theme.text.base};
          ${theme.fontWeight.regular};
          margin-bottom: 0.75rem;
        `}
      >
        BACKGROUND
      </div>
      <h1
        css={css`
          ${theme.text.lg};
          ${theme.fontWeight.bold};
          margin-top: 0;
          margin-bottom: 1rem;
        `}
      >
        {children}
      </h1>
    </div>
  )
}

export const HeroImageContent = styled.div`
  ${(props) => props.theme.v1.text.base};
  ${(props) => props.theme.v1.fontWeight.medium};
  position: relative;

  @media (min-width: ${(props) => props.theme.v1.breakpoint}) {
    width: 671px;
    margin-left: auto;
    margin-right: auto;
  }
`

export type PinnedScrollPromptProps = {
  color: string
}

export const PinnedScrollPrompt: React.FC<PinnedScrollPromptProps> = ({ color }) => {
  const { v1: theme } = useTheme()

  return (
    <div
      css={css`
        display: flex;
        width: 100%;
        height: 0;
        justify-content: center;
      `}
    >
      <div
        css={css`
          position: relative;
          top: -25px;

          @media (min-width: ${theme.breakpoint}) {
            top: -50px;
          }
        `}
      >
        <div css={theme.atBreakpoint.hide}>
          <ScrollPrompt color={color} />
        </div>
        <div css={theme.atBreakpoint.showBlock}>
          <ScrollPrompt color={color} variant="long" />
        </div>
      </div>
    </div>
  )
}

export const Content = styled(Inner)`
  padding: 4.6875rem 0 2.625rem 0;

  @media (min-width: ${(props) => props.theme.v1.breakpoint}) {
    padding-top: 7rem;
  }
`

export type ContentSolutionProps = {
  color: string
  children: React.ReactNode
}

const ContentHeading = styled(_DivBase)<{ color: string }>`
  ${(props) => props.theme.v1.text.base};
  ${(props) => props.theme.v1.fontWeight.medium};
  color: ${(props) => props.color};
  margin-bottom: 0.75rem;
`

export const ContentSolution: React.FC<ContentSolutionProps> = ({ color, children }) => {
  const theme = useTheme()

  return (
    <div
      css={css`
        margin-bottom: 1.875rem;

        @media (min-width: ${theme.v1.breakpoint}) {
          ${ContentBody} {
            max-width: 672px;
          }

          ${ContentHeading} {
            text-align: center;
          }
        }
      `}
    >
      <ContentHeading color={color}>SOLUTION</ContentHeading>
      <div>{children}</div>
    </div>
  )
}

export type ContentFutureProps = {
  color: string
  children: React.ReactNode
}

export const ContentFuture: React.FC<ContentFutureProps> = ({ color, children }) => {
  const theme = useTheme()

  return (
    <div
      css={css`
        @media (min-width: ${theme.v1.breakpoint}) {
          max-width: 672px;
          margin-left: auto;
          margin-right: 0;
        }
      `}
    >
      <ContentHeading color={color}>FUTURE</ContentHeading>
      <div>{children}</div>
    </div>
  )
}

export const ContentTitle = styled(_H2Base)`
  ${(props) => props.theme.v1.text.xl};
  ${(props) => props.theme.v1.fontWeight.bold};
  margin: 0;
  margin-bottom: 2.2rem;

  @media (min-width: ${(props) => props.theme.v1.breakpoint}) {
    text-align: center;
  }
`

export const ContentImageContainer = styled.div`
  margin-bottom: 2.5rem;
  height: 220px;

  @media (min-width: ${(props) => props.theme.v1.breakpoint}) {
    height: 550px;
  }
`

export const ContentSubTitle = styled(_DivBase)`
  ${(props) => props.theme.v1.text.lg};
  ${(props) => props.theme.v1.fontWeight.bold};
  margin-bottom: 0.9375rem;
`

export const ContentBody = styled(_DivBase)`
  ${(props) => props.theme.v1.text.base};
  ${(props) => props.theme.v1.fontWeight.medium};

  p {
    margin: 0;
  }

  p + p {
    ${(props) => theme.v1.margin.top}
  }
`
