import { useEffect, useState } from 'react'
import { CaseAndVoice, useMicroCms } from './use-micro-cms'
import { logger } from '../helpers'

export const useCaseAndVoices = (serviceId: string, locale: string | undefined) => {
  const microCms = useMicroCms()
  const [casesAndVoices, setCasesAndVoices] = useState<CaseAndVoice[]>(microCms.createCasesAndVoicesPlaceholder(2))

  useEffect(() => {
    ;(async () => {
      const casesAndVoices_ = await microCms.getCasesAndVoices(serviceId, locale)

      setCasesAndVoices(casesAndVoices_.contents)
    })().catch((error) => logger.error(error))
  }, [microCms, serviceId, locale])

  return casesAndVoices
}
