import { useRef } from 'react'

export const useMemoOnce = <T>(factory: () => T): T => {
  const ref = useRef<T | undefined>(undefined)

  if (ref.current === undefined) {
    ref.current = factory()
  }
  return ref.current
}
