import React, { useMemo } from 'react'
import { css, useTheme } from '@emotion/react'
import { Heading } from '../components/heading'
import { HalfRoundedButton } from '../components/button'
import { emailAddress } from '../constants'
import { Locale } from './locale'

type ContactProps = {
  className?: string
}

export const Contact: React.FC<ContactProps> = ({ className }) => {
  const { v2: theme } = useTheme()

  const styles = useMemo(() => {
    return {
      container: css`
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: ${theme.baseColor.blue};
        padding: 35px 18px;
        border-radius: 10px;

        &::before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background-image: url(/images/logo-image-white.svg);
          background-repeat: no-repeat;
          background-position: center;
          background-size: 150%;
          opacity: 0.1;
          z-index: 1;
        }

        @media (min-width: ${theme.breakpoint}) {
          &::before {
            background-size: 70%;
          }
        }
      `,
      containerInner: css`
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        position: relative;
        z-index: 2;
      `,
      contentHeading: css`
        margin: 0;
        margin-bottom: 30px;
      `,
      headingContent: css`
        color: ${theme.baseColor.white};
      `,
      content: css`
        ${theme.text.xs};
        ${theme.fontWeight.bold};
        color: ${theme.baseColor.white};
        text-align: center;
        margin: 0;

        @media (min-width: ${theme.breakpoint}) {
          width: 400px;
        }
      `,
      linkButton: css`
        margin-top: 30px;
        color: ${theme.baseColor.white};
        border-color: ${theme.baseColor.white};
        background-color: transparent;
      `,
    }
  }, [theme])

  return (
    <div css={styles.container} className={className}>
      <div css={styles.containerInner}>
        <Heading
          css={styles.contentHeading}
          subContentCss={styles.headingContent}
          mainContentCss={styles.headingContent}
          subContent="Let’s Innoqua with us"
          customMargin={true}
          align="center"
        >
          <Locale lang="ja">お声がけをお待ちしています</Locale>
          <Locale lang="en">Let’s INNOQUA with us</Locale>
        </Heading>
        <Locale lang="ja">
          <p css={styles.content}>
            独自の「環境移送技術®︎」および、「海洋・水域生態系」「サステナビリティ推進」「環境エデュテインメント（Education
            × Entertainment）」の知見を組み合わせた、共同企画 / 調査研究・ラボ実験 /
            教育イベント企画運営等を承っております。
            <br />
            お問合せをお待ちしております。
          </p>
        </Locale>
        <Locale lang="en">
          <p css={styles.content}>
            Joint planning/research/laboratory experiments/educational event planning and management that combines our
            unique “Biosphere Transfer Technology®︎” and knowledge of “marine/aquatic ecosystems,” “sustainability
            promotion,” and “environmental edutainment (Education x Entertainment).” We accept all mentioned requests to
            improve the environment.
            <br />
            We look forward to be the partner with you.
          </p>
        </Locale>
        <HalfRoundedButton css={styles.linkButton} href={`mailto:${emailAddress}`}>
          Contact
        </HalfRoundedButton>
      </div>
    </div>
  )
}
