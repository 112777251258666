import { useLocale } from './use-locale'

const title: Record<string, string | undefined> = {
  ja: '株式会社イノカ 環境移送企業　-自然の価値を、人々に届ける-',
  en: 'Innoqua',
}

export const useTitle = (mainPart: Record<string, string | undefined> = {}) => {
  const locale = useLocale()
  const main = mainPart[locale]

  return [main, title[locale]].filter((part) => part).join(' | ')
}
