import React, { useMemo } from 'react'
import { css, useTheme } from '@emotion/react'
import { I18nLink } from '../components/i18n-link'
import styled from '@emotion/styled'

export type RoundedButtonV1Props = {
  href: string
  className?: string
  children: React.ReactNode
}

export const RoundedButtonV1: React.FC<RoundedButtonV1Props> = ({ children, href, className }) => {
  const { v1: theme } = useTheme()

  return (
    <I18nLink
      css={css`
        ${theme.fontWeight.regular};
        ${theme.text.sm};
        line-height: 2rem;
        display: inline-block;
        border-radius: 1.0625rem;
        border: 1px solid #2b2b2b;
        line-height: 2rem;
        padding: 0 1.5rem;
        min-width: 7.25rem;
        text-align: center;
        background-color: #fff;
        cursor: pointer;

        @media (min-width: ${theme.breakpoint}) {
          font-size: 0.875rem;
          line-height: 2.375rem;
          min-width: 14.875rem;
          border-radius: 1.1875rem;
        }
      `}
      className={className}
      to={href}
    >
      <span
        css={css`
          position: relative;
          top: -0.05rem;
        `}
      >
        {children}
      </span>
    </I18nLink>
  )
}

export const UnstyledButton = styled.button`
  border-style: none;
  background-color: transparent;
  padding: 0;
  cursor: pointer;
`

export type RoundedButtonProps = {
  href?: string
  onClick?: () => void
  className?: string
  children: React.ReactNode
}

export const RoundedButton: React.FC<RoundedButtonProps> = ({ children, href, onClick, className }) => {
  const { v2: theme } = useTheme()

  const styles = useMemo(() => {
    return {
      container: css`
        ${theme.text.xs};
        ${theme.fontWeight.bold};
        color: ${theme.baseColor.blue};
        box-sizing: border-box;
        line-height: 1;
        height: 35px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        padding: 0 40px;
        min-width: 158px;
        text-align: center;
        text-decoration: none;
        background-color: #fff;
        border: 1px solid ${theme.baseColor.blue};
        border-radius: 20px;
        cursor: pointer;

        @media (min-width: ${theme.breakpoint}) {
          height: 40px;
          min-width: 180px;
        }
      `,
    }
  }, [theme])

  if (!href) {
    return (
      <button css={styles.container} className={className} onClick={onClick}>
        {children}
      </button>
    )
  }

  return (
    <I18nLink css={styles.container} className={className} to={href}>
      {children}
    </I18nLink>
  )
}

export type HalfRoundedButtonProps = {
  href?: string
  onClick?: () => void
  className?: string
  children: React.ReactNode
}

export const HalfRoundedButton: React.FC<HalfRoundedButtonProps> = ({ children, href, onClick, className }) => {
  const { v2: theme } = useTheme()

  const styles = useMemo(() => {
    return {
      container: css`
        ${theme.text.xs};
        ${theme.fontWeight.bold};
        color: ${theme.baseColor.blue};
        box-sizing: border-box;
        line-height: 1;
        height: 35px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        padding: 0 40px;
        min-width: 158px;
        text-align: center;
        text-decoration: none;
        background-color: #fff;
        border: 1px solid ${theme.baseColor.blue};
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;
        cursor: pointer;

        @media (min-width: ${theme.breakpoint}) {
          height: 40px;
          min-width: 180px;
        }
      `,
    }
  }, [theme])

  if (!href) {
    return (
      <button css={styles.container} className={className} onClick={onClick}>
        {children}
      </button>
    )
  }

  return (
    <I18nLink css={styles.container} className={className} to={href}>
      {children}
    </I18nLink>
  )
}
