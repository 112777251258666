import React, { useRef } from 'react'
import { css } from '@emotion/react'
import { useMergeRefs } from '../hooks/use-merge-refs'
import { useMatchQueryDeepCompare } from '../hooks/use-match-query'

export type BgImageSource = {
  src: string
  minWidth: number
  minHeight: number
  query?: string | null
}

export type BgImageProps = {
  src?: string
  minWidth?: number
  minHeight?: number
  sources?: BgImageSource[]
  className?: string
}

export const BgImage = React.forwardRef<HTMLSpanElement, BgImageProps>(function BgImage(
  { src, minWidth, minHeight, sources = [], className },
  ref,
) {
  const sources_: BgImageSource[] = (() => {
    if (src != null && minWidth != null && minHeight != null) {
      return [...sources, { src, minWidth, minHeight }]
    }

    return sources
  })()

  const imageRef = useRef<HTMLSpanElement | null>(null)

  useMatchQueryDeepCompare(() => {
    return sources_.map((source) => ({
      query: source.query,
      onMatch: () => {
        const image = imageRef.current

        if (image == null) {
          return
        }

        image.style.minWidth = `${source.minWidth}px`
        image.style.minHeight = `${source.minHeight}px`
        image.style.aspectRatio = `${source.minWidth} / ${source.minHeight}`
        image.style.backgroundImage = source.src ? `url(${source.src})` : 'none'
      },
    }))
  }, [sources_])

  return (
    <span
      ref={useMergeRefs(ref, imageRef)}
      className={className}
      css={css`
        display: block;
        width: 100%;
        min-width: ${minWidth}px;
        min-height: ${minHeight}px;
        aspect-ratio: ${minWidth} / ${minHeight};
        background-image: url(${src});
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
      `}
    />
  )
})
