import React from 'react'
import { Link, LinkProps } from 'react-router-dom'
import { useBuildI18nUrl } from '../hooks/use-build-i18n-url'
import { isSameOrigin } from '../helpers'

export type I18nLinkProps = Omit<LinkProps, 'to'> & {
  to?: string
  locale?: string
  className?: string
}

export const I18nLink: React.FC<I18nLinkProps> = ({ locale, to, className, children, ...rest }) => {
  const buildI18nUrl = useBuildI18nUrl()
  const url = buildI18nUrl(to, locale)

  if (!isSameOrigin(url)) {
    delete rest.replace
    delete rest.state
    delete rest.reloadDocument
    delete rest.preventScrollReset
    delete rest.relative

    return (
      <a className={className} href={url} target="_blank" rel="noreferrer noopener" {...rest}>
        {children}
      </a>
    )
  }

  return (
    <Link className={className} to={buildI18nUrl(to, locale)} {...rest}>
      {children}
    </Link>
  )
}
