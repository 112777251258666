import { useTheme } from '@emotion/react'
import { Helmet } from 'react-helmet-async'
import { Breadcrumbs } from '../../components/breadcrumbs'
import { CaseAndVoiceSection } from '../../components/case-and-voice-section'
import { Locale } from '../../components/locale'
import {
  Service,
  Content,
  ContentBody,
  ContentImageContainer,
  ContentSolution,
  ContentFuture,
  ContentSubTitle,
  ContentTitle,
  HeroImage,
  HeroImageContent,
  HeroImageTitle,
  PinnedScrollPrompt,
} from '../../components/service'
import { FooterV1 } from '../../components/footer'
import { Header } from '../../components/header'
import { ServicesSection } from '../../components/services-section'
import { TechnologySection } from '../../components/technology-section'
import { useCallback, useMemo } from 'react'
import { Slideshow, SlideshowItemOption } from '../../components/slideshow'
import { Typewriter, TypewriterNewLineHandler } from '../../components/typewriter'
import { OuterV1 } from '../../components/layout'
import { useCaseAndVoices } from '../../hooks/use-case-and-voices'
import { darkBgClassName } from '../../constants'
import { useLocale } from '../../hooks/use-locale'
import { useTitle } from '../../hooks/use-title'

const overlayColor = 'rgba(111, 98, 0, 0.6)'
const serviceId = 'coral-kids-lab'
const title = 'サンゴ礁ラボ®'
const titleEn = 'Coral Reef Lab®'

export const CoralKidsLabPage: React.FC = () => {
  const { v1: theme } = useTheme()
  const color = theme.baseColor.yellow
  const locale = useLocale()
  const casesAndVoices = useCaseAndVoices(serviceId, locale)

  const slideshowItems = useMemo<SlideshowItemOption[]>(() => {
    return [
      {
        sources: [[null, `/images/${serviceId}-1d.jpg`]],
      },
      {
        sources: [[null, `/images/${serviceId}-2d.jpg`]],
      },
      {
        sources: [[null, `/images/${serviceId}-3d.jpg`]],
      },
    ]
  }, [])

  const handleNewLine = useCallback<TypewriterNewLineHandler>(
    (i) => {
      return i === 0 || i === 2 ? <br css={theme.atBreakpoint.hide} /> : <br />
    },
    [theme.atBreakpoint.hide],
  )

  const currentTitle = useTitle({
    ja: title,
    en: titleEn,
  })

  return (
    <>
      <Helmet>
        <title>{currentTitle}</title>
      </Helmet>
      <OuterV1>
        <Header darkBgSelector={`.${darkBgClassName}`} />
        <Service>
          <HeroImage
            $srcS={`/images/${serviceId}-hero-s.jpg`}
            $srcL={`/images/${serviceId}-hero-d.jpg`}
            $overlayColor={overlayColor}
            className={darkBgClassName}
          >
            <HeroImageTitle>
              <Locale lang="ja">
                <Typewriter
                  text={'海を守る\n未来の仲間を増やすには？\n環境教育をもっと身近に、面白く。'}
                  onNewLine={handleNewLine}
                />
              </Locale>
              <Locale lang="en">
                <Typewriter
                  text={
                    'How to increase the number of future researchers who protect the ocean? By Making environmental education more accessible and interesting.'
                  }
                />
              </Locale>
            </HeroImageTitle>
            <HeroImageContent>
              <Locale lang="ja">
                イノカが2020年から実施し、これまでにのべ6000名以上の子どもたちが参加した「サンゴ礁ラボ&reg;」は、生態系・環境問題・人と自然の共栄のシンボルであるサンゴという生き物を通じて、海や生き物のおもしろさや可能性、深刻な環境問題といった重要なテーマについて、楽しみながら学ぶことのできる小学生向けの体験型”環境エデュテインメント（Education
                ×
                Entertainment）”プログラムです。プログラム内では、通常では南国の海や研究施設でしか見ることができない本物のサンゴに実際に触れて白骨標本との比較ワークショップや、クイズ形式でのサンゴの生態学習など、子どもたちが楽しむことをベースに現代に不可欠な生態系をはじめとする自然について地球規模の視点での学びを得ることができるコンテンツを多数用意しております。
              </Locale>
              <Locale lang="en">
                The Coral Reef Lab&reg;, which Innoqua has been implementing since 2020 and in which more than 6,000
                children have participated to date, is a hands-on "Education × Entertainment" program for elementary
                school students to learn about important themes such as the joy and potentiality of the ocean and its
                organisms, and serious environmental issues through coral, which is the symbol of ecosystems,
                environmental issues, and the symbiosis between humans and nature. The program includes a workshop where
                children can touch real corals, which are usually available only in tropical seas or at research
                facilities, compare it with white bone specimens, as well as learning about coral ecology in a quiz
                format. The event offers a wide variety of contents for children to enjoy and learn about nature,
                including ecosystems that are indispensable to the modern age, from a global perspective.
              </Locale>
            </HeroImageContent>
          </HeroImage>
          <PinnedScrollPrompt color={color} />
          <Content>
            <ContentSolution color={color}>
              <ContentTitle>
                <Locale lang="ja">{title}</Locale>
                <Locale lang="en">{titleEn}</Locale>
              </ContentTitle>
              <ContentImageContainer>
                <Slideshow items={slideshowItems} />
              </ContentImageContainer>
              <ContentSubTitle>
                <Locale lang="ja">
                  SDGsネイティブの
                  <br css={theme.atBreakpoint.hide} />
                  子ども達を中心に
                  <br />
                  学びや地域とのつながりを生み出す場所へ。
                </Locale>
                <Locale lang="en">
                  We seek to make a place where children familiar with SDGs are at the center of learning and creating
                  connections with the community.
                </Locale>
              </ContentSubTitle>
              <ContentBody>
                <Locale lang="ja">
                  SDGsに注目が集まる今、リアルな場をもつ商業施設は、子ども達に環境教育を提供することで、持続可能な地域づくりを後押しすることができます。生き物の面白さや命の価値は
                  オンラインでは届けることができません。買い物がオンライン化していくいま、環境教育を商業施設で展開することで商業施設は「モノを買う場所からかけがえのない体験ができる場所」へと変わり、未来を担う子どもと家族に豊かな時間を提供することが可能となります。
                </Locale>
                <Locale lang="en">
                  At a time when the SDGs are attracting attention, commercial facilities can help create sustainable
                  communities by providing a place for environmental education to children. The fascination with living
                  things and the value of life cannot be delivered online. Since online shopping has gained popularity
                  nowadays, by developing environmental education at commercial facilities, commercial facilities can be
                  transformed from "places to buy things" to "places to have irreplaceable experiences" and provide rich
                  moments for the children and families who will be responsible for the future.
                </Locale>
              </ContentBody>
            </ContentSolution>
            <ContentFuture color={color}>
              <ContentBody>
                <Locale lang="ja">
                  2010年代を振り返ると、ひとつの切り口として、AIやブロックチェーンが一気に注目を浴びた”ITテクノロジーの時代”だったと言えるでしょう。”あらゆる社会システムにITテクノロジーの導入が急速に進んでいった時代”に、プログラミング教育が広がっていったのは必然だったと言えます。では2020年代はどうかというと、”サステナビリティの時代”が到来しているわけです。前段でも述べた通り、それは”あらゆる社会システムに環境対応が急速に組み込まれていく時代”です。こうした社会的文脈の類似性からも、環境教育は今後、プログラミング教育に匹敵するスピードで普及していくと期待されます。
                </Locale>
                <Locale lang="en">
                  Looking back on the 2010s, one can say that it was the "era of IT technology", when AI and blockchain
                  attracted a great deal of attention. It was inevitable that programming education would spread in this
                  era of rapid adoption of IT technology into all social systems. The 2020s, then, will see the arrival
                  of the "era of sustainability". Similar to the "era of IT technology", as mentioned in the previous
                  section, this is an era in which environmental responses will be rapidly integrated into all social
                  systems. Because of this similarity in social context, environmental education is expected to spread
                  at a speed comparable to that of programming education in the future.
                </Locale>
              </ContentBody>
            </ContentFuture>
          </Content>
        </Service>
        <CaseAndVoiceSection headingColor={color} items={casesAndVoices} />
        <TechnologySection className={darkBgClassName} />
        <ServicesSection />
        <Breadcrumbs
          items={[
            {
              name: 'TOP',
              href: '/',
            },
            {
              name: 'SERVICE',
              href: '#services',
            },
            {
              name: (
                <span>
                  <Locale lang="ja">{title}</Locale>
                  <Locale lang="en">{titleEn}</Locale>
                </span>
              ),
              href: '',
            },
          ]}
        />
        <FooterV1 className={darkBgClassName} />
      </OuterV1>
    </>
  )
}

export default CoralKidsLabPage
