import { css, useTheme } from '@emotion/react'
import React, { useMemo } from 'react'
import { BgImage } from './bg-image'
import { HeadingV1 } from './heading'
import { Contact } from './contact'
import { Locale } from './locale'
import { SocialMediaLinks } from './social-media'

type IconButtonProps = {
  href?: string
  children: React.ReactNode
  className?: string
  src: string
  width: number
  height: number
  offset?: number
}

const IconButton = React.forwardRef<HTMLAnchorElement, IconButtonProps>(function IconButton(
  { href, className, children, src, width, height, offset = 0 },
  ref,
) {
  const { v1: theme } = useTheme()

  return (
    <a
      ref={ref}
      css={css`
        ${theme.fontWeight.medium};
        ${theme.text.sm};
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 0.375rem;
        border-radius: 1.0625rem;
        border-width: 1px;
        border-style: solid;
        height: 2.125rem;
        width: 11.875rem;
        text-decoration: none;
        color: ${theme.baseColor.white};

        @media (min-width: ${theme.breakpoint}) {
          font-size: 0.875rem;
          line-height: 1.25rem;
          height: 2.5rem;
          width: 17.625rem;
          border-radius: 1.25rem;
        }
      `}
      className={className}
      href={href}
      target="_blank"
      rel="noreferrer"
    >
      <span
        css={css`
          display: flex;
          align-items: center;
          position: relative;
          top: ${offset}px;
        `}
      >
        <BgImage src={src} minWidth={width} minHeight={height} />
      </span>
      <span>{children}</span>
    </a>
  )
})

export const ContactSectionV1: React.FC = () => {
  const { v1: theme } = useTheme()

  return (
    <article
      id="contact"
      css={css`
        ${theme.colorMode.dark};
        ${theme.color.white};
        position: relative;
        ${theme.padding.x};
        ${theme.padding.y};
        padding-top: 5.625rem;
        background-image: url(/images/contact-s.jpg);
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;

        ::before {
          content: '';
          display: block;
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          background-color: rgba(0, 0, 0, 0.6);
        }

        @media (min-width: ${theme.breakpoint}) {
          background-image: url(/images/contact-d.jpg);
        }
      `}
    >
      <div
        css={css`
          position: relative;
        `}
      >
        <HeadingV1
          css={css`
            @media (min-width: 690px) {
              text-align: center;
            }
          `}
          $colorMode="dark"
        >
          CONTACT
        </HeadingV1>
        <div
          css={css`
            display: flex;
            flex-direction: column;
            gap: 0.625rem;
            align-items: center;
          `}
        >
          <div
            css={css`
              ${theme.text.base};
              ${theme.fontWeight.medium};
              margin-bottom: 3.125rem;

              p {
                margin: 0;
              }

              p + p {
                margin-top: 1.625rem;
              }

              @media (min-width: 690px) {
                text-align: center;
              }
            `}
          >
            <Locale lang="ja">
              <p>
                「海洋生態系」「SDGs推進」「AI・IoT分野」の知見を活用した
                <br
                  css={css`
                    @media (max-width: 689px) {
                      display: none;
                    }
                  `}
                />
                各種コンサルティング / 共同企画 / 共同研究 / システム開発を承っております。
              </p>
              <p>お問い合わせをお待ちしております。</p>
            </Locale>
            <Locale lang="en">
              <p>
                We offer various consulting, joint planning, joint research, and system development services
                <br
                  css={css`
                    @media (max-width: 689px) {
                      display: none;
                    }
                  `}
                />
                utilizing our knowledge in the marine ecosystem, SDGs promotion, and AI/IoT fields.
              </p>
              <p>We look forward to hearing from you.</p>
            </Locale>
          </div>
          <div
            css={css`
              display: flex;
              flex-direction: column;
              gap: 1.25rem;
              align-items: center;
              margin-bottom: 3.125rem;
            `}
          >
            <div>
              <IconButton
                css={css`
                  border-color: ${theme.baseColor.blue};
                  background-color: ${theme.baseColor.blue};
                `}
                href="mailto:Info@innoqua.jp"
                src="/images/icon-email.svg"
                width={18.551}
                height={13.286}
              >
                Info@innoqua.jp
              </IconButton>
            </div>
            <div>
              <IconButton
                css={css`
                  border-color: ${theme.baseColor.white};
                `}
                src="/images/press-kit.svg"
                href="https://drive.google.com/drive/folders/1y0vsw1IDPrTPT-wEsT0bKgjeQdIe8t4X"
                width={21}
                height={21}
                offset={-1}
              >
                <Locale lang="ja">プレスキットを活用する</Locale>
                <Locale lang="en">Utilizing the press kit</Locale>
              </IconButton>
            </div>
            <div>
              <IconButton
                css={css`
                  border-color: ${theme.baseColor.white};
                `}
                src="/images/icon-map.svg"
                href="https://maps.app.goo.gl/Z3xWZz6BoymGr1uw8?g_st=ic"
                width={14}
                height={18}
              >
                <Locale lang="ja">Google MAPをひらく</Locale>
                <Locale lang="en">Open Google Maps</Locale>
              </IconButton>
            </div>
          </div>
          <div
            css={css`
              ${theme.text.xs};
              ${theme.fontWeight.medium};
            `}
          >
            FOLLOW US
          </div>
          <div
            css={css`
              margin-bottom: 3.125rem;
            `}
          >
            <SocialMediaLinks theme="dark" />
          </div>
          <div>
            <small
              css={css`
                ${theme.fontWeight.medium};
                ${theme.text.xs};
                display: block;
                text-align: center;
              `}
            >
              &copy; Innoqua Inc. All Rights Reserved.
            </small>
          </div>
        </div>
      </div>
    </article>
  )
}

export type ContactSectionProps = {
  className?: string
}

export const ContactSection: React.FC<ContactSectionProps> = ({ className }) => {
  const { v2: theme } = useTheme()

  const styles = useMemo(() => {
    return {
      container: css`
        margin-top: 99px;
        display: flex;
        justify-content: center;
        overflow: hidden;
        padding: 0 18px;

        @media (min-width: ${theme.breakpoint}) {
          width: ${theme.innerWidth};
          margin-left: auto;
          margin-right: auto;
          margin-top: 153px;
          display: block;
        }
      `,
    }
  }, [theme])

  return (
    <section id="contact" css={styles.container} className={className}>
      <Contact />
    </section>
  )
}
