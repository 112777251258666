import { css, useTheme } from '@emotion/react'
import React, { useMemo } from 'react'
import { formatDate } from '../helpers'
import { I18nLink } from './i18n-link'

export type Article = {
  id: string
  title?: string
  date?: string
  category?: {
    name: string
  } | null
  featuredImage?: {
    src: string
  } | null
  image?: {
    src: string
  } | null
}

export type ArticleListItemProps = {
  item: Article
}

export const ArticleListItem: React.FC<ArticleListItemProps> = ({ item }) => {
  const { v2: theme } = useTheme()

  const styles = useMemo(() => {
    const contentPaddingX = '7px'

    return {
      container: css`
        position: relative;
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 15px;

        @media (min-width: ${theme.breakpoint}) {
          gap: 13px;
        }
      `,
      imageContainer: css`
        position: relative;
        box-sizing: border-box;
        width: 100%;
        border-radius: 10px;
        aspect-ratio: 302 / 163;

        @media (min-width: ${theme.breakpoint}) {
          aspect-ratio: 324 / 175;
        }

        overflow: hidden;
      `,
      image: css`
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
      `,
      pseudoImage: css`
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
        background-color: ${theme.baseColor.white};
      `,
      tag: css`
        ${theme.text.xs};
        ${theme.fontWeight.medium};
        display: inline-block;
        background-color: ${theme.baseColor.blue};
        color: ${theme.baseColor.white};
        height: 20px;
        line-height: 20px;
        padding: 0 10px;
        text-align: center;
        vertical-align: middle;
        min-width: 49px;
        border-radius: 5px;
        text-transform: capitalize;
      `,
      title: css`
        ${theme.text.sm};
        ${theme.fontWeight.medium};

        @media (min-width: ${theme.breakpoint}) {
          padding: 0 ${contentPaddingX};
        }
      `,
      date: css`
        ${theme.text.sm};
        ${theme.fontWeight.medium};
        line-height: 1;
      `,
      meta1: css`
        @media (min-width: ${theme.breakpoint}) {
          padding: 0 ${contentPaddingX};
        }
      `,
      meta2: css`
        @media (min-width: ${theme.breakpoint}) {
          padding: 0 ${contentPaddingX};
          padding-top: 2px;
        }
      `,
    }
  }, [theme])

  const imageSrc = item.image?.src ?? item.featuredImage?.src
  const dateStr = useMemo(() => (item.date ? formatDate(new Date(item.date)) : null), [item.date])
  const tags = []

  if (item.category) {
    tags.push(item.category.name.toLowerCase())
  }

  return (
    <div css={styles.container}>
      <div css={styles.imageContainer}>
        {imageSrc ? (
          <I18nLink to={`/articles/${item.id}`}>
            <img css={styles.image} src={imageSrc} alt={item.title ?? ''}></img>
          </I18nLink>
        ) : (
          <span css={styles.pseudoImage} />
        )}
      </div>
      {tags.length > 0 && (
        <div css={styles.meta1}>
          {tags.map((tag) => (
            <span key={tag} css={styles.tag}>
              {tag}
            </span>
          ))}
        </div>
      )}
      <div css={styles.title}>{item.title}</div>
      <div css={styles.meta2}>
        <span css={styles.date}>{dateStr}</span>
      </div>
    </div>
  )
}

export type ArticleListProps = {
  items: Article[]
  className?: string
}

export const ArticleList: React.FC<ArticleListProps> = ({ items, className }) => {
  const { v2: theme } = useTheme()

  const styles = useMemo(() => {
    return {
      container: css`
        display: grid;
        grid-template-columns: 1fr;
        gap: 30px;
        width: 100%;

        @media (min-width: ${theme.breakpoint}) {
          grid-template-columns: repeat(3, 1fr);
          gap: 16px;
        }
      `,
    }
  }, [theme])

  return (
    <div className={className} css={styles.container}>
      {items.map((item) => (
        <ArticleListItem key={item.id} item={item} />
      ))}
    </div>
  )
}
