import { theme as v1 } from './v1'
import { theme as v2 } from './v2'

export const theme = {
  v1,
  v2,
}

declare module '@emotion/react' {
  type _Theme = typeof theme

  interface Theme extends _Theme {}
}
