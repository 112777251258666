import React, { useMemo, useState } from 'react'
import { css, useTheme } from '@emotion/react'
import { BgImage } from './bg-image'
import { emailAddress } from '../constants'
import { I18nLink } from './i18n-link'

type ExternalIconLinkProps = {
  href?: string
  children: React.ReactNode
  className?: string
  src: string
  width: number
  height: number
  offset?: number
}

const ExternalIconLink = React.forwardRef<HTMLAnchorElement, ExternalIconLinkProps>(function IconButton(
  { href, className, children, src, width, height, offset = 0 },
  ref,
) {
  const { v1: theme } = useTheme()

  return (
    <a
      ref={ref}
      css={css`
        ${theme.text.sm};
        ${theme.fontWeight.bold};
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 7px;
        color: ${theme.baseColor.white};
        text-decoration: none;
      `}
      className={className}
      href={href}
      target="_blank"
      rel="noreferrer noopener"
    >
      <span>{children}</span>
      <span
        css={css`
          display: flex;
          align-items: center;
          position: relative;
          top: ${offset}px;
        `}
      >
        <BgImage src={src} minWidth={width} minHeight={height} />
      </span>
    </a>
  )
})

const ExternalLink: React.FC<{ href: string; children: React.ReactNode; className?: string }> = ({
  href,
  children,
  className,
}) => {
  const { v2: theme } = useTheme()

  return (
    <a
      css={css`
        ${theme.text.sm};
        ${theme.fontWeight.bold};
        color: ${theme.baseColor.white};
        text-decoration: none;
      `}
      className={className}
      href={href}
      target="_blank"
      rel="noreferrer noopener"
    >
      {children}
    </a>
  )
}

export type ColorScheme = 'dark' | 'light'

export const useLinkColorScheme = <T,>(colorScheme: ColorScheme, extra?: { dark: T; light: T }) => {
  const { v2: theme } = useTheme()
  const color = colorScheme === 'dark' ? 'white' : 'black'

  const [extraState] = useState(
    extra ?? {
      dark: undefined as T,
      light: undefined as T,
    },
  )

  return useMemo(() => {
    return {
      extra: colorScheme === 'dark' ? extraState.dark : extraState.light,
      colorScheme,
      color,
      styles: {
        link: css`
          ${theme.text.sm};
          ${theme.fontWeight.bold};
          color: ${theme.baseColor[color]};
          text-decoration: none;
        `,
        hidden: css`
          visibility: hidden;
        `,
      },
    }
  }, [theme, colorScheme, color, extraState])
}

export type LinkProps = {
  className?: string
  colorScheme: ColorScheme
}

export const EmailLink: React.FC<LinkProps> = ({ className, colorScheme }) => {
  const { styles, extra: src } = useLinkColorScheme(colorScheme, {
    light: '/images/icons/email-black.svg',
    dark: '/images/icons/email-white.svg',
  })

  return (
    <ExternalIconLink
      className={className}
      src={src}
      width={15.673}
      height={11.162}
      css={styles.link}
      href={`mailto:${emailAddress}`}
    >
      Contact
    </ExternalIconLink>
  )
}

export const AccessLink: React.FC<LinkProps> = ({ className, colorScheme }) => {
  const { styles, extra: src } = useLinkColorScheme(colorScheme, {
    light: '/images/icons/pin-black.svg',
    dark: '/images/icons/pin-white.svg',
  })

  return (
    <ExternalIconLink
      className={className}
      src={src}
      width={13.65}
      height={17.063}
      css={styles.link}
      href="https://maps.app.goo.gl/Z3xWZz6BoymGr1uw8?g_st=ic"
    >
      Access
    </ExternalIconLink>
  )
}

export const MediaKitLink: React.FC<LinkProps> = ({ className, colorScheme }) => {
  const { styles, extra: src } = useLinkColorScheme(colorScheme, {
    light: '/images/icons/folder-black.svg',
    dark: '/images/icons/folder-white.svg',
  })

  return (
    <ExternalIconLink
      className={className}
      src={src}
      width={16.676}
      height={12.41}
      css={styles.link}
      href="https://drive.google.com/drive/folders/1y0vsw1IDPrTPT-wEsT0bKgjeQdIe8t4X"
    >
      Media kit
    </ExternalIconLink>
  )
}

export const XLink: React.FC<LinkProps> = ({ className, colorScheme }) => {
  const { styles } = useLinkColorScheme(colorScheme)

  return (
    <ExternalLink css={styles.link} className={className} href="https://x.com/Innoqua_inc">
      X
    </ExternalLink>
  )
}

export const InstagramLink: React.FC<LinkProps> = ({ className, colorScheme }) => {
  const { styles } = useLinkColorScheme(colorScheme)

  return (
    <ExternalLink css={styles.link} className={className} href="https://www.instagram.com/innoqua_inc">
      Instagram
    </ExternalLink>
  )
}

export const FacebookLink: React.FC<LinkProps> = ({ className, colorScheme }) => {
  const { styles } = useLinkColorScheme(colorScheme)

  return (
    <ExternalLink
      css={styles.link}
      className={className}
      href="https://www.facebook.com/profile.php?id=100063594788948"
    >
      Facebook
    </ExternalLink>
  )
}

export const PrivacyPolicyLink: React.FC<LinkProps> = ({ className, colorScheme }) => {
  const { styles } = useLinkColorScheme(colorScheme)

  // TODO: Set the correct URL and replace the `styles.hidden` with `styles.link`.
  return (
    <I18nLink css={styles.hidden} className={className} to="/">
      Privacy policy
    </I18nLink>
  )
}

export const CompanyLink: React.FC<LinkProps> = ({ className, colorScheme }) => {
  const { styles } = useLinkColorScheme(colorScheme)

  // TODO: Set the correct URL
  return (
    <I18nLink css={styles.link} to="/">
      (c) Innoqua. Inc
    </I18nLink>
  )
}
