import React from 'react'
import { css, useTheme } from '@emotion/react'
import styled from '@emotion/styled'
import { I18nLink } from '../components/i18n-link'

const Separator = styled.span`
  margin-left: 0.5rem;
  margin-right: 0.4rem;
`

export type BreadcrumbsItem = {
  name: React.ReactNode
  href: string
}

export type BreadcrumbsProps = {
  items: BreadcrumbsItem[]
}

export const Breadcrumbs: React.FC<BreadcrumbsProps> = ({ items }) => {
  const { v1: theme } = useTheme()

  return (
    <div
      css={css`
        ${theme.colorMode.light};
        ${theme.text.sm};
        ${theme.fontWeight.medium};
        ${theme.padding.x};
        padding-top: 2.625rem;
        padding-bottom: 0.625rem;

        a {
          text-decoration: none;
          color: ${theme.baseColor.black};
        }
      `}
    >
      <div
        css={css`
          max-width: 1024px;
          margin: 0 auto;
        `}
      >
        {items.map(({ name, href }, i) => (
          <React.Fragment key={href}>
            {i !== 0 && <Separator>&gt;</Separator>}
            <I18nLink to={href}>{name}</I18nLink>
          </React.Fragment>
        ))}
      </div>
    </div>
  )
}
