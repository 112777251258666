import React, { useMemo } from 'react'
import { Footer } from './footer'
import { css, useTheme } from '@emotion/react'

export type FooterSectionProps = {
  className?: string
}

export const FooterSection: React.FC<FooterSectionProps> = ({ className }) => {
  const { v2: theme } = useTheme()

  const styles = useMemo(() => {
    return {
      container: css`
        position: relative;
        margin-top: 100px;
        background-color: ${theme.baseColor.black};
        padding: 0 ${theme.basePadding.x};
        background-repeat: no-repeat;
        background-size: cover;
        background-image: url(/images/footer-s.jpg);

        &::before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background-color: ${theme.baseColor.black};
          opacity: 0.6;
        }

        @media (min-width: ${theme.breakpoint}) {
          padding: 0;
          background-image: url(/images/footer-l.jpg);
        }
      `,
      footer: css`
        position: relative;

        @media (min-width: ${theme.breakpoint}) {
          width: ${theme.innerWidth};
          margin-left: auto;
          margin-right: auto;
        }
      `,
    }
  }, [theme])

  return (
    <section css={styles.container} className={className}>
      <Footer css={styles.footer} />
    </section>
  )
}
