import { css, useTheme } from '@emotion/react'
import React, { useMemo } from 'react'

export type Image = {
  id: string
  src?: string
  srcL?: string
  srcS?: string
  alt: string
}

export type ImageListItemProps = {
  item: Image
}

export const ImageListItem: React.FC<ImageListItemProps> = ({ item }) => {
  const { v2: theme } = useTheme()

  const styles = useMemo(() => {
    return {
      container: css`
        position: relative;
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 15px;

        @media (min-width: ${theme.breakpoint}) {
          gap: 13px;
        }
      `,
      imageContainer: css`
        position: relative;
        box-sizing: border-box;
        width: 100%;
        aspect-ratio: 303 / 154;

        @media (min-width: ${theme.breakpoint}) {
          aspect-ratio: 324 / 175;
        }

        overflow: hidden;
      `,
      image: css`
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 10px;
      `,
    }
  }, [theme])

  return (
    <div css={styles.container}>
      <div css={styles.imageContainer}>
        <picture>
          <source srcSet={item.srcL ?? item.src} media={`(min-width: ${theme.breakpoint})`} />
          <img css={styles.image} src={item.srcS ?? item.src} alt={item.alt} />
        </picture>
      </div>
    </div>
  )
}

export type ImageListProps = {
  items: Image[]
  horizontal?: boolean
  className?: string
}

export const ImageList: React.FC<ImageListProps> = ({ items, className, horizontal = false }) => {
  const { v2: theme } = useTheme()

  const styles = useMemo(() => {
    const defaultStyles = {
      container: css`
        display: grid;
        grid-template-columns: 1fr;
        gap: 30px;
        width: 100%;

        @media (min-width: ${theme.breakpoint}) {
          grid-template-columns: repeat(3, 1fr);
          gap: 16px;
        }
      `,
    }

    const horizontalStyles = {
      container: css`
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 18px;
        width: 100%;

        @media (min-width: ${theme.breakpoint}) {
          gap: 16px;
        }
      `,
    }

    return horizontal ? horizontalStyles : defaultStyles
  }, [theme, horizontal])

  return (
    <div className={className} css={styles.container}>
      {items.map((item) => (
        <ImageListItem key={item.id} item={item} />
      ))}
    </div>
  )
}
