import React, { useRef, useEffect } from 'react'
import { createPortal } from 'react-dom'

export type ClientPortalProps = {
  selector: string
  children: React.ReactNode
}

export const ClientPortal: React.FC<ClientPortalProps> = ({ children, selector }) => {
  const ref = useRef<HTMLElement | null>(null)

  useEffect(() => {
    ref.current = document.querySelector(selector)
  }, [selector])

  return ref.current ? createPortal(children, ref.current) : null
}
