import { useTheme } from '@emotion/react'
import React from 'react'
import { Banner } from './banner'
import { Locale } from './locale'

export type TechnologySectionProps = {
  className?: string
}

export const TechnologySection: React.FC<TechnologySectionProps> = ({ className }) => {
  const { v1: theme } = useTheme()

  return (
    <Banner
      heading={
        <span>
          CORE
          <br css={theme.atBreakpoint.replaceWithSpace} />
          TECHNOLOGY
        </span>
      }
      href="/about#core-technology"
      sources={[
        {
          query: `(min-width: ${theme.breakpoint})`,
          src: '/images/core-technology-d.jpg',
        },
        {
          src: '/images/core-technology-s.jpg',
        },
      ]}
      className={className}
    >
      <Locale lang="ja">環境移送技術&reg;</Locale>
      <Locale lang="en">
        Environmental
        <br />
        Transfer
        <br />
        Technology
      </Locale>
    </Banner>
  )
}
