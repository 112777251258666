/* eslint-disable react/jsx-pascal-case */

import { css, useTheme } from '@emotion/react'
import styled from '@emotion/styled'
import React from 'react'
import { HeadingV1 } from './heading'
import { Inner, Section } from './layout'
import { Locale } from './locale'
import { Facebook, Twitter } from './social-media'
import { BgImage } from './bg-image'

const MemberGroup = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 3.125rem 0.8125rem;

  @media (min-width: 690px) {
    grid-template-columns: repeat(3, 1fr);
    gap: 3.125rem 1.875rem;
  }
`

const Member = styled.div``

const MemberImageContainer = styled.div`
  margin-bottom: 0.9375rem;
`

const MemberTitle = styled.div`
  ${(props) => props.theme.v1.text.sm};
  ${(props) => props.theme.v1.fontWeight.medium};
  text-align: center;
  margin-bottom: 0.3rem;
`

const MemberName = styled.div`
  ${(props) => props.theme.v1.text.lg};
  ${(props) => props.theme.v1.fontWeight.bold};
  text-align: center;
`

const MemberNameEn = styled.div`
  ${(props) => props.theme.v1.text.base};
  ${(props) => props.theme.v1.fontWeight.medium};
  color: #aaaaaa;
  margin-top: -0.25rem;
  margin-bottom: 0.9375rem;
  text-align: center;
`

const MemberDescription = styled.div`
  ${(props) => props.theme.v1.text.sm};
  ${(props) => props.theme.v1.fontWeight.medium};
`

const MemberSocialMedia = styled.div`
  display: flex;
  gap: 8px;
  margin-top: 0.8125rem;

  @media (min-width: ${(props) => props.theme.v1.breakpoint}) {
    margin-top: 1.875rem;
  }
`

type MemberImageProps = {
  src?: string
  srcS?: string
  srcL?: string
}

const MemberImage: React.FC<MemberImageProps> = ({ src, srcS, srcL }) => {
  const { v1: theme } = useTheme()
  const srcS_ = srcS ?? src ?? ''
  const srcL_ = srcL ?? src ?? ''

  return (
    <BgImage
      sources={[
        {
          src: srcL_,
          minWidth: 320,
          minHeight: 192,
          query: `(min-width: ${theme.breakpoint})`,
        },
        {
          src: srcS_,
          minWidth: 163,
          minHeight: 110,
        },
      ]}
      css={css`
        background-color: ${theme.baseColor.gray};
      `}
    />
  )
}

const Member_Takakura: React.FC = () => {
  return (
    <Member>
      <MemberImageContainer>
        <MemberImage srcS="/images/members/takakura-s.jpg" srcL="/images/members/takakura-l.jpg" />
      </MemberImageContainer>
      <MemberTitle>Chief Executive Officer</MemberTitle>
      <MemberName>高倉葉太</MemberName>
      <MemberNameEn>Yota Takakura</MemberNameEn>
      <MemberDescription>
        <Locale lang="ja">
          1994年生まれ。兵庫県出身。東京大学工学部を卒業、同大学院暦本純一研究室で機械学習を用いた楽器の練習支援の研究を行う。2019年4月に株式会社イノカを設立。サンゴ礁をはじめとする海洋生態系を室内空間に再現する「環境移送技術&reg;」を構想し、研究開発を推進。2021年10月より一般財団法人
          ロートこどもみらい財団 理事に就任。同年、Forbes JAPAN「30 UNDER 30」に選出。
        </Locale>
        <Locale lang="en">
          Born in Hyogo Prefecture , Japan in 1994. He graduated from the Faculty of Engineering, Tokyo University, and
          conducted research on supporting musical instrument practice using machine learning supervised by Professor
          Junichi Rekimoto. He established Innoqua Inc. in April 2019, in which he conceived and promoted research and
          development of 'Biosphere transfer technology&reg;' to reproduce coral reefs and other marine ecosystems in
          indoor spaces. In October 2021, he became a director of the Rohto Children's Mirai Foundation. In the same
          year, he was selected for Forbes Japan's '30 Under 30’ list.
        </Locale>
      </MemberDescription>
      <MemberSocialMedia>
        <Twitter href="https://twitter.com/oboeob" />
        <Facebook href="https://www.facebook.com/snowdaffio.sax" />
      </MemberSocialMedia>
    </Member>
  )
}

const Member_Masuda: React.FC = () => {
  return (
    <Member>
      <MemberImageContainer>
        <MemberImage srcS="/images/members/masuda-s.jpg" srcL="/images/members/masuda-l.jpg" />
      </MemberImageContainer>
      <MemberTitle>Chief Aquarium Officer</MemberTitle>
      <MemberName>増田直記 </MemberName>
      <MemberNameEn>Naoki Masuda</MemberNameEn>
      <MemberDescription>
        <Locale lang="ja">
          1990年生まれ。栃木県出身。宇都宮工業高校卒業。前職は火力発電所の精密部品の鋳型職人。2019年に高倉と出会い、イノカを共同創業。世界初の「チーフ・アクアリウム・オフィサー（最高アクアリウム責任者）」に就任し、「生態圏エンジニア」という職種の一般化を提唱している。“海なし県”
          栃木・宇都宮の自宅に巨大サンゴ礁生態系水槽を所有する生粋のアクアリスト。3児の父。
        </Locale>
        <Locale lang="en">
          Born in Tochigi Prefecture, Japan in 1990. He graduated from Utsunomiya Technical High School. He previously
          worked as a moulder of precision components for thermal power plants. In 2019, he met Takakura and became
          co-founder Innoqua Inc. He achieved the distinction of being the world's first "Chief Aquarium Officer" and
          actively advocates for the generalization of the "ecosphere engineer" job title. He is a passionate aquarist
          and maintains a giant coral reef ecosystem tank in his home located in Utsunomiya, Tochigi, a prefecture
          without direct access to the sea. Additionally, he is a proud father of three children.
        </Locale>
      </MemberDescription>
      <MemberSocialMedia>
        <Twitter href="https://twitter.com/naoking3535" />
        <Facebook href="https://www.facebook.com/masudanaoki.masuda" />
      </MemberSocialMedia>
    </Member>
  )
}

const Member_Takeuchi: React.FC = () => {
  return (
    <Member>
      <MemberImageContainer>
        <MemberImage srcS="/images/members/takeuchi-s.jpg" srcL="/images/members/takeuchi-l.jpg" />
      </MemberImageContainer>
      <MemberTitle>Chief Operating Officer</MemberTitle>
      <MemberName>竹内四季</MemberName>
      <MemberNameEn>Shiki Takeuchi</MemberNameEn>
      <MemberDescription>
        <Locale lang="ja">
          1994年生まれ。鹿児島県出身。東京大学経済学部卒業。学生時代は障がい者雇用に関する先進企業事例を研究し、社会起業家を志す。人材系メガベンチャーでの営業経験を経て、2020年2月にCOOとしてイノカに合流し、事業開発・パブリックリレーションズ全般を管掌。将来の夢は「次世代の社会経済システム構築に寄与すること
          / “ブルーエコノミーの父“」。好きな映画は『オーシャンズ11』
        </Locale>
        <Locale lang="en">
          Born in Kagoshima Prefecture, Japan in 1994. He graduated from the Faculty of Economics, the University of
          Tokyo. During his time as a student, he conducted research on advanced corporate case studies focusing on the
          employment of people with disabilities, nurturing his aspirations of becoming a social entrepreneur. After
          that, he gained experience working in sales at a prominent human resources mega-venture before joining Innoqua
          Inc. in February 2020 as the COO. In his role, he manages overall business development and handles public
          relations. His dream for the future is to contribute to the construction of the next generation socio-economic
          system and be recognized as the "Father of the Blue Economy." His favorite film is Ocean's 11.
        </Locale>
      </MemberDescription>
      <MemberSocialMedia>
        <Twitter href="https://twitter.com/Shiki_Innoqua" />
        <Facebook href="https://www.facebook.com/shiki.takeuchi.7" />
      </MemberSocialMedia>
    </Member>
  )
}

const Member_Ueda: React.FC = () => {
  return (
    <Member>
      <MemberImageContainer>
        <MemberImage srcS="/images/members/ueda-s.jpg" srcL="/images/members/ueda-l.jpg" />
      </MemberImageContainer>
      <MemberTitle>Chief Technology Officer</MemberTitle>
      <MemberName>上田正人</MemberName>
      <MemberNameEn>Masato Ueda</MemberNameEn>
      <MemberDescription>
        <Locale lang="ja">
          1974年大阪府生まれ。関西大学工学部を卒業後、大阪大学大学院工学研究科で結晶粒界における相変態に関する研究を行い、博士（工学）を取得。大阪大学助手、関西大学准教授、英国ケンブリッジ大学
          客員研究員を経て、2017年4月より関西大学 化学生命工学部
          教授に就任。硬組織（骨・歯）に関連するバイオマテリアルの研究を行う。2022年６月よりCTOとしてイノカにジョイン。週末はアメリカンフットボール選手。
        </Locale>
        <Locale lang="en">
          Born in 1974 in Osaka, he graduated from the Faculty of Engineering at Kansai University. He obtained his PhD
          in Engineering from Osaka University, where he conducted research on phase transformation at grain boundaries.
          He has held positions as an assistant professor at Osaka University, an associate professor at Kansai
          University, and a visiting researcher at the University of Cambridge. In April 2017, he was appointed as a
          professor at the Faculty of Chemistry and Biotechnology, Kansai University. He conducts research on
          biomaterials related to hard tissues, such as bones and teeth. In June 2022, he joined Innoqua Inc. as the
          CTO. In his free time, he enjoys playing American football.
        </Locale>
      </MemberDescription>
      <MemberSocialMedia>
        <Twitter href="https://twitter.com/kandai_coral" />
      </MemberSocialMedia>
    </Member>
  )
}

const Member_Moriya: React.FC = () => {
  return (
    <Member>
      <MemberImageContainer>
        <MemberImage srcS="/images/members/moriya-s.jpg" srcL="/images/members/moriya-l.jpg" />
      </MemberImageContainer>
      <MemberTitle>Chief Design Officer</MemberTitle>
      <MemberName>守屋輝一</MemberName>
      <MemberNameEn>Kiichi Moriya</MemberNameEn>
      <MemberDescription>
        <Locale lang="ja">
          1995年埼玉県生まれ。法政大学大学院デザイン工学研究科SD専攻修了。自らも起業家として活動する経験を活かし、社内のあらゆる状況変化を考慮した流動的なデザイン支援に取り組む。JAMES
          DYSON AWARD日本最優秀・国際TOP20受賞、国際学術会議CHI論文採択など。
        </Locale>
        <Locale lang="en">
          Born in Saitama prefecture, Japan in 1995. He graduated from Hosei University's Graduate School of Design
          Engineering, specializing in the Department of SD (System Design). Utilizing his own experience as an
          entrepreneur, he focuses on providing fluid design support that considers all internal company dynamics and
          changes. He won the Japanese first-place award and the international top 20 award at the JAMES DYSON AWARD.
          Additionally, his CHI (Computer-Human Interaction) paper was accepted by the International Conference on
          Science and Technology.
        </Locale>
      </MemberDescription>
    </Member>
  )
}

const Member_Katunishi: React.FC = () => {
  return (
    <Member>
      <MemberImageContainer>
        <MemberImage srcS="/images/members/katunishi-s.jpg" srcL="/images/members/katunishi-l.jpg" />
      </MemberImageContainer>
      <MemberTitle></MemberTitle>
      <MemberName>勝西 菜子</MemberName>
      <MemberNameEn>Saiko Katunishi</MemberNameEn>
      <MemberDescription>
        <Locale lang="ja">
          1995年生まれ。神奈川県出身。青山学院大学総合政策学部卒業。新卒で上場企業に入社し、総務・営業事務を経験。2020年、転職活動中に出会ったイノカに可能性を感じ、メガバンクの内定を辞退して入社を決意。広報・事業開発・バックオフィスなど幅広い業務領域を担当するかたわら「海ぶどう事業」「海藻事業」の立ち上げ責任者も務める。将来の夢は「マーメイドラグーンをつくること」。
        </Locale>
        <Locale lang="en">
          Born in Kanagawa Prefecture, Japan in 1995. She graduated from Aoyama Gakuin University with a degree in
          Policy Studies. After graduation, she joined a listed company, where she gained experience in general affairs
          and sales roles. In 2020, during her job search, she recognized the potential of Innoqua Inc. and decided to
          turn down an offer from a megabank in order to join the company. In her role at Innoqua Inc., she is involved
          in various business areas, including public relations, business development, and back-office operations. She
          has taken charge of the 'sea grape business' and 'seaweed business' launches. Her dream for the future is to
          create a mermaid lagoon.
        </Locale>
      </MemberDescription>
    </Member>
  )
}

const Member_Matuura: React.FC = () => {
  return (
    <Member>
      <MemberImageContainer>
        <MemberImage srcS="/images/members/matuura-s.jpg" srcL="/images/members/matuura-l.jpg" />
      </MemberImageContainer>
      <MemberTitle></MemberTitle>
      <MemberName>松浦 京佑</MemberName>
      <MemberNameEn>Kyousuke Matuura</MemberNameEn>
      <MemberDescription>
        <Locale lang="ja">
          2002年生まれ。神奈川県出身。TCA東京ECO動物海洋専門学校
          水族館・アクアリウム専攻卒業。学生時代より「サンゴ礁ラボ&reg;」運営インターンとしてイノカに関わり、2022年10月、最年少の20歳で入社。イノカの掲げる“環境エデュテインメント“の拡大に貢献し、2023年5月より教育・イベント事業部長に就任。「世界中の人々の笑顔を10%増やす」を目標に、笑顔で奮闘中。
        </Locale>
        <Locale lang="en">
          Born in 2002 in Kanagawa Prefecture, he graduated from TCA Tokyo ECO Animal & Marine College with a major in
          aquarium studies. Since his student days, he has been involved with Innoqua as an intern in the management of
          the Coral Reef Lab&reg;. In October 2022, at the age of 20, he joined the company, becoming the youngest
          employee here. He has played a significant role in the expansion of Innoqua's environmental edutainment
          business. In May 2023, he was appointed as the head of the Education and Events Division. With a determined
          spirit, he is currently working diligently with the goal of « increasing the smiles of people all over the
          world by 10%. »
        </Locale>
      </MemberDescription>
    </Member>
  )
}
const Member_Pachoensuk: React.FC = () => {
  return (
    <Member>
      <MemberImageContainer>
        <MemberImage srcS="/images/members/pachoensuk-s.jpg" srcL="/images/members/pachoensuk-l.jpg" />
      </MemberImageContainer>
      <MemberTitle></MemberTitle>
      <MemberName>Pachoensuk Theeranukul</MemberName>
      <MemberNameEn>Pachoensuk Theeranukul</MemberNameEn>
      <MemberDescription>
        <Locale lang="ja">
          1990年生まれ。タイ王国出身。生物学博士。タイ王国トップ校のマヒドン大学生物学科を卒業後、チュラロンコン大学海洋科学科
          生物海洋科学専攻課程にて理学修士。海洋生態系と海洋集団遺伝学に関する論文を発表。2017年に来日し、静岡大学にてPh.D.取得。国立遺伝学研究所（NIG）で1年間、魚類の遺伝学に関するプロジェクトに従事。2023年3月に研究員としてイノカにジョイン。
        </Locale>
        <Locale lang="en">
          Born in Thailand in 1990, he obtained his PhD in Bioscience. He graduated from the Department of Biology at
          Mahidol University, which is considered one of Thailand's top schools. He further pursued his studies and
          completed his M.Sc. in Biological and Marine Sciences at the Department of Marine Sciences, Chulalongkorn
          University. His thesis focused on marine ecosystems and marine population genetics. In 2017, he came to Japan
          and successfully obtained his Ph.D. from Shizuoka University. Following his studies, he worked for a year on a
          fish genetics project at the National Institute of Genetics (NIG). In March 2023, he joined Innoqua as a
          researcher, bringing his expertise to the company.
        </Locale>
      </MemberDescription>
    </Member>
  )
}

const Member_Maru: React.FC = () => {
  return (
    <Member>
      <MemberImageContainer>
        <MemberImage src="/images/members/maru.jpg" />
      </MemberImageContainer>
      <MemberName>丸幸弘</MemberName>
      <MemberNameEn>Yukihiro Maru</MemberNameEn>
      <MemberDescription>
        <Locale lang="ja">
          2002年大学院在学中に理工系大学生・大学院生のみでリバネスを設立。町工場や大手企業等と連携したアジア最大級のベンチャーエコシステムの仕掛け人として、世界各地のディープテックを発掘し、地球規模の社会課題の解決に取り組む。株式会社ユーグレナをはじめとする多数のベンチャー企業の立ち上げにも携わる。主な著書に『ディープテックＤｅｅｐＴｅｃｈ　世界の未来を切り拓く「眠れる技術」』（日経ＢＰ）などがある。
        </Locale>
        <Locale lang="en">
          In 2002, while still in graduate school, Maru founded Leave a Nest with only undergraduate and graduate
          students in science and engineering. As the driving force behind one of Asia's largest venture ecosystems, in
          collaboration with local factories and major companies, he discovers deep tech innovations from around the
          world and tackles global-scale social issues. He has also been involved in launching numerous venture
          companies, including Euglena Co., Ltd. Some of his notable publications include "DeepTech: Sleeping
          Technologies that Pioneer the Future of the World" (Nikkei BP).
        </Locale>
      </MemberDescription>
    </Member>
  )
}

const Member_Iwata: React.FC = () => {
  return (
    <Member>
      <MemberImageContainer>
        <MemberImage src="/images/members/iwata.jpg" />
      </MemberImageContainer>
      <MemberName>岩田彰一郎</MemberName>
      <MemberNameEn>Shoichiro Iwata</MemberNameEn>
      <MemberDescription>
        <Locale lang="ja">
          1986年プラス株式会社入社、1992年新規事業であるアスクルの開始にあたりアスクル事業推進室室長に就任、1997年の同事業分社独立とともにアスクル株式会社代表取締役社長就任、2000年に最高経営責任者（CEO）就任。2019年8月に代表取締役を退任し、2019年9月に株式会社フォース・マーケティングアンドマネジメントを設立し、現職。
        </Locale>
        <Locale lang="en">
          In 1986, he joined Plas Co., Ltd. In 1992, he took on the role of General Manager of the Askul Business
          Promotion Office when Askul, a new business, was launched. In 1997, when the business became independent as
          Askul Co., Ltd., he assumed the position of President and Representative Director. In 2000, he became the
          Chief Executive Officer (CEO) of Askul. In August 2019, he resigned the Representative Director, then in
          September 2019, he established Force Marketing and Management Co., Ltd., where he currently holds a position.
        </Locale>
      </MemberDescription>
    </Member>
  )
}

export type MembersSectionProps = {
  compact?: boolean
  className?: string
}

export const MembersSection: React.FC<MembersSectionProps> = ({ compact, className }) => {
  const { v1: theme } = useTheme()

  return (
    <Section id="members" className={className} $compact={compact}>
      <Inner>
        <HeadingV1>MEMBER</HeadingV1>
        <MemberGroup
          css={css`
            margin-bottom: 50px;

            @media (min-width: ${theme.breakpoint}) {
              margin-bottom: 100px;
            }
          `}
        >
          <Member_Takakura />
          <Member_Masuda />
          <Member_Takeuchi />
          <Member_Ueda />
          <Member_Moriya />
          <Member_Katunishi />
          <Member_Matuura />
          <Member_Pachoensuk />
        </MemberGroup>
        <HeadingV1>
          <Locale lang="ja">経営顧問</Locale>
          <Locale lang="en">MANAGEMENT ADVISOR</Locale>
        </HeadingV1>
        <MemberGroup>
          <Member_Maru />
          <Member_Iwata />
        </MemberGroup>
      </Inner>
    </Section>
  )
}
