import React, { useMemo } from 'react'
import { css, useTheme } from '@emotion/react'

type IconDefItem = {
  src: string
  width: number
  height: number
}

type IconDefTheme = {
  dark: IconDefItem
  light: IconDefItem
}

type IconDef = {
  small: IconDefTheme
  medium: IconDefTheme
}

type IconSize = keyof IconDef
type IconTheme = keyof IconDefTheme

type IconLinkProps = {
  href: string
  def: IconDef
  size?: IconSize | null
  theme: IconTheme
  color: string
  children?: React.ReactNode
}

const IconLink: React.FC<IconLinkProps> = ({ href, def, size, theme: themeName, color, children }) => {
  const { v1: theme } = useTheme()

  const linkCss = useMemo(() => {
    if (size) {
      const { src, width, height } = def[size][themeName]

      return css`
        position: relative;
        display: inline-block;
        width: ${width - 2}px;
        height: ${height - 2}px;
        border: 1px solid ${color};
        border-radius: 50%;
        background-image: url(${src});
        background-position: center;
        background-repeat: no-repeat;
        background-size: ${width * 0.5}px;
        cursor: pointer;
      `
    }

    const { src: srcS, width: widthS, height: heightS } = def.small[themeName]
    const { src: srcM, width: widthM, height: heightM } = def.medium[themeName]

    return css`
      position: relative;
      display: inline-block;
      width: ${widthS - 2}px;
      height: ${heightS - 2}px;
      border: 1px solid ${color};
      border-radius: 50%;
      background-image: url(${srcS});
      background-position: center;
      background-repeat: no-repeat;
      background-size: ${widthS * 0.5}px;
      cursor: pointer;

      @media (min-width: ${theme.breakpoint}) {
        width: ${widthM - 2}px;
        height: ${heightM - 2}px;
        background-image: url(${srcM});
        background-size: ${widthM * 0.5}px;
      }
    `
  }, [size, def, themeName, color, theme])

  return (
    <a href={href} css={linkCss} target="_blank" rel="noreferrer">
      {children}
    </a>
  )
}

export type SocialMediaLinkProps = {
  theme?: 'dark' | 'light'
  href: string
  size?: 'medium' | 'small' | null
}

const colorDef = {
  dark: '#ffffff',
  light: '#2b2b2b',
}

const facebookIconDef = {
  small: {
    dark: {
      src: '/images/social-media/facebook-white.svg',
      width: 25,
      height: 25,
    },
    light: {
      src: '/images/social-media/facebook-black.svg',
      width: 25,
      height: 25,
    },
  },
  medium: {
    dark: {
      src: '/images/social-media/facebook-white.svg',
      width: 37,
      height: 37,
    },
    light: {
      src: '/images/social-media/facebook-black.svg',
      width: 37,
      height: 37,
    },
  },
}

export const Facebook: React.FC<SocialMediaLinkProps> = ({ theme = 'light', href, size }) => {
  return <IconLink href={href} def={facebookIconDef} size={size} theme={theme} color={colorDef[theme]} />
}

const instagramIconDef = {
  small: {
    dark: {
      src: '/images/social-media/instagram-white.svg',
      width: 25,
      height: 25,
    },
    light: {
      src: '/images/social-media/instagram-black.svg',
      width: 25,
      height: 25,
    },
  },
  medium: {
    dark: {
      src: '/images/social-media/instagram-white.svg',
      width: 37,
      height: 37,
    },
    light: {
      src: '/images/social-media/instagram-black.svg',
      width: 37,
      height: 37,
    },
  },
}

export const Instagram: React.FC<SocialMediaLinkProps> = ({ theme = 'light', href, size }) => {
  return <IconLink href={href} def={instagramIconDef} size={size} theme={theme} color={colorDef[theme]} />
}

const twitterIconDef = {
  small: {
    dark: {
      src: '/images/social-media/twitter-white.svg',
      width: 25,
      height: 25,
    },
    light: {
      src: '/images/social-media/twitter-black.svg',
      width: 25,
      height: 25,
    },
  },
  medium: {
    dark: {
      src: '/images/social-media/twitter-white.svg',
      width: 37,
      height: 37,
    },
    light: {
      src: '/images/social-media/twitter-black.svg',
      width: 37,
      height: 37,
    },
  },
}

export const Twitter: React.FC<SocialMediaLinkProps> = ({ theme = 'light', href, size }) => {
  return <IconLink href={href} def={twitterIconDef} size={size} theme={theme} color={colorDef[theme]} />
}

export type SocialMediaLinksProps = Pick<SocialMediaLinkProps, 'theme'>

export const SocialMediaLinks: React.FC<SocialMediaLinksProps> = ({ theme = 'light' }) => {
  return (
    <div
      css={css`
        display: flex;
        align-items: center;
        justify-content: start;
        gap: 10px;
      `}
    >
      <div>
        <Facebook theme={theme} href="https://www.facebook.com/profile.php?id=100063594788948" size="medium" />
      </div>
      <div>
        <Twitter theme={theme} href="https://twitter.com/Innoqua_inc" size="medium" />
      </div>
    </div>
  )
}
