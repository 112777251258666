import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from './index'

type Category = {
  id: string
  name: string
}

type BlogsState = {
  categories: Category[]
}

const initialState: BlogsState = {
  categories: [],
}

export const blogsSlice = createSlice({
  name: 'blogs',
  initialState,
  reducers: {
    setCategories: (state, action: PayloadAction<Category[]>) => {
      state.categories = action.payload
    },
  },
})

export const { setCategories } = blogsSlice.actions

export const selectCategories = (state: RootState) => state.blogs.categories

export default blogsSlice.reducer
