import { css, keyframes, useTheme } from '@emotion/react'
import React from 'react'

const createKeyframe = (height: number) => {
  return keyframes`
    from {
      transform: translateY(${height * 0.0212766}px);
      opacity: 0;
    }

    5% {
      opacity: 1;
    }

    95% {
      opacity: 1;
    }

    to {
      transform: translateY(${(height - 7) * 0.9787234}px);
      opacity: 0;
    }
  `
}

const createCss = (height: number, radius: number) => {
  const animation = createKeyframe(height)
  const lineWidth = 2

  return css`
    position: relative;
    width: ${radius * 2}px;
    height: ${height}px;

    ::before {
      content: '';
      display: block;
      position: relative;
      left: ${radius - lineWidth / 2}px;
      width: ${lineWidth}px;
      height: ${height}px;
      border-radius: 2px;
    }

    ::after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: ${radius * 2}px;
      height: ${radius * 2}px;
      border-radius: 50%;
      animation: ${animation} 1200ms ease-out infinite;
    }
  `
}

const variants = {
  short: createCss(50, 4),
  long: createCss(100, 5),
} as const

export type ScrollPromptProps = {
  color?: string
  variant?: 'short' | 'long'
  className?: string
  children?: React.ReactNode
}

export const ScrollPrompt: React.FC<ScrollPromptProps> = ({
  color = '#000000',
  variant: variantName = 'short',
  className,
  children,
}) => {
  const { v1: theme } = useTheme()
  const variantCss = variants[variantName]

  return (
    <div
      css={css`
        ${variantCss}

        ::before {
          background-color: ${color};
        }

        ::after {
          background-color: ${color};
        }
      `}
      className={className}
    >
      {children && (
        <div
          css={css`
            ${theme.text.sm};
            ${theme.fontWeight.medium};
            position: absolute;
            top: 50%;
            left: 15px;
            color: ${color};
            white-space: nowrap;
            transform: translateY(-50%);
          `}
        >
          {children}
        </div>
      )}
    </div>
  )
}
