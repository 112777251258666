import React from 'react'
import { useLocale } from '../hooks/use-locale'

export type LocaleProps = {
  lang: string
  children: React.ReactNode
}

export const Locale: React.FC<LocaleProps> = ({ lang, children }) => {
  const locale = useLocale()

  return lang === locale ? <>{children}</> : null
}
