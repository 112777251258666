import React, { useMemo } from 'react'
import { css, Theme, useTheme } from '@emotion/react'
import styled from '@emotion/styled'

const colorMode = {
  dark: ({ v1: theme }: Theme) => css`
    color: ${theme.baseColor.white};
  `,
  light: ({ v1: theme }: Theme) => css`
    color: ${theme.baseColor.blue};
  `,
  none: () => css``,
} as const

type ColorModeName = keyof typeof colorMode

export const HeadingV1 = styled.h2<{ $colorMode?: ColorModeName }>`
  ${(props) => props.theme.v1.text.lg}
  ${(props) => colorMode[props.$colorMode ?? 'light'](props.theme)};
  margin: 0 0 1.875rem 0;
`

export type HeadingProps = {
  subContent: React.ReactNode
  extraContent?: React.ReactNode
  children: React.ReactNode
  className?: string
  customMargin?: boolean
  align?: 'left' | 'center'
  subContentCss?: ReturnType<typeof css>
  mainContentCss?: ReturnType<typeof css>
}

export const Heading: React.FC<HeadingProps> = ({
  subContent,
  extraContent,
  children,
  className,
  customMargin = false,
  align = 'left',
  subContentCss,
  mainContentCss,
}) => {
  const { v2: theme } = useTheme()

  const styles = useMemo(() => {
    return {
      container: css`
        display: flex;
        align-items: end;
      `,
      containerDefault: css`
        justify-content: space-between;
      `,
      containerCenter: css`
        justify-content: center;
        text-align: center;
      `,
      containerMargin: css`
        margin: 0;
        margin-bottom: 30px;

        @media (min-width: ${theme.breakpoint}) {
          margin-bottom: 33px;
        }
      `,
      subContent: css`
        ${theme.fontWeight.bold};
        ${theme.text.sm};
        color: ${theme.baseColor.blue};
        line-height: 1;
        margin-bottom: 13px;
      `,
      mainContent: css`
        ${theme.text.lg};
        ${theme.fontWeight.bold};
        margin-top: -7px;
      `,
    }
  }, [theme])

  const containerStyle = align === 'center' ? styles.containerCenter : styles.containerDefault

  return (
    <h2 css={css(styles.container, containerStyle, customMargin ? null : styles.containerMargin)} className={className}>
      <div>
        <div css={css(styles.subContent, subContentCss)}>{subContent}</div>
        <div css={css(styles.mainContent, mainContentCss)}>{children}</div>
      </div>
      {extraContent && <div>{extraContent}</div>}
    </h2>
  )
}
