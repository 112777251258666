import React, { useCallback, useEffect, useRef } from 'react'
import { css, useTheme } from '@emotion/react'
import { HeadingV1 } from './heading'
import { Inner } from './layout'
import { ZoomingImage } from './zooming-image'
import { Slidable, SlidableRenderFunction } from './slidable'

const InnerHtml: React.FC<{ text: string }> = ({ text }) => {
  return (
    <div
      css={css`
        position: relative;
        overflow: hidden;

        img {
          max-width: 100%;
        }
      `}
      dangerouslySetInnerHTML={{ __html: text }}
    />
  )
}

export type CaseAndVoiceItem = {
  id: string
  customer: string
  body: string
  featuredImage: {
    src: string
  } | null
}

export type CaseAndVoiceSectionItemProps = {
  item: CaseAndVoiceItem
  current: boolean
  setControlsTop: (top: number) => void
}

export const CaseAndVoiceSectionItem: React.FC<CaseAndVoiceSectionItemProps> = ({ item, current, setControlsTop }) => {
  const { v1: theme } = useTheme()
  const { customer, body } = item
  const src = item.featuredImage?.src ?? ''

  const imageRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    const update = () => {
      if (!current) {
        return
      }

      const imageEl = imageRef.current

      if (!imageEl) {
        return
      }

      const { height } = imageEl.getBoundingClientRect()

      setControlsTop(height + 15)
    }

    update()

    window.addEventListener('resize', update)

    return () => {
      window.removeEventListener('resize', update)
    }
  }, [current, setControlsTop])

  return (
    <div
      css={css`
        @media (min-width: ${theme.breakpoint}) {
          min-width: 496px;
        }
      `}
    >
      <div
        ref={imageRef}
        css={css`
          margin-bottom: 35px;

          @media (min-width: ${theme.breakpoint}) {
            margin-bottom: auto;
          }
        `}
      >
        <ZoomingImage src={src} minWidth={340} minHeight={208} />
      </div>
      <div
        css={css`
          background-color: ${theme.baseBackgroundColor.light.secondary};
          padding: 1.25rem 0;
        `}
      >
        <div
          css={css`
            ${theme.text.lg};
            ${theme.fontWeight.medium};
            color: ${theme.baseColor.black};
            margin-bottom: 0.625rem;
          `}
        >
          {customer}
        </div>
        <div
          css={css`
            ${theme.text.sm};
            ${theme.fontWeight.medium};
            margin-bottom: 1.25rem;
          `}
        >
          <InnerHtml text={body} />
        </div>
      </div>
    </div>
  )
}

export type CaseAndVoiceSectionProps = {
  items: CaseAndVoiceItem[]
  headingColor: string
}

export const CaseAndVoiceSection: React.FC<CaseAndVoiceSectionProps> = ({ items, headingColor }) => {
  const { v1: theme } = useTheme()

  const render = useCallback<SlidableRenderFunction<CaseAndVoiceItem>>(
    (item, current, setControlsTop) => (
      <CaseAndVoiceSectionItem item={item} current={current} setControlsTop={setControlsTop} />
    ),
    [],
  )

  if (items.length === 0) {
    return (
      <div
        css={css`
          background-color: ${theme.baseColor.white};
          height: 3.125rem;

          @media (min-width: ${theme.breakpoint}) {
            height: 3.625rem;
          }
        `}
      />
    )
  }

  return (
    <div
      css={css`
        background-color: ${theme.baseColor.white};
        ${theme.padding.x};
        padding-top: 3.125rem;
        padding-bottom: 4.75rem;

        @media (min-width: ${theme.breakpoint}) {
          padding: 3.625rem 0 4.75rem 0;
        }
      `}
    >
      <Inner>
        <HeadingV1
          $colorMode="none"
          css={css`
            color: ${headingColor};
          `}
        >
          CASE &amp; VOICE
        </HeadingV1>
      </Inner>
      <div
        css={css`
          max-width: ${theme.breakpoint};
          margin: 0 auto;
        `}
      >
        <Slidable items={items} itemGapS={18} itemGapL={32} itemWidthS={-1} itemWidthL={496} render={render} />
      </div>
    </div>
  )
}
