import { SerializedStyles, css, useTheme } from '@emotion/react'
import styled from '@emotion/styled'
import React, { useRef } from 'react'
import { DetailLinkContent } from '../components/detail-link'
import { I18nLink } from '../components/i18n-link'
import { useMatchQueryDeepCompare } from '../hooks/use-match-query'
import { SC } from '../types'

export const BannerContentOuter: SC<HTMLDivElement> = styled.div`
  ${(props) => props.theme.v1.colorMode.dark};
  height: 550px;
  overflow: hidden;
  cursor: pointer;
  position: relative;

  :hover ${() => BannerImage} {
    transform: scale(1.1);
  }

  & + ${() => BannerContentOuter} {
    margin-top: 15px;
  }
`

export const BannerImage = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  transition: transform 300ms linear;

  ::after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.5);
  }
`

export type BannerContentProps = {
  heading: React.ReactNode
  children: React.ReactNode
  className?: string
}

export const BannerContent: React.FC<BannerContentProps> = ({ heading, children, className }) => {
  const { v1: theme } = useTheme()

  return (
    <div
      css={css`
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
      `}
    >
      <div
        css={css`
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          gap: 2.5rem;
          color: ${theme.baseColor.white};
        `}
        className={className}
      >
        <div
          css={css`
            ${theme.text.base};
            ${theme.fontWeight.medium};
            text-align: center;
          `}
        >
          {heading}
        </div>
        <div
          css={css`
            ${theme.text.xl};
            ${theme.fontWeight.bold};
            line-height: 2.8rem;
            text-align: center;
          `}
        >
          {children}
        </div>
        <div>
          <DetailLinkContent color={theme.baseColor.white}>LEARN MORE</DetailLinkContent>
        </div>
      </div>
    </div>
  )
}

export type BannerImageSource = {
  src: string
  query?: string | null
}

export type BannerProps = {
  heading: React.ReactNode
  href: string
  children: React.ReactNode
  className?: string
  contentClassName?: string
  contentCss?: SerializedStyles
  src?: string
  sources?: BannerImageSource[]
}

export const Banner: React.FC<BannerProps> = ({
  heading,
  href,
  children,
  src,
  sources = [],
  className,
  contentClassName,
  contentCss,
}) => {
  const sources_: BannerImageSource[] = (() => {
    if (src != null) {
      return [...sources, { src }]
    }

    return sources
  })()

  const imageRef = useRef<HTMLDivElement | null>(null)

  useMatchQueryDeepCompare(() => {
    return sources_.map((source) => ({
      query: source.query,
      onMatch: () => {
        const image = imageRef.current

        if (image == null) {
          return
        }

        image.style.backgroundImage = `url(${source.src})`
      },
    }))
  }, [sources_])

  return (
    <I18nLink
      css={css`
        text-decoration: none;
      `}
      to={href}
    >
      <BannerContentOuter className={className}>
        <BannerImage ref={imageRef} />
        <BannerContent heading={heading} className={contentClassName} css={contentCss}>
          {children}
        </BannerContent>
      </BannerContentOuter>
    </I18nLink>
  )
}
