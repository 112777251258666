import React, { useEffect, useMemo, useRef, useState } from 'react'
import { css, useTheme } from '@emotion/react'
import { Helmet } from 'react-helmet-async'
import { useParams } from 'react-router-dom'
import { Breadcrumbs } from '../../components/breadcrumbs'
import { FooterV1 } from '../../components/footer'
import { Header } from '../../components/header'
import { Inner, OuterV1, Section } from '../../components/layout'
import { useMicroCms, Blog } from '../../hooks/use-micro-cms'
import { logger } from '../../helpers'
import { HeadingV1 } from '../../components/heading'
import { BgImage } from '../../components/bg-image'
import { darkBgClassName } from '../../constants'
import { useLocale } from '../../hooks/use-locale'
import { useTitle } from '../../hooks/use-title'

const InnerHtml: React.FC<{ text: string }> = ({ text }) => {
  const { v1: theme } = useTheme()
  const htmlRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    if (htmlRef.current == null) {
      return
    }

    const scripts = htmlRef.current.querySelectorAll('script')

    Array.from(scripts).forEach((script) => {
      const newScript = document.createElement('script')

      newScript.id = script.id
      newScript.className = script.className
      newScript.src = script.src
      newScript.innerHTML = script.innerHTML

      script.parentNode?.replaceChild(newScript, script)
    })
  }, [])

  return (
    <div
      ref={htmlRef}
      css={css`
        ${theme.text.base}
        position: relative;
        overflow: hidden;
        word-break: break-all;

        img {
          max-width: 100%;
        }
      `}
      dangerouslySetInnerHTML={{ __html: text }}
    />
  )
}

export const NewsDetailPage: React.FC = () => {
  const { v1: theme } = useTheme()
  const { id: currentId } = useParams()
  const locale = useLocale()
  const microCms = useMicroCms()
  const [blog, setBlog] = useState<Blog | null>(null)
  const date = useMemo(() => (blog?.date ? new Date(blog?.date) : null), [blog?.date])

  useEffect(() => {
    ;(async () => {
      if (currentId == null) {
        throw new Error(`Invalid id ${currentId}`)
      }

      const blog_ = await microCms.getBlog(currentId, locale)

      setBlog(blog_)
    })().catch((error) => logger.error(error))
  }, [microCms, currentId, locale])

  const currentTitle = useTitle({
    ja: blog?.title ?? '',
    en: blog?.title ?? '',
  })

  return (
    <>
      <Helmet>
        <title>{currentTitle}</title>
      </Helmet>
      <OuterV1>
        <Header darkBgSelector={`.${darkBgClassName}`} />
        <Section $compact={true}>
          <Inner
            css={css`
              min-height: ${blog ? '0' : '100vh'};
            `}
          >
            {blog && date && (
              <>
                <HeadingV1
                  css={css`
                    color: ${theme.baseColor.black};
                  `}
                >
                  {blog.title}
                </HeadingV1>
                <div
                  css={css`
                    display: grid;
                    grid-template-columns: 1fr;
                    gap: 15px;

                    @media (min-width: ${theme.breakpoint}) {
                      gap: 20px;
                    }
                  `}
                >
                  {blog.featuredImage && (
                    <div>
                      <BgImage minWidth={85} minHeight={52} src={blog.featuredImage.src ?? ''} />
                    </div>
                  )}
                  <div
                    css={css`
                      display: flex;
                      justify-content: space-between;
                      align-items: center;
                    `}
                  >
                    <div>
                      <span
                        css={css`
                          ${theme.text.sm};
                          ${theme.fontWeight.medium};
                          display: inline-flex;
                          background-color: ${theme.baseColor.blue};
                          color: ${theme.baseColor.white};
                          height: 1.25rem;
                          padding: 0 0.5rem;
                          align-items: center;
                        `}
                      >
                        <span
                          css={css`
                            position: relative;
                            top: 0.05rem;
                          `}
                        >
                          {blog.category?.name}
                        </span>
                      </span>
                    </div>
                    <div>
                      <span
                        css={css`
                          ${theme.text.sm};
                          ${theme.fontWeight.medium};
                        `}
                      >
                        {date.getFullYear()}.{date.getMonth() + 1}.{date.getDate()}
                      </span>
                    </div>
                  </div>
                  <div>
                    <InnerHtml text={blog.content} />
                  </div>
                </div>
              </>
            )}
          </Inner>
        </Section>
        <Breadcrumbs
          items={[
            {
              name: 'TOP',
              href: '/',
            },
            {
              name: 'NEWS',
              href: '/news',
            },
            {
              name: blog?.title ?? '',
              href: '',
            },
          ]}
        />
        <FooterV1 className={darkBgClassName} />
      </OuterV1>
    </>
  )
}
