import { useCallback } from 'react'
import { useParams } from 'react-router-dom'
import { buildI18nUrl } from '../helpers'
import { defaultLocale as _defaultLocale } from '../constants'

export const useBuildI18nUrl = () => {
  const params = useParams()

  return useCallback(
    (href?: string, locale?: string, defaultLocale = _defaultLocale) => {
      return buildI18nUrl(href, params.locale, locale ?? params.locale, defaultLocale)
    },
    [params.locale],
  )
}
