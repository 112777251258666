import { defaultLocale as _defaultLocale, availableLocales } from '../constants'

export const logger = {
  debug: (...args: unknown[]) => console.log(...args),
  error: (...args: unknown[]) => console.error(...args),
}

export const gcd = (a_: number, b_: number) => {
  const ap = Math.pow(10, String(a_).split('.')[1]?.length ?? 0)
  const bp = Math.pow(10, String(b_).split('.')[1]?.length ?? 0)
  const p = Math.max(ap, bp)

  function _gcd(a: number, b: number): number {
    return a % b > 0 ? _gcd(b, a % b) : b
  }

  return _gcd(a_ * p, b_ * p) / p
}

export const getMinRect = (width_: number, height_: number): [number, number] => {
  const width = Math.floor(width_)
  const height = Math.floor(height_)
  const value = gcd(width, height)

  return [width / value, height / value]
}

export const truncate = (text: string, n: number): string => {
  const chars = Array.from(text)

  if (chars.length <= n) {
    return text
  }

  const e = '...'
  const t = chars.slice(0, n - Array.from(e).length).join('')

  return `${t}${e}`
}

export const normalizeLocale = (locale?: string | null | undefined): string => {
  if (locale == null || locale === '') {
    return _defaultLocale
  }

  if (availableLocales.includes(locale)) {
    return locale
  }

  return _defaultLocale
}

export const isDefaultLocale = (locale?: string | null | undefined): boolean => {
  return normalizeLocale(locale) === _defaultLocale
}

export const isSameOrigin = (href: string | URL): boolean => {
  const url = typeof href === 'string' ? new URL(href, window.location.href) : href

  return url.origin === window.location.origin
}

export const buildI18nUrl = (
  href?: string,
  currentLocale?: string,
  nextLocale?: string,
  defaultLocale = _defaultLocale,
): string => {
  const url = new URL(href ?? window.location.href, window.location.href)

  if (!isSameOrigin(url)) {
    if (href == null) {
      throw new Error('Invalid href')
    }

    return href
  }

  const pathname =
    currentLocale && url.pathname.indexOf(`/${currentLocale}`) === 0
      ? url.pathname.slice(currentLocale.length + 1) || '/'
      : url.pathname

  if (nextLocale != null && defaultLocale !== nextLocale) {
    return pathname === '/'
      ? `/${nextLocale}${url.search}${url.hash}`
      : `/${nextLocale}${pathname}${url.search}${url.hash}`
  }

  return `${pathname}${url.search}${url.hash}`
}

export const formatDate = (date: Date): string => {
  return `${date.getFullYear()}.${date.getMonth() + 1}.${date.getDate()}`
}
