import styled from '@emotion/styled'
import { SC } from '../types'
import { css } from '@emotion/react'

export type SectionProps = {
  $compact?: boolean
  $full?: boolean
}

export const Section: SC<HTMLDivElement, SectionProps> = styled.div<SectionProps>`
  ${(props) =>
    props.$compact
      ? props.theme.v1.padding.top
      : css`
          padding-top: 100px;
        `};
  padding-bottom: 80px;

  ${(props) =>
    props.$full
      ? css`
          padding-right: 0;
          padding-left: 0;
        `
      : props.theme.v1.padding.x};

  & + ${() => Section} {
    padding-top: 0;
  }
`

export const Inner = styled.div`
  max-width: ${(props) => props.theme.v1.innerWidth};
  margin: 0 auto;
`

export const OuterV1 = styled.main<{ headerSpace?: boolean }>`
  position: relative;
  padding-top: ${(props) => (props.headerSpace ?? true ? '75px' : '0')};

  @media (min-width: ${(props) => props.theme.v1.breakpoint}) {
    padding-top: ${(props) => (props.headerSpace ?? true ? '165px' : '0')};
  }
`

export const Outer = styled.main<{ headerSpace?: boolean }>`
  position: relative;
  padding-top: ${(props) => (props.headerSpace ?? true ? '75px' : '0')};
  background-color: ${(props) => props.theme.v2.baseColor.white};

  @media (min-width: ${(props) => props.theme.v2.breakpoint}) {
    padding-top: ${(props) => (props.headerSpace ?? true ? '165px' : '0')};
  }
`
