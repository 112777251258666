import React, { useCallback, useEffect, useState } from 'react'
import { css, useTheme } from '@emotion/react'
import { Helmet } from 'react-helmet-async'
import { useParams, useSearchParams } from 'react-router-dom'
import { Breadcrumbs } from '../../components/breadcrumbs'
import { FooterV1 } from '../../components/footer'
import { Header } from '../../components/header'
import { NewsSection, NewsItem } from '../../components/news-section'
import { UnstyledButton } from '../../components/button'
import { OuterV1 } from '../../components/layout'
import { useMicroCms } from '../../hooks/use-micro-cms'
import { logger } from '../../helpers'
import { useAppSelector } from '../../hooks/use-app-selector'
import { selectCategories } from '../../store/blogs'
import { Pagination, PageChangeHandler } from '../../components/pagination'
import { darkBgClassName } from '../../constants'
import { useTitle } from '../../hooks/use-title'

type CategoryItem = {
  id: string
  name: string
}

type CategoryClickHandler = (category: CategoryItem) => void

type CategoryProps = {
  item: CategoryItem
  current: boolean
  onClick: CategoryClickHandler
  className?: string | undefined
}

const Category: React.FC<CategoryProps> = ({ item, onClick, className, current }) => {
  const { v1: theme } = useTheme()

  const handleClick = useCallback<React.MouseEventHandler<HTMLButtonElement>>(
    (e) => {
      e.preventDefault()
      onClick(item)
    },
    [item, onClick],
  )

  return (
    <UnstyledButton
      onClick={handleClick}
      className={className}
      css={css`
        ${theme.text.sm};
        ${theme.fontWeight.medium};
        color: ${current ? theme.baseColor.blue : theme.baseColor.gray};
      `}
    >
      {item.name}
    </UnstyledButton>
  )
}

export const NewsPage: React.FC = () => {
  const { v1: theme } = useTheme()
  const { locale } = useParams()
  const [searchParams, setSearchParams] = useSearchParams()
  const microCms = useMicroCms()
  const categories = useAppSelector(selectCategories)
  const [blogs, setBlogs] = useState<NewsItem[]>([])
  const [totalCount, setTotalCount] = useState<number>(-1)

  const currentCategory = searchParams.get('category') ?? null
  const currentOffset = parseInt(searchParams.get('offset') ?? '0', 10)
  const currentLimit = parseInt(searchParams.get('limit') ?? '9', 10)

  useEffect(() => {
    if (categories.length === 0) {
      return
    }

    ;(async () => {
      const categoryId = categories.find((cat) => cat.name === currentCategory)?.id
      const blogs_ = await microCms.getBlogSummaries({
        categoryId,
        locale,
        offset: currentOffset,
        limit: currentLimit,
      })

      setBlogs(blogs_.contents)
      setTotalCount(blogs_.totalCount)
    })().catch((error) => logger.error(error))
  }, [microCms, currentCategory, currentOffset, currentLimit, categories, locale])

  const handleCategoryClick = useCallback<CategoryClickHandler>(
    (category) => {
      if (currentCategory === category.name) {
        setSearchParams({})
      } else {
        setSearchParams({
          category: category.name,
        })
      }
    },
    [setSearchParams, currentCategory],
  )

  const handlePageChange = useCallback<PageChangeHandler>(
    ({ offset }) => {
      setSearchParams({
        offset: String(offset),
        limit: String(currentLimit),
        ...(currentCategory ? { category: currentCategory } : {}),
      })
    },
    [setSearchParams, currentCategory, currentLimit],
  )

  const currentTitle = useTitle({
    ja: 'News',
    en: 'News',
  })

  return (
    <>
      <Helmet>
        <title>{currentTitle}</title>
        <link rel="icon" href="/favicon.png" />
      </Helmet>
      <OuterV1>
        <Header darkBgSelector={`.${darkBgClassName}`} />
        <NewsSection
          css={css`
            min-height: ${blogs.length === 0 ? '640px' : '0'};
          `}
          compact={true}
          items={blogs}
          aboveContent={
            <div>
              {categories.map((item) => (
                <span
                  key={item.id}
                  css={css`
                    & + & {
                      ::before {
                        ${theme.text.sm};
                        ${theme.fontWeight.medium};
                        color: ${theme.baseColor.gray};
                        content: '|';
                        padding: 0 0.25rem;
                      }
                    }
                  `}
                >
                  <Category
                    item={item}
                    onClick={handleCategoryClick}
                    current={currentCategory == null || item.name === currentCategory}
                  />
                </span>
              ))}
            </div>
          }
          belowContent={
            totalCount > currentLimit ? (
              <div>
                <Pagination
                  totalCount={totalCount}
                  limit={currentLimit}
                  offset={currentOffset}
                  arround={3}
                  onChange={handlePageChange}
                />
              </div>
            ) : null
          }
        />
        <Breadcrumbs
          items={[
            {
              name: 'TOP',
              href: '/',
            },
            {
              name: 'NEWS',
              href: '',
            },
          ]}
        />
        <FooterV1 className={darkBgClassName} />
      </OuterV1>
    </>
  )
}
