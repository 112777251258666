import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { css, useTheme, SerializedStyles } from '@emotion/react'
import { useMatchQueryDeepCompare } from '../hooks/use-match-query'

export type SlideshowItemOption = {
  sources: [string | null, string][]
  primaryContent?: React.ReactNode
  secondaryContent?: React.ReactNode
}

export type SlideshowStyleInterpolationFn = (props: { item: SlideshowItemOption; active: boolean }) => SerializedStyles
export type SlideshowStyleInterpolation = SlideshowStyleInterpolationFn | 'fade' | null

type SlideshowItemProps = {
  item: SlideshowItemOption
  active: boolean
  maxWidth?: string
  style?: SlideshowStyleInterpolation
}

const _styleFuncs: Record<'fade', SlideshowStyleInterpolationFn> = {
  fade: () => {
    return css`
      transition:
        opacity linear 400ms,
        visibility linear 400ms;
    `
  },
}

const SlideshowItem: React.FC<SlideshowItemProps> = ({ item, maxWidth, active, style = 'fade' }) => {
  const { v2: theme } = useTheme()
  const bgRef = useRef<HTMLDivElement | null>(null)

  const styleFn = useMemo(() => {
    if (style == null) {
      return undefined
    }

    if (typeof style === 'function') {
      return style
    }

    return _styleFuncs[style]
  }, [style])

  useMatchQueryDeepCompare(() => {
    return item.sources.map(([query, url]) => {
      return {
        query,
        onMatch: () => {
          const bgEl = bgRef.current

          if (!bgEl) {
            return
          }

          bgEl.style.backgroundImage = `url(${url})`
        },
      }
    })
  }, [item.sources])

  const styles = useMemo(() => {
    return {
      container: css`
        position: relative;
        width: 100%;
        height: 100%;
      `,
      background: css(
        css`
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-repeat: no-repeat;
          background-position: center;
          background-size: cover;
          opacity: ${active ? '1' : '0'};
          visibility: ${active ? 'visible' : 'hidden'};
        `,
        styleFn?.({ item, active }),
      ),
      content: css`
        position: relative;
        width: calc(100% - 2rem);
        max-width: ${maxWidth ?? 'initial'};
        height: 100%;
        padding: 0 1rem;
        margin: 0 auto;
        visibility: ${active ? 'visible' : 'hidden'};
        padding: 0 16px;

        @media (min-width: ${theme.breakpoint}) {
          padding: 0 50px;
        }
      `,
      contentInner: css`
        position: absolute;
        bottom: 70px;
        color: ${theme.baseColor.white};
      `,
      primaryContent: css`
        ${theme.fontWeight.bold};
        ${theme.text.xl};
      `,
      secondaryContent: css`
        ${theme.text.base};
        ${theme.fontWeight.medium};
        margin-top: 1rem;
      `,
    }
  }, [theme, active, styleFn, item, maxWidth])

  return (
    <div css={styles.container}>
      <div ref={bgRef} css={styles.background} />
      {(item.primaryContent || item.secondaryContent) && active && (
        <div css={styles.content}>
          <div css={styles.contentInner}>
            {item.primaryContent && <div css={styles.primaryContent}>{item.primaryContent}</div>}
            {item.secondaryContent && <div css={styles.secondaryContent}>{item.secondaryContent}</div>}
          </div>
        </div>
      )}
    </div>
  )
}

type IndicatorClickHandler = (index: number) => void

type SlideshowIndicatorItemProps = {
  index: number
  active: boolean
  onClick?: IndicatorClickHandler
}

const SlideshowIndicatorItem: React.FC<SlideshowIndicatorItemProps> = ({ index, active, onClick }) => {
  const { v2: theme } = useTheme()

  const handleClick = useCallback<React.MouseEventHandler<HTMLButtonElement>>(
    (e) => {
      e.preventDefault()
      onClick?.(index)
    },
    [onClick, index],
  )

  return (
    <button
      onClick={handleClick}
      css={css`
        display: block;
        border: 1px solid ${theme.baseColor.white};
        width: 7px;
        height: 7px;
        border-radius: 50%;
        padding: 0;
        margin: 0;
        background-color: ${active ? theme.baseColor.white : 'transparent'};
        cursor: pointer;

        @media (min-width: ${theme.breakpoint}) {
          border-width: 2px;
          width: 10px;
          height: 10px;
        }
      `}
    />
  )
}

type SlideshowIndicatorProps = {
  index: number
  size: number
  onClick?: IndicatorClickHandler
}

const SlideshowIndicator: React.FC<SlideshowIndicatorProps> = ({ index, size, onClick }) => {
  const items = useMemo(() => new Array(size).fill(null).map((_, i) => i === index), [index, size])

  return (
    <div
      css={css`
        display: flex;
        justify-content: space-between;
        gap: 15px;
      `}
    >
      {items.map((active, i) => (
        <SlideshowIndicatorItem key={i} index={i} active={active} onClick={onClick} />
      ))}
    </div>
  )
}

export type SlideshowProps = {
  items: SlideshowItemOption[]
  maxWidth?: string
  showIndicator?: boolean
  interval?: number
  style?: SlideshowStyleInterpolation
}

export const Slideshow: React.FC<SlideshowProps> = ({ items, showIndicator = true, interval = 9000, ...itemProps }) => {
  const { v2: theme } = useTheme()
  const [currentIndex, setCurrentIndex] = useState(-1)

  const fullCss = useMemo(
    () => css`
      position: relative;
      width: 100%;
      height: 100%;
    `,
    [],
  )

  const timeoutIdRef = useRef<NodeJS.Timeout | null>(null)

  const start = useCallback(
    (after: number) => {
      if (timeoutIdRef.current != null) {
        throw new Error('The timer seems to be working')
      }

      timeoutIdRef.current = setTimeout(function next() {
        setCurrentIndex((index) => (index + 1 >= items.length ? 0 : index + 1))

        timeoutIdRef.current = setTimeout(next, interval)
      }, after)
    },
    [items.length, interval],
  )

  const stop = useCallback(() => {
    if (timeoutIdRef.current != null) {
      clearTimeout(timeoutIdRef.current)
      timeoutIdRef.current = null
    }
  }, [])

  const handleIndicatorClick = useCallback<IndicatorClickHandler>(
    (index) => {
      setCurrentIndex(index)
      stop()
      start(interval)
    },
    [start, stop, interval],
  )

  useEffect(() => {
    stop()
    start(0)

    return () => {
      stop()
    }
  }, [start, stop])

  return (
    <div css={fullCss}>
      <div
        css={css`
          ${fullCss};
          background-color: ${theme.baseColor.black};
          overflow: hidden;
        `}
      >
        {items.map((item, index) => (
          <div
            key={index}
            css={css`
              position: absolute;
              width: 100%;
              height: 100%;
              top: 0;
              left: 0;
            `}
          >
            <SlideshowItem item={item} active={currentIndex === index} {...itemProps} />
          </div>
        ))}
      </div>
      {showIndicator && (
        <div
          css={css`
            position: absolute;
            width: 100%;
            left: 0;
            bottom: 15px;
            display: flex;
            align-items: center;
            justify-content: center;
          `}
        >
          <SlideshowIndicator index={currentIndex} size={items.length} onClick={handleIndicatorClick} />
        </div>
      )}
    </div>
  )
}
