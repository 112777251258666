import { css, useTheme } from '@emotion/react'
import React, { useMemo } from 'react'
import { Helmet } from 'react-helmet-async'
import { Header } from '../../components/header'
import { Outer } from '../../components/layout'
import { darkBgClassName } from '../../constants'
import { ContactSection } from '../../components/contact-section'
import { FooterSection } from '../../components/footer-section'
import { Heading } from '../../components/heading'
import { HalfRoundedButton } from '../../components/button'
import { ArticleList } from '../../components/articles'

type IntroSectionProps = {
  title: React.ReactNode
  subTitle: string
  linkContent?: React.ReactNode
  linkTo?: string
  className?: string
  children?: React.ReactNode
}

const IntroSection: React.FC<IntroSectionProps> = ({ title, subTitle, className, children, linkContent, linkTo }) => {
  const { v2: theme } = useTheme()

  const styles = useMemo(() => {
    return {
      container: css`
        display: flex;
        flex-direction: column;
        justify-content: center;
      `,
      heading: css`
        margin: 0 0 25px 0;
      `,
      content: css`
        ${theme.text.base};
        ${theme.fontWeight.bold};
        line-height: 40px;
        display: flex;
        justify-content: center;
      `,
      contentInner: css`
        margin: 0;
        text-align: center;
      `,
      footer: css`
        display: flex;
        justify-content: center;
        margin-top: 30px;
      `,
    }
  }, [theme])

  return (
    <div css={styles.container} className={className}>
      <Heading css={styles.heading} subContent={subTitle} align="center" customMargin={true}>
        {title}
      </Heading>
      <div css={styles.content}>
        <p css={styles.contentInner}>{children}</p>
      </div>
      {linkTo && (
        <div css={styles.footer}>
          <HalfRoundedButton href={linkTo}>{linkContent}</HalfRoundedButton>
        </div>
      )}
    </div>
  )
}

type DetailSectionProps = {
  title: React.ReactNode
  className?: string
}

const DetailSection: React.FC<DetailSectionProps> = ({ title, className }) => {
  const { v2: theme } = useTheme()

  const styles = useMemo(() => {
    return {
      container: css`
        display: flex;
        flex-direction: column;
        gap: 50px;
      `,
      titleContainer: css`
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 60px;
        background-color: ${theme.baseColor.gray};

        @media (min-width: ${theme.breakpoint}) {
          height: 500px;
        }
      `,
      title: css`
        ${theme.text.xl};
        ${theme.fontWeight.bold};
        margin: 0;
      `,
      contentContainer: css`
        display: grid;

        @media (min-width: ${theme.breakpoint}) {
          grid-template-columns: 310px 1fr;
          grid-template-rows: 1fr min-content auto;
          gap: 50px 30px;
          width: ${theme.innerWidth};
          margin-left: auto;
          margin-right: auto;
          padding: 0;
        }
      `,
      heading: css`
        ${theme.text.sm};
        ${theme.fontWeight.bold};
        color: ${theme.baseColor.blue};
      `,
      imageContainer: css`
        border: 1px solid ${theme.baseColor.black};

        @media (min-width: ${theme.breakpoint}) {
          width: 100%;
          border-radius: 28.915663% / 50%;
          aspect-ratio: 664 / 384;
        }

        overflow: hidden;
      `,
      imageWrapper: css`
        width: 100%;
        height: 100%;
      `,
      image: css`
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
        background-color: ${theme.baseColor.white};
      `,
      imageIndicatorContainer: css`
        width: 100%;
        position: absolute;
        bottom: 20px;
        display: flex;
        justify-content: center;
        gap: 15px;
      `,
      imageIndicator: css`
        background-color: ${theme.baseColor.white};
        border: 1px solid ${theme.baseColor.black};
        border-radius: 50%;
        display: block;
        width: 10px;
        height: 10px;
        padding: 0;
        margin: 0;
      `,
      textContainer: css`
        @media (min-width: ${theme.breakpoint}) {
          grid-row: 1 / 2;
          grid-column: 1 / 2;
        }
      `,
      textContainerInner: css``,
      metaContainer: css`
        @media (min-width: ${theme.breakpoint}) {
          grid-row: 2 / 3;
          grid-column: 1 / 2;
        }
      `,
      metaContainerInner: css``,
      imagesContainer: css`
        position: relative;

        @media (min-width: ${theme.breakpoint}) {
          grid-row: 1 / 3;
          grid-column: 2 / 3;
        }
      `,
      articlesContainer: css`
        @media (min-width: ${theme.breakpoint}) {
          grid-row: 3 / 4;
          grid-column: 1 / 4;
        }
      `,
      articlesContainerInner: css``,
    }
  }, [theme])

  const images = [
    'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=',
    'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=',
    'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=',
    'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=',
    'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=',
  ]

  const articles = [
    {
      id: '1',
      title: 'Test 1',
      category: {
        name: 'test',
      },
      featuredImage: {
        src: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=',
      },
      date: '2021-01-03',
    },
    {
      id: '2',
      title: 'Test 2',
      category: {
        name: 'test',
      },
      featuredImage: {
        src: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=',
      },
      date: '2021-01-02',
    },
    {
      id: '3',
      title: 'Test 3',
      category: {
        name: 'test',
      },
      featuredImage: {
        src: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=',
      },
      date: '2021-01-01',
    },
  ]

  return (
    <div css={styles.container} className={className}>
      <div css={styles.titleContainer}>
        <h2 css={styles.title}>{title}</h2>
      </div>
      <div css={styles.contentContainer}>
        <div css={styles.textContainer}>
          <div css={styles.heading}>test</div>
          <div css={styles.textContainerInner}>test</div>
        </div>
        <div css={styles.metaContainer}>
          <div css={styles.heading}>test</div>
          <div css={styles.metaContainerInner}>test</div>
        </div>
        <div css={styles.imagesContainer}>
          <div css={styles.imageContainer}>
            {images.map((item, index) => (
              <div key={index} css={styles.imageWrapper}>
                <img css={styles.image} src={item} alt={`${title} - ${index}`} />
              </div>
            ))}
            <div css={styles.imageIndicatorContainer}>
              {images.map((_, index) => (
                <button key={index} css={styles.imageIndicator} />
              ))}
            </div>
          </div>
        </div>
        <div css={styles.articlesContainer}>
          <div css={styles.heading}>test</div>
          <div css={styles.articlesContainerInner}>
            <ArticleList items={articles} />
          </div>
        </div>
      </div>
    </div>
  )
}

export type LayoutProps = {
  title: string
  intro1Title: React.ReactNode
  intro1SubTitle: string
  intro1Content: React.ReactNode
  detail1Title: React.ReactNode
  detail2Title: React.ReactNode
  detail3Title: React.ReactNode
  intro2Title: React.ReactNode
  intro2SubTitle: string
  intro2Content: React.ReactNode
  intro2LinkContent: React.ReactNode
  intro2LinkTo: string
}

export const Layout: React.FC<LayoutProps> = ({
  title,
  intro1Title,
  intro1SubTitle,
  intro1Content,
  intro2Title,
  detail1Title,
  detail2Title,
  detail3Title,
  intro2SubTitle,
  intro2Content,
  intro2LinkContent,
  intro2LinkTo,
}) => {
  const styles = useMemo(() => {
    return {
      mainContents: css`
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 60px;
      `,
    }
  }, [])

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Outer>
        <Header darkBgSelector={`.${darkBgClassName}`} />
        <div css={styles.mainContents}>
          <IntroSection title={intro1Title} subTitle={intro1SubTitle}>
            {intro1Content}
          </IntroSection>
          <DetailSection title={detail1Title} />
          <DetailSection title={detail2Title} />
          <DetailSection title={detail3Title} />
          <IntroSection
            title={intro2Title}
            subTitle={intro2SubTitle}
            linkContent={intro2LinkContent}
            linkTo={intro2LinkTo}
          >
            {intro2Content}
          </IntroSection>
        </div>
        <ContactSection />
        <FooterSection className={darkBgClassName} />
      </Outer>
    </>
  )
}
