import { css, useTheme } from '@emotion/react'
import styled from '@emotion/styled'
import React, { useCallback, useEffect, useRef, useState } from 'react'

const SlideButton = styled.button<{ $direction: 'left' | 'right' }>`
  background-color: transparent;
  background-position: center;
  background-image: url(/images/slide-arrow-left.svg);
  background-repeat: no-repeat;
  background-size: 20px;
  border-style: none;
  width: 14px;
  height: 20px;
  display: block;
  cursor: pointer;
  outline: none;
  transform: rotate(${(props) => (props.$direction === 'right' ? '180deg' : '0deg')});

  :disabled {
    opacity: 0.3;
    cursor: auto;
  }

  @media (min-width: ${(props) => props.theme.v1.breakpoint}) {
    background-size: 25px;
    width: 30px;
    height: 100%;
  }
`

const SlideButtonContainer = styled.div`
  display: none;

  @media (min-width: ${(props) => props.theme.v1.breakpoint}) {
    display: block;
  }
`

export type SlidableSetControlsTopFunction = (top: number) => void
export type SlidableRenderFunction<T> = (
  item: T,
  current: boolean,
  setControlsTop: SlidableSetControlsTopFunction,
) => React.ReactNode

export type SlidableProps<T extends { id: string }> = {
  items: T[]
  render: SlidableRenderFunction<T>
  className?: string
  itemGapS: number
  itemGapL: number
  itemWidthS: number
  itemWidthL: number
}

export const Slidable = <T extends { id: string }>({
  items,
  className,
  render,
  itemGapS,
  itemGapL,
  itemWidthS,
  itemWidthL,
}: SlidableProps<T>): React.ReactElement<any, any> => {
  const { v1: theme } = useTheme()
  const [currentPosition, setCurrentPosition] = useState(0)
  const [currentLastPosition, setCurrentLastPosition] = useState(currentPosition)

  const firstPostision = 0
  const mode = itemWidthS === -1 ? 'fill' : 'fixed'

  const scrollWindowRef = useRef<HTMLDivElement | null>(null)
  const scrollWindowInnerRef = useRef<HTMLDivElement | null>(null)
  const itemsRef = useRef<Record<string, HTMLDivElement | null>>({})
  const controlsRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    for (const key in itemsRef.current) {
      if (!items.find((item) => item.id === key)) {
        delete itemsRef.current[key]
      }
    }
  }, [items])

  const handleScrollToLeft = useCallback<React.MouseEventHandler<HTMLButtonElement>>((e) => {
    e.preventDefault()

    setCurrentPosition((current) => Math.max(current - 1, firstPostision))
  }, [])

  const handleScrollToRight = useCallback<React.MouseEventHandler<HTMLButtonElement>>(
    (e) => {
      e.preventDefault()

      setCurrentPosition((current) => Math.min(current + 1, currentLastPosition))
    },
    [currentLastPosition],
  )

  const setControlsTop = useCallback<SlidableSetControlsTopFunction>((top) => {
    const controlsEl = controlsRef.current

    if (!controlsEl) {
      return
    }

    controlsEl.style.top = `${top}px`
  }, [])

  useEffect(() => {
    const update = (behavior: 'smooth' | 'auto') => {
      const windowEl = scrollWindowRef.current

      if (!windowEl) {
        return
      }

      const item = items[currentPosition]

      if (!item) {
        return
      }

      const itemEl = itemsRef.current[item.id]

      if (!itemEl) {
        return
      }

      windowEl.scrollTo({
        left: itemEl.offsetLeft,
        behavior,
      })
    }

    const handleResize = () => update('auto')

    update('smooth')

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [items, currentPosition])

  useEffect(() => {
    const update = () => {
      const windowEl = scrollWindowRef.current
      const windowInnerEl = scrollWindowInnerRef.current

      if (!windowEl || !windowInnerEl) {
        return
      }

      const { width: containerWidth } = windowEl.getBoundingClientRect()
      const { width } = windowInnerEl.getBoundingClientRect()
      const maxScrollLeft = width - containerWidth

      const position = items.findIndex((item) => {
        const itemEl = itemsRef.current[item.id]

        if (!itemEl) {
          return false
        }

        return itemEl.offsetLeft >= maxScrollLeft
      })

      setCurrentLastPosition(Math.max(position, 0))
    }

    update()

    window.addEventListener('resize', update)

    return () => {
      window.removeEventListener('resize', update)
    }
  }, [items])

  return (
    <div
      css={css`
        position: relative;
        width: 100%;
        display: flex;
      `}
    >
      <SlideButtonContainer
        css={css`
          padding-right: 35px;
        `}
      >
        <SlideButton disabled={currentPosition === firstPostision} onClick={handleScrollToLeft} $direction="left" />
      </SlideButtonContainer>
      <div
        ref={scrollWindowRef}
        css={css`
          position: relative;
          box-sizing: border-box;
          width: 100%;
          overflow-x: ${mode === 'fill' ? 'hidden' : 'auto'};
          ${theme.padding.right};
          padding-bottom: 1.375rem;
          display: flex;

          @media (min-width: ${theme.breakpoint}) {
            padding-right: 0;
            overflow-x: hidden;
          }
        `}
        className={className}
      >
        <div
          ref={scrollWindowInnerRef}
          css={css`
            position: relative;
            display: flex;
            gap: ${itemGapS}px;

            @media (min-width: ${theme.breakpoint}) {
              gap: ${itemGapL}px;
            }
          `}
        >
          {items.map((item, i) => (
            <div
              key={item.id}
              ref={(el) => {
                itemsRef.current[item.id] = el
              }}
              css={css`
                min-width: ${mode === 'fill' ? `calc(100vw - ${theme.basePadding.x} * 2)` : `${itemWidthS}px`};
                max-width: ${mode === 'fill' ? `calc(100vw - ${theme.basePadding.x} * 2)` : `${itemWidthS}px`};

                @media (min-width: ${theme.breakpoint}) {
                  min-width: ${itemWidthL}px;
                  max-width: ${itemWidthL}px;
                }
              `}
            >
              {render(item, i === currentPosition, setControlsTop)}
            </div>
          ))}
        </div>
      </div>
      <SlideButtonContainer
        css={css`
          padding-left: 35px;
        `}
      >
        <SlideButton
          disabled={currentPosition === currentLastPosition}
          onClick={handleScrollToRight}
          $direction="right"
        />
      </SlideButtonContainer>
      {mode === 'fill' && (
        <div
          ref={controlsRef}
          css={css`
            position: absolute;
            left: 0;
            top: 0;
            display: flex;
            justify-content: space-between;
            min-width: calc(100vw - ${theme.basePadding.x} * 2);

            @media (min-width: ${theme.breakpoint}) {
              display: none;
            }
          `}
        >
          <div>
            <SlideButton disabled={currentPosition === firstPostision} onClick={handleScrollToLeft} $direction="left" />
          </div>
          <div>
            <SlideButton
              disabled={currentPosition === currentLastPosition}
              onClick={handleScrollToRight}
              $direction="right"
            />
          </div>
        </div>
      )}
    </div>
  )
}
