import React, { useCallback, useMemo } from 'react'
import { css, Theme, useTheme } from '@emotion/react'
import { useNavigate } from 'react-router-dom'
import { UnstyledButton } from './button'
import { BgImage } from './bg-image'
import { I18nLink } from './i18n-link'
import { useBuildI18nUrl } from '../hooks/use-build-i18n-url'
import { AccessLink, MediaKitLink, XLink, InstagramLink, FacebookLink, PrivacyPolicyLink, CompanyLink } from './links'

const menuText = {
  header: (theme: Theme['v2']) => {
    return css`
      ${theme.text.base};
      text-decoration: none;
      color: ${theme.baseColor.black};
    `
  },
  menu: (theme: Theme['v2']) => {
    return css`
      ${theme.text.lg};
      text-decoration: none;
      color: ${theme.baseColor.black};
    `
  },
} as const

type MenuTextVariant = keyof typeof menuText
type FontWeight = 'medium' | 'bold'

const useMenuTextStyle = (variant: MenuTextVariant, fontWeight: FontWeight = 'bold') => {
  const { v2: theme } = useTheme()
  const currentTheme = useMemo(() => menuText[variant](theme), [variant, theme])

  return css`
    ${currentTheme};
    ${theme.color.black};
    ${theme.fontWeight[fontWeight]};
  `
}

type MenuLinkParams = {
  href?: string
  locale?: string
}
type MenuLinkHandler = (params: MenuLinkParams) => void

export type MenuLinkPreps = {
  variant: MenuTextVariant
  fontWeight?: FontWeight
  href?: string
  locale?: string
  onClick?: MenuLinkHandler
  children: React.ReactNode
  className?: string
}

export const MenuLink: React.FC<MenuLinkPreps> = ({
  variant,
  fontWeight,
  onClick,
  children,
  href,
  locale,
  className,
}) => {
  const textStyles = useMenuTextStyle(variant, fontWeight)

  const handleClick = useCallback<React.MouseEventHandler<HTMLAnchorElement>>(
    (e) => {
      e.preventDefault()

      onClick?.({
        href,
        locale,
      })
    },
    [onClick, href, locale],
  )

  const styles = css`
    ${textStyles};
    cursor: pointer;
  `

  if (onClick) {
    return (
      <a href={href} className={className} css={styles} onClick={handleClick}>
        {children}
      </a>
    )
  }

  return (
    <I18nLink className={className} css={styles} to={href} locale={locale}>
      {children}
    </I18nLink>
  )
}

const useMenuItemHandler = (onClose?: () => void) => {
  const navigate = useNavigate()
  const buildI18nUrl = useBuildI18nUrl()

  const handleMenuClick = useCallback<MenuLinkHandler>(
    ({ href, locale }) => {
      navigate(buildI18nUrl(href, locale))

      onClose?.()
    },
    [onClose, navigate, buildI18nUrl],
  )

  const handleNoopMenuClick = useCallback(() => undefined, [])

  const navigateToIdInPage = useCallback(
    (id: string) => {
      const el = document.getElementById(id)

      if (el) {
        navigate({
          hash: id,
        })
      } else {
        navigate(buildI18nUrl(`/#${id}`))
      }
    },
    [navigate, buildI18nUrl],
  )

  const handleContactClick = useCallback<MenuLinkHandler>(() => {
    onClose?.()
    navigateToIdInPage('contact')
  }, [onClose, navigateToIdInPage])

  const handlers = useMemo(
    () => ({
      handleMenuClick,
      handleNoopMenuClick,
      handleContactClick,
    }),
    [handleMenuClick, handleNoopMenuClick, handleContactClick],
  )

  return handlers
}

export type MenuProps = {
  onClose: () => void
}

export const Menu: React.FC<MenuProps> = ({ onClose }) => {
  const { v2: theme } = useTheme()
  const { handleMenuClick, handleContactClick } = useMenuItemHandler(onClose)

  const handleMenuClose = useCallback<React.MouseEventHandler<HTMLButtonElement>>(
    (e) => {
      e.preventDefault()

      onClose()
    },
    [onClose],
  )

  const styles = useMemo(() => {
    return {
      container: css`
        ${theme.colorMode.light};
        display: block;
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 11;
      `,
      actions: css`
        position: absolute;
        top: 21px;
        right: 18px;
        z-index: 10;

        @media (min-width: ${theme.breakpoint}) {
          top: 62px;
          right: 34px;
        }
      `,
      main: css`
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        overflow-y: auto;
      `,
      mainInner: css`
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        min-height: 100lvh;
        box-sizing: border-box;
        position: relative;
        min-width: 100%;
        padding: 100px 36px 30px 36px;
        gap: 70px;

        @media (min-width: ${theme.breakpoint}) {
          margin: auto;
          padding-top: 136px;
          padding-bottom: 80px;
          max-width: 660px;
          min-width: 0;
        }
      `,
      siteNav: css`
        display: flex;
        flex-direction: column;
        gap: 20px;

        @media (min-width: ${theme.breakpoint}) {
          gap: 30px;
        }
      `,
      external: css`
        display: grid;
        grid-template-columns: 1fr;
        gap: 40px;

        @media (min-width: ${theme.breakpoint}) {
          grid-template-columns: 138px 138px auto;
          align-items: end;
          gap: 30px;
        }
      `,
      externalGroup: css``,
      externalGroup1: css`
        display: flex;
        flex-direction: column;
        gap: 16px;

        @media (min-width: ${theme.breakpoint}) {
          gap: 12px;
        }
      `,
      externalGroup2: css`
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding-top: 70px;

        @media (min-width: ${theme.breakpoint}) {
          padding-top: 0;
        }
      `,
    }
  }, [theme])

  return (
    <nav css={styles.container}>
      <div css={styles.actions}>
        <UnstyledButton onClick={handleMenuClose}>
          <BgImage src="/images/menu-close.svg" minWidth={22.828} minHeight={22.828} />
        </UnstyledButton>
      </div>
      <div css={styles.main}>
        <div css={styles.mainInner}>
          <div css={styles.siteNav}>
            <div>
              <MenuLink href="/" variant="menu">
                Top
              </MenuLink>
            </div>
            <div>
              <MenuLink href="/projects-and-services" variant="menu" onClick={handleMenuClick}>
                Projects & Services
              </MenuLink>
            </div>
            <div>
              <MenuLink href="/thinking" variant="menu" onClick={handleMenuClick}>
                Thinking
              </MenuLink>
            </div>
            <div>
              <MenuLink href="/articles/s-jrv8z0ql" variant="menu" onClick={handleMenuClick}>
                Careers
              </MenuLink>
            </div>
            <div>
              <MenuLink href="/articles" variant="menu" onClick={handleMenuClick}>
                Articles
              </MenuLink>
            </div>
            <div>
              <MenuLink href="/contact" variant="menu" onClick={handleContactClick}>
                Contact
              </MenuLink>
            </div>
          </div>
          <div css={styles.external}>
            <div css={css(styles.externalGroup, styles.externalGroup1)}>
              <div>
                <AccessLink colorScheme="light" />
              </div>
              <div>
                <MediaKitLink colorScheme="light" />
              </div>
            </div>
            <div css={css(styles.externalGroup, styles.externalGroup1)}>
              <div>
                <XLink colorScheme="light" />
              </div>
              <div>
                <InstagramLink colorScheme="light" />
              </div>
              <div>
                <FacebookLink colorScheme="light" />
              </div>
            </div>
            <div css={css(styles.externalGroup, styles.externalGroup2)}>
              <div>
                <PrivacyPolicyLink colorScheme="light" />
              </div>
              <div>
                <CompanyLink colorScheme="light" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
  )
}
