import React, { useMemo } from 'react'
import { css, useTheme } from '@emotion/react'
import { Locale } from './locale'
import { HeadingV1 } from './heading'
import { Section } from './layout'
import { DetailLink } from './detail-link'
import { ZoomingImage } from './zooming-image'
import { I18nLink } from './i18n-link'

export type ServicesSectionProps = {
  className?: string
  imageClassName?: string
}

export const ServicesSection: React.FC<ServicesSectionProps> = ({ className, imageClassName }) => {
  const { v1: theme } = useTheme()

  const services = useMemo(
    () => [
      {
        id: 'marine-clinical-trial-services',
        images: [
          {
            src: '/images/marine-clinical-trial-services-td.jpg',
            minWidth: 672,
            minHeight: 300,
            query: `(min-width: ${theme.breakpoint})`,
          },
          {
            src: '/images/marine-clinical-trial-services-td.jpg',
            minWidth: 375,
            minHeight: 200,
          },
        ],
        label: '研究事業',
        labelEn: 'Research Business',
        title: '海洋治験サービス',
        titleEn: 'Marine Clinical Trial Service',
        summary: '独自設備でデータ解析を行い、サンゴなどの海洋生物に対する影響評価レポートを提供します。',
        summaryEn:
          'We analyze data using our own facilities and provide impact assessment reports on corals and other marine lives.',
      },
      {
        id: 'coral-kids-lab',
        images: [
          {
            src: '/images/coral-kids-lab-td.jpg',
            minWidth: 672,
            minHeight: 300,
            query: `(min-width: ${theme.breakpoint})`,
          },
          {
            src: '/images/coral-kids-lab-td.jpg',
            minWidth: 375,
            minHeight: 200,
            query: null,
          },
        ],
        label: '教育・イベント事業',
        labelEn: 'Education and Event Business',
        title: 'サンゴ礁ラボ®',
        titleEn: 'Coral Reef Lab®',
        summary: '”秘密研究機関イノカ”の一員として楽しく環境を学ぶ、新感覚の体験型環境教育プログラムです。',
        summaryEn:
          'This is a new type of hands-on environmental education program where you can enjoy learning about the environment as a member of Innoqua, a secret research institute.',
      },
      {
        id: 'innovate-aquarium-festival',
        images: [
          {
            src: '/images/innovate-aquarium-festival-td.jpg',
            minWidth: 672,
            minHeight: 300,
            query: `(min-width: ${theme.breakpoint})`,
          },
          {
            src: '/images/innovate-aquarium-festival-ts.jpg',
            minWidth: 375,
            minHeight: 200,
          },
        ],
        label: '教育・イベント事業',
        labelEn: 'Education and Event Business',
        title: 'INNOVATE AQUARIUM FESTIVAL',
        titleEn: undefined,
        summary: 'アクアリストのまだ見ぬ可能性の追求、また社会との新たな接点作りを目的としたイベントです。',
        summaryEn:
          'The purpose of this event is to pursue the yet undiscovered potential of aquarists and to create new connections with society.',
      },
    ],
    [theme.breakpoint],
  )

  const centerCss = useMemo(
    () => css`
      max-width: ${theme.innerWidth};
      margin-left: auto;
      margin-right: auto;
    `,
    [theme],
  )

  const containerCss = useMemo(
    () => css`
      ${centerCss};
      ${theme.padding.x};
      flex-grow: 1;

      @media (min-width: ${theme.breakpoint}) {
        padding-left: 0;
        padding-right: 0;
      }
    `,
    [theme, centerCss],
  )

  const itemCssEven = useMemo(
    () => css`
      @media (min-width: ${theme.breakpoint}) {
        flex-direction: row;
      }
    `,
    [theme],
  )

  const itemCssOdd = useMemo(
    () => css`
      @media (min-width: ${theme.breakpoint}) {
        flex-direction: row-reverse;
      }
    `,
    [theme],
  )

  return (
    <Section id="services" $full={true} className={className}>
      <HeadingV1 css={containerCss}>SERVICE</HeadingV1>
      <div
        css={css`
          display: grid;
          grid-template-columns: 1fr;
          align-items: start;
          gap: 4.6875rem 3.125rem;

          @media (min-width: ${theme.breakpoint}) {
            ${centerCss};
          }
        `}
      >
        {services.map((service, i) => (
          <div
            key={service.id}
            css={css`
              ${i % 2 === 0 ? itemCssEven : itemCssOdd};

              @media (min-width: ${theme.breakpoint}) {
                display: flex;
                align-items: center;
                gap: 32px;
              }
            `}
          >
            <div
              css={css`
                margin-bottom: 0.9375rem;

                @media (min-width: ${theme.breakpoint}) {
                  margin-bottom: 0;
                }
              `}
              className={imageClassName}
            >
              <I18nLink to={`/services/${service.id}`}>
                <ZoomingImage sources={service.images} />
              </I18nLink>
            </div>
            <div css={containerCss}>
              <div
                css={css`
                  ${theme.text.sm};
                  ${theme.fontWeight.medium};
                  background-color: ${theme.baseColor.blue};
                  color: ${theme.baseColor.white};
                  display: inline-block;
                  padding: 0 0.4rem;
                  margin-bottom: 0.9rem;

                  span {
                    position: relative;
                    top: 0.0625rem;
                  }
                `}
              >
                <span>
                  <Locale lang="ja">{service.label}</Locale>
                  <Locale lang="en">{service.labelEn ?? service.label}</Locale>
                </span>
              </div>
              <div
                css={css`
                  ${theme.text.lg};
                  ${theme.fontWeight.bold};
                  margin-bottom: 0.5rem;
                `}
              >
                <Locale lang="ja">{service.title}</Locale>
                <Locale lang="en">{service.titleEn ?? service.title}</Locale>
              </div>
              <div
                css={css`
                  ${theme.text.base};
                  ${theme.fontWeight.medium};
                  margin-bottom: 0.875rem;
                `}
              >
                <Locale lang="ja">{service.summary}</Locale>
                <Locale lang="en">{service.summaryEn ?? service.summary}</Locale>
              </div>
              <div
                css={css`
                  display: flex;
                  justify-content: end;

                  @media (min-width: ${theme.breakpoint}) {
                    justify-content: start;
                  }
                `}
              >
                <DetailLink href={`/services/${service.id}`} color={theme.baseColor.blue}>
                  LEARN MORE
                </DetailLink>
              </div>
            </div>
          </div>
        ))}
      </div>
    </Section>
  )
}
