import { css } from '@emotion/react'
import React, { useMemo } from 'react'
import { useTypewriter, Cursor } from 'react-simple-typewriter'

export type TypewriterNewLineHandler = (index: number) => React.ReactNode

export type TypewriterProps = {
  text: string
  onNewLine?: TypewriterNewLineHandler
}

const split = (text: string | number, onNewLine?: TypewriterNewLineHandler) => {
  const segments: React.ReactNode[] = []

  let i = 0
  let current = ''

  Array.from(String(text)).forEach((ch) => {
    if (ch === '\n') {
      segments.push(current)
      segments.push(onNewLine?.(i++))
      current = ''
    } else {
      current += ch
    }
  })

  segments.push(current)

  return segments
}

export const Typewriter: React.FC<TypewriterProps> = ({ text, onNewLine }) => {
  const [currentText] = useTypewriter({
    words: ['', text],
    delaySpeed: 500,
  })

  const fullSegments = useMemo(() => split(text, onNewLine), [text, onNewLine])

  const lines = useMemo(() => {
    const current = split(currentText, onNewLine)
    const cursorIndex = current.length

    fullSegments.slice().forEach((segment, i) => {
      if (current[i] == null) {
        if (typeof segment === 'string') {
          current[i] = <span>&nbsp;</span>
        } else {
          current[i] = segment
        }
      }
    })

    current.splice(cursorIndex, 0, <Cursor />)

    return current
  }, [currentText, onNewLine, fullSegments])

  return (
    <div
      css={css`
        position: relative;
      `}
    >
      <div
        css={css`
          position: absolute;
        `}
      >
        {lines.map((line, i) => (
          <React.Fragment key={i}>{line}</React.Fragment>
        ))}
      </div>
      <div
        css={css`
          visibility: hidden;
        `}
      >
        {fullSegments.map((line, i) => (
          <React.Fragment key={i}>{line}</React.Fragment>
        ))}
        <Cursor />
      </div>
    </div>
  )
}
