import { css, useTheme } from '@emotion/react'
import styled from '@emotion/styled'
import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet-async'
import { Breadcrumbs } from '../components/breadcrumbs'
import { OuterV1, Inner } from '../components/layout'
import { Locale } from '../components/locale'
import { CompanySection } from '../components/company-section'
import { FooterV1 } from '../components/footer'
import { Header } from '../components/header'
import { MembersSection } from '../components/members-section'
import { SC } from '../types'
import { SlippingImage } from '../components/slipping-image'
import { useLoaderData } from 'react-router'
import { darkBgClassName } from '../constants'
import { useTitle } from '../hooks/use-title'

const Block: SC<HTMLDivElement> = styled.div`
  & + ${() => Block} {
    margin-top: 4.6875rem;
  }

  @media (min-width: ${(props) => props.theme.v1.breakpoint}) {
    overflow-x: hidden;
  }
`

const BlockHeading = styled.div`
  ${(props) => props.theme.v1.text.base};
  ${(props) => props.theme.v1.fontWeight.medium};
  color: ${(props) => props.theme.v1.baseColor.blue};
  margin-bottom: 0.75rem;
  ${(props) => props.theme.v1.padding.x};
  padding-top: 0;
  padding-bottom: 0;

  @media (min-width: ${(props) => props.theme.v1.breakpoint}) {
    padding: 0;
  }
`

const BlockTitleBase = styled.div`
  ${(props) => props.theme.v1.text.lg};
  ${(props) => props.theme.v1.fontWeight.bold};
  margin-bottom: 1rem;
`

const BlockTitleEm = styled(BlockTitleBase)`
  position: relative;
  padding-left: 1rem;

  ::before {
    content: '';
    display: block;
    position: absolute;
    top: 0.15rem;
    bottom: 0.15rem;
    left: 0;
    width: 2px;
    border-radius: 1px;
    background-color: ${(props) => props.theme.v1.baseColor.blue};
  }
`

const BlockTitle: React.FC<{
  border?: boolean
  className?: string
  children: React.ReactNode
}> = ({ border, className, children }) => {
  const { v1: theme } = useTheme()
  const BlockTitleComponent = border ? BlockTitleEm : BlockTitleBase

  return (
    <div
      css={css`
        ${theme.padding.x};
        padding-top: 0;
        padding-bottom: 0;

        @media (min-width: ${theme.breakpoint}) {
          padding: 0;
        }
      `}
      className={className}
    >
      <BlockTitleComponent>{children}</BlockTitleComponent>
    </div>
  )
}

const BlockContent = styled.div`
  ${(props) => props.theme.v1.text.base};
  ${(props) => props.theme.v1.fontWeight.medium};
  ${(props) => props.theme.v1.padding.x};
  padding-top: 0;
  padding-bottom: 0;

  @media (min-width: ${(props) => props.theme.v1.breakpoint}) {
    padding: 0;
  }
`

const BlockImageContainer = styled.div`
  margin-top: 3.125rem;
  position: relative;

  @media (min-width: ${(props) => props.theme.v1.breakpoint}) {
    max-height: 550px;
    overflow: hidden;
  }
`

const TechBlock: SC<HTMLDivElement> = styled.div`
  & + ${() => TechBlock} {
    margin-top: 1.875rem;
  }
`

export const AboutPage: React.FC = () => {
  const { v1: theme } = useTheme()
  const loaderData = useLoaderData()

  const contentBaseCss = css`
    @media (min-width: ${theme.breakpoint}) {
      max-width: 672px;
    }
  `

  useEffect(() => {
    if (loaderData) {
      const elementData = document.getElementById(`${loaderData}`)

      if (elementData) {
        const elementDataTop = window.scrollY + elementData.getBoundingClientRect().top
        window.scroll(0, elementDataTop)
      }
    }
  }, [loaderData])

  const currentTitle = useTitle({
    ja: 'About',
    en: 'About',
  })

  return (
    <>
      <Helmet>
        <title>{currentTitle}</title>
      </Helmet>
      <OuterV1>
        <Header darkBgSelector={`.${darkBgClassName}`} />
        <div
          id="mission-philosophy-value"
          css={css`
            ${theme.colorMode.light};
            padding: 0 0 5rem 0;
          `}
        >
          <Block>
            <Inner>
              <div css={contentBaseCss}>
                <BlockHeading>MISSION</BlockHeading>
                <BlockTitle>
                  <Locale lang="ja">人類の選択肢を増やし、人も自然も栄える世界をつくる</Locale>
                  <Locale lang="en">
                    Increase Human options and Create a World where both people and nature can be better
                  </Locale>
                </BlockTitle>
                <BlockContent>
                  <Locale lang="ja">
                    現在、環境保全の重要性が認知されるようになり、
                    自然からの搾取を減らし、一方で植林などを増やすことで、
                    人間経済と自然環境とのバランスをとることが目指されるようになってきました。
                    一方で現状取れる選択肢では、どうしてもこの人間の活動は我慢しなければいけない、この自然保護はある程度諦めないといけない、
                    という厳しい選択を迫られる場面もあります。 そのような現状を変えるべく、イノカは、
                    「人類の選択肢を増やし、人も自然も栄える世界をつくる。」 というミッションを掲げます。
                    上述のように、AorBという厳しい選択を強いるのではなくて、
                    イノカが見つけた自然の可能性をベースに、環境移送技術&reg;を使って、新しい選択肢Cを作っていきます。
                    また、自然を守る技術の提案だけではなく、時には自然から人が得られる新しい恩恵を社会に提示していきます。
                  </Locale>
                  <Locale lang="en">
                    The aim is now to achieve a balance between the human economy and the natural environment by
                    reducing exploitation of nature and increasing afforestation. On the other hand, there are
                    situations in which we are forced to make a hard choice between the current options: we must endure
                    this human activity, or we must give up a certain degree of nature conservation. In order to change
                    this situation, INNOQUA's mission is to "Increase human options and create a world where both people
                    and nature can be better". Rather than forcing people to make a hard choice between A and B, as
                    described above, INNOQUA will create a new choice C based on the potential of nature that it has
                    discovered, using biosphere transfer technology&reg;. In addition, we will not only propose
                    technologies to protect nature, but sometimes we will also present to society new benefits that
                    people can gain from nature.
                  </Locale>
                </BlockContent>
              </div>
            </Inner>
            <BlockImageContainer
              css={css`
                @media (min-width: ${theme.breakpoint}) {
                  left: calc((100% - ${theme.innerWidth}) / 2);
                  width: calc(100% - (100% - ${theme.innerWidth}) / 2);
                }
              `}
            >
              <SlippingImage
                sources={[
                  {
                    src: '/images/about-i1.jpg',
                    minWidth: 1195,
                    minHeight: 550,
                    query: `(min-width: ${theme.breakpoint})`,
                  },
                  {
                    src: '/images/about-i1.jpg',
                    minWidth: 375,
                    minHeight: 220,
                  },
                ]}
              />
            </BlockImageContainer>
          </Block>
          <Block>
            <Inner>
              <div
                css={css`
                  ${contentBaseCss};
                  margin-left: auto;
                  margin-right: 0;
                `}
              >
                <BlockHeading>PHILOSOPHY</BlockHeading>
                <BlockTitle>
                  <Locale lang="ja">
                    自分たちが好きな
                    <br
                      css={css`
                        ${theme.atBreakpoint.hide}
                      `}
                    />
                    自然をみつづける。
                  </Locale>
                  <Locale lang="en">Continue to Watch the Nature We Love</Locale>
                </BlockTitle>
                <BlockContent>
                  <Locale lang="ja">
                    イノカのメンバーは全員強烈な生き物好きです。 全員がそれぞれ違う多様な生き物のことを愛しています。
                    僕らは「自分たちが好きな生き物たちをずっと見ていたい、触れ合っていたい、そのためには当然、自然環境を守っていかなければいけない」という思いを持って、イノカに集まってきました。
                    そして、我々は各メンバーが好きな生き物をテーマに、地球貢献に繋がるプロジェクトがつくれないかを考え続けていきます。
                    我々は地球の可能性を、「生き物が好き」という気持ちをベースに探究し、その可能性を社会に伝えていく活動を続けます。
                  </Locale>
                  <Locale lang="en">
                    All members of INNOQUA are intense living things lovers. We all love different and diverse living
                    things. We have come together at INNOQUA with the desire to see and interact with the livings we
                    love all the time, and to do so, of course, we must protect the natural environment. We will
                    continue to think about how we can create a project that contributes to the earth, based on the
                    theme of each member's favorite livings. We will continue to explore the possibilities of the earth
                    based on our love of living things, and to communicate these possibilities to society.
                  </Locale>
                </BlockContent>
              </div>
            </Inner>
            <BlockImageContainer
              css={css`
                @media (min-width: ${theme.breakpoint}) {
                  width: calc(100% - (100% - ${theme.innerWidth}) / 2);
                }
              `}
            >
              <SlippingImage
                sources={[
                  {
                    src: '/images/about-i2.jpg',
                    minWidth: 1195,
                    minHeight: 550,
                    query: `(min-width: ${theme.breakpoint})`,
                  },
                  {
                    src: '/images/about-i2.jpg',
                    minWidth: 375,
                    minHeight: 220,
                  },
                ]}
              />
            </BlockImageContainer>
          </Block>
          <Block>
            <Inner>
              <div
                css={css`
                  ${contentBaseCss};
                `}
              >
                <BlockHeading>VALUES</BlockHeading>
                <BlockTitle border={true}>
                  <Locale lang="ja">地球にも、人にも敬意を。</Locale>
                  <Locale lang="en">Respecting both the Earth and people.</Locale>
                </BlockTitle>
                <BlockContent>
                  <Locale lang="ja">
                    人々が自らを地球の生態系の一部であることを深く理解し、自然に対する敬意、すなわち知的好奇心と愛情を持つこと。そしてまた、人類が発展させてきた文明に対しても同様に敬意を払い、将来世代にわたる全ての人類の幸せを追い求めること。我々はこれら2つの「敬意」を忘れず、またいずれかに偏ることなく、テクノロジーとサイエンスを絶えず発展させながら、人と自然の共栄に向け常に挑戦しつづけます。
                  </Locale>
                  <Locale lang="en">
                    We must deeply understand that we humans are part of the Earth's ecosystem and therefore have
                    respect for nature, that is, intellectual curiosity and love. We must also have the same respect for
                    the civilization that humanity has developed, and pursue the happiness of all human beings for
                    future generations. We will never forget to respect both the Earth and people, and without being
                    biased toward one or the other, we will continue to challenge ourselves to achieve a symbiosis
                    between people and nature while technology and science advance constantly.
                  </Locale>
                </BlockContent>
              </div>
              <div
                css={css`
                  ${contentBaseCss};
                  margin-top: 3.125rem;
                  margin-left: auto;
                  margin-right: auto;
                `}
              >
                <BlockTitle border={true}>
                  <Locale lang="ja">素人発想、玄人実行。</Locale>
                  <Locale lang="en">Thinking like a layman, executing like an expert.</Locale>
                </BlockTitle>
                <BlockContent>
                  <Locale lang="ja">
                    イノカでは、多様な専門性を持つメンバーが集まり、人と自然の共栄を実現するための議論と行動を進めています。専門外だからといって遠慮するのではなく、素人ならではの柔軟な発想で研究開発・事業開発に取り組むことにより、革新的な発想を生み出すことができると信じています。毎日初心に戻り、現状や常識を疑う姿勢も忘れません。同時に、それぞれが専門分野の実行者としてプロフェッショナリズムを持ち、大胆なアイデアを細部にまでこだわって形にすることで、世の中に確かな変革をもたらす玄人集団であることを掲げます。
                  </Locale>
                  <Locale lang="en">
                    At Innoqua, members with diverse expertise come together to discuss and take action to achieve the
                    symbiosis between people and nature. We believe that we can create innovative ideas by approaching
                    R&D and business development with the flexibility of a layman, rather than being reserved because of
                    the lack of expertise. We also remember to go back to the basics on a daily basis and question the
                    present condition and common sense of the current society. At the same time, we uphold our
                    professionalism as executors in our respective fields of expertise, and by giving shape to bold
                    ideas with meticulous attention to detail, we aim to become a group of experts who will bring about
                    solid change in the world.
                  </Locale>
                </BlockContent>
              </div>
              <div
                css={css`
                  ${contentBaseCss};
                  margin-top: 3.125rem;
                  margin-left: auto;
                  margin-right: 0;
                `}
              >
                <BlockTitle border={true}>
                  <Locale lang="ja">貪欲であれ！</Locale>
                  <Locale lang="en">Be willing to learn!</Locale>
                </BlockTitle>
                <BlockContent>
                  <Locale lang="ja">
                    これら二つのバリューを高い水準で体現するためには、常に学び続ける志が大切です。学び続けることで自身の五感を研ぎ澄ませ、地球上で起きているさまざまな事象をより深く捉えられる人材の創出を目指します。そして、自らが感じた違和感や課題に対して一人一人がスピーディに考え、夢を持ち、リーダーシップを発揮して主体的に行動することを誓います。
                  </Locale>
                  <Locale lang="en">
                    In order to embody these two values at a high level, it is important to have the will to continue to
                    learn. We aim to create human resources who can sharpen their senses through continuous learning and
                    gain a deeper understanding of the various phenomena occurring on the Earth. We also pledge that
                    each one of us will think quickly, have dreams, exercise leadership and act proactively in response
                    to the discomfort and challenges we feel.
                  </Locale>
                </BlockContent>
              </div>
            </Inner>
          </Block>
        </div>
        <div id="MembersSection">
          <MembersSection
            compact={true}
            css={css`
              @media (min-width: ${theme.breakpoint}) {
                padding-top: 60px;
              }
            `}
          />
        </div>
        <div id="CompanySection">
          <CompanySection />
        </div>
        <Breadcrumbs
          items={[
            {
              name: 'TOP',
              href: '/',
            },
            {
              name: 'ABOUT',
              href: '',
            },
          ]}
        />
        <FooterV1 className={darkBgClassName} />
      </OuterV1>
    </>
  )
}
