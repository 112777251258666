import React, { useEffect, useRef, useMemo } from 'react'
import { useDeepCompareDeps } from './use-deep-compare-deps'

export type QueryOption = {
  query?: string | null | undefined
  onMatch: () => void
}

export const useMatchQuery = (queryOptionsBuilder: () => QueryOption[], deps: React.DependencyList) => {
  const prevMatchedRef = useRef<QueryOption | null>(null)

  const options = useMemo(() => {
    const itemsHasQuery: QueryOption[] = []
    const itemsHasNoQuery: QueryOption[] = []

    queryOptionsBuilder().forEach((query) => {
      if (query.query != null) {
        itemsHasQuery.push(query)
      } else {
        itemsHasNoQuery.push(query)
      }
    })

    return [...itemsHasQuery, ...itemsHasNoQuery]
  }, deps) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const run = () => {
      for (const option of options) {
        if (option.query != null) {
          if (window.matchMedia(option.query).matches) {
            if (prevMatchedRef.current !== option) {
              option.onMatch()
              prevMatchedRef.current = option
            }

            break
          }
        } else {
          if (prevMatchedRef.current !== option) {
            option.onMatch()
            prevMatchedRef.current = option
          }

          break
        }
      }
    }

    run()

    window.addEventListener('resize', run)

    return () => {
      window.removeEventListener('resize', run)
    }
  }, [options])
}

export const useMatchQueryDeepCompare = (queryOptionsBuilder: () => QueryOption[], deps: React.DependencyList) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useMatchQuery(queryOptionsBuilder, useDeepCompareDeps(deps))
}
