import { css, useTheme } from '@emotion/react'
import { Helmet } from 'react-helmet-async'
import { ContactSection } from '../../components/contact-section'
import { FooterSection } from '../../components/footer-section'
import { Header } from '../../components/header'
import React, { useCallback, useMemo, useState } from 'react'
import { Outer } from '../../components/layout'
import { darkBgClassName } from '../../constants'
import { useTitle } from '../../hooks/use-title'
import { useMemoOnce } from '../../hooks/use-memo-once'
import { Heading } from '../../components/heading'
import { HalfRoundedButton, RoundedButton } from '../../components/button'
import { ImageList } from '../../components/images'
import { Locale } from '../../components/locale'

type MainContentProps = {
  id?: string
  title: React.ReactNode
  description: React.ReactNode
  link?: React.ReactNode
  children?: React.ReactNode
  className?: string
  layout?: '2-1' | '2'
}

const MainContent: React.FC<MainContentProps> = ({
  id,
  title,
  description,
  link,
  children,
  className,
  layout = '2-1',
}) => {
  const { v2: theme } = useTheme()

  const styles = useMemo(() => {
    const styles21 = {
      container: css`
        display: grid;
        gap: 20px;

        @media (min-width: ${theme.breakpoint}) {
          grid-template-columns: 1fr 2fr;
          grid-auto-rows: min-content min-content 1fr;
          width: ${theme.innerWidth};
          margin-left: auto;
          margin-right: auto;
          gap: 0 13px;
          padding: 0;
        }
      `,
      titleContainer: css`
        padding: 0 36px;

        @media (min-width: ${theme.breakpoint}) {
          grid-row: 1 / 2;
          grid-column: 1 / 2;
          padding: 0;
        }
      `,
      descriptionContainer: css`
        ${theme.text.sm};
        ${theme.fontWeight.bold};
        padding: 0 36px;

        @media (min-width: ${theme.breakpoint}) {
          grid-row: 1 / 3;
          grid-column: 2 / 3;
          padding: 0;
          margin-top: 22px;
        }
      `,
      linkContainer: css`
        display: flex;
        justify-content: center;
        padding-top: 10px;

        @media (min-width: ${theme.breakpoint}) {
          grid-row: 2 / 3;
          grid-column: 1 / 2;

          padding-top: 0;
          align-items: end;
          justify-content: start;
        }
      `,
      linkContainerInner: css``,
      contentContainer: css`
        padding-top: 30px;

        @media (min-width: ${theme.breakpoint}) {
          grid-row: 3 / 4;
          grid-column: 1 / 4;
          padding-top: 50px;
        }
      `,
    }

    const styles2 = {
      container: css`
        display: grid;
        gap: 20px;

        @media (min-width: ${theme.breakpoint}) {
          grid-template-columns: 1fr 2fr;
          grid-auto-rows: min-content min-content 1fr;
          width: ${theme.innerWidth};
          margin-left: auto;
          margin-right: auto;
          gap: 0 13px;
          padding: 0;
        }
      `,
      titleContainer: css`
        padding: 0 36px;

        @media (min-width: ${theme.breakpoint}) {
          grid-row: 1 / 2;
          grid-column: 1 / 2;

          padding: 0;
        }
      `,
      descriptionContainer: css`
        ${theme.text.sm};
        ${theme.fontWeight.bold};
        padding: 0 36px;

        @media (min-width: ${theme.breakpoint}) {
          grid-row: 1 / 3;
          grid-column: 2 / 3;
          margin-top: 22px;
          padding: 0;
        }
      `,
      linkContainer: css`
        display: flex;
        justify-content: center;
        padding-top: 10px;

        @media (min-width: ${theme.breakpoint}) {
          grid-row: 2 / 3;
          grid-column: 1 / 2;

          padding-top: 0;
          align-items: end;
          justify-content: start;
        }
      `,
      linkContainerInner: css``,
      contentContainer: css`
        @media (min-width: ${theme.breakpoint}) {
          grid-row: 3 / 4;
          grid-column: 1 / 4;
        }
      `,
    }

    const layouts = {
      '2-1': styles21,
      '2': styles2,
    }

    return layouts[layout]
  }, [theme, layout])

  return (
    <div id={id} className={className} css={styles.container}>
      <div css={styles.titleContainer}>{title}</div>
      <div css={styles.descriptionContainer}>{description}</div>
      <div css={styles.linkContainer}>
        <div css={styles.linkContainerInner}>{link}</div>
      </div>
      <div css={styles.contentContainer}>{children}</div>
    </div>
  )
}

const WhatWeDoContent: React.FC = () => {
  const { v2: theme } = useTheme()

  const styles = useMemo(() => {
    return {
      container: css`
        position: relative;
        display: flex;
        flex-direction: column;
        gap: 30px;

        @media (min-width: ${theme.breakpoint}) {
          flex-direction: row;
          justify-content: space-between;
          gap: 0;
        }
      `,
      imageContainer: css`
        padding: 0 36px;

        @media (min-width: ${theme.breakpoint}) {
          padding: 0;
        }
      `,
      image: css`
        width: 100%;

        @media (min-width: ${theme.breakpoint}) {
          width: 579px;
        }
      `,
      actions: css`
        display: flex;
        flex-direction: column;
        gap: 40px;
        position: relative;
        padding: 0 36px;

        @media (min-width: ${theme.breakpoint}) {
          top: 75px;
          padding: 0;
        }
      `,
      groupContainer: css`
        position: relative;
      `,
      group: css`
        position: relative;
        border: 1px solid ${theme.baseColor.black};
        border-radius: 10px;
        padding: 0 15px;
        background-color: ${theme.baseColor.white};
        z-index: 2;

        @media (min-width: ${theme.breakpoint}) {
          min-width: 324px;
          max-width: 380px;
        }
      `,
      groupItem: css`
        border-bottom: 1px solid ${theme.baseColor.black};
        padding: 0 6px;

        &:last-of-type {
          border-bottom: none;
        }

        @media (min-width: ${theme.breakpoint}) {
          padding: 0 13px;
        }
      `,
      button: css`
        width: 100%;
        height: 40px;
        ${theme.text.sm};
        ${theme.fontWeight.bold};
        display: flex;
        align-items: center;
        gap: 8px;
        text-decoration: none;
        white-space: nowrap;

        @media (min-width: ${theme.breakpoint}) {
          gap: 15px;
        }
      `,
      subTitle: css`
        display: block;
        display: block;
        color: ${theme.baseColor.black};

        @media (min-width: ${theme.breakpoint}) {
          min-width: 86px;
        }
      `,
      title: css`
        display: block;
        color: ${theme.baseColor.blue};
        line-height: 1;
        white-space: normal;
      `,
      icon: css`
        flex: 1;
        display: flex;
        justify-content: flex-end;
      `,
      iconImage: css``,
      pointer1: css`
        display: none;

        @media (min-width: ${theme.breakpoint}) {
          display: block;
          position: absolute;
          bottom: 17px;
          right: 324px;
          z-index: 1;
        }
      `,
      pointer2: css`
        display: none;

        @media (min-width: ${theme.breakpoint}) {
          display: block;
          position: absolute;
          bottom: 13px;
          right: 324px;
          z-index: 1;
        }
      `,
    }
  }, [theme])

  return (
    <div css={styles.container}>
      <div css={styles.imageContainer}>
        <img css={styles.image} src="/images/what-we-do/main.jpg" alt="What we do" />
      </div>
      <div css={styles.actions}>
        <div css={styles.groupContainer}>
          <div css={styles.group}>
            <div css={styles.groupItem}>
              <a href="#project-01" css={styles.button}>
                <span css={styles.subTitle}>Project 01</span>
                <span css={styles.title}>
                  <Locale lang="ja">地域を良くしていく</Locale>
                  <Locale lang="en">Improving the community.</Locale>
                </span>
                <span css={styles.icon}>
                  <img css={styles.iconImage} src="/images/what-we-do/arrow-down.svg" alt="↓" />
                </span>
              </a>
            </div>
          </div>
          <img css={styles.pointer1} src="/images/what-we-do/pointer.svg" alt="" />
        </div>
        <div css={styles.groupContainer}>
          <div css={styles.group}>
            <div css={styles.groupItem}>
              <a href="#project-02" css={styles.button}>
                <span css={styles.subTitle}>Project 02</span>
                <span css={styles.title}>
                  <Locale lang="ja">企業と良くしていく</Locale>
                  <Locale lang="en">Making it better with companies.</Locale>
                </span>
                <span css={styles.icon}>
                  <img css={styles.iconImage} src="/images/what-we-do/arrow-down.svg" alt="↓" />
                </span>
              </a>
            </div>
            <div css={styles.groupItem}>
              <a href="#service-01" css={styles.button}>
                <span css={styles.subTitle}>Service 01</span>
                <span css={styles.title}>
                  <Locale lang="ja">研究開発事業</Locale>
                  <Locale lang="en">Research and Development</Locale>
                </span>
                <span css={styles.icon}>
                  <img css={styles.iconImage} src="/images/what-we-do/arrow-down.svg" alt="↓" />
                </span>
              </a>
            </div>
            <div css={styles.groupItem}>
              <a href="#service-02" css={styles.button}>
                <span css={styles.subTitle}>Service 02</span>
                <span css={styles.title}>
                  <Locale lang="ja">教育・イベント事業</Locale>
                  <Locale lang="en">Education and Event Business</Locale>
                </span>
                <span css={styles.icon}>
                  <img css={styles.iconImage} src="/images/what-we-do/arrow-down.svg" alt="↓" />
                </span>
              </a>
            </div>
          </div>
          <img css={styles.pointer2} src="/images/what-we-do/pointer.svg" alt="" />
        </div>
      </div>
    </div>
  )
}

const coreTechnologyImages = {
  '01': {
    srcL: '/images/core-technology/01-l.jpg',
    srcS: '/images/core-technology/01-s.jpg',
    alt: 'Core technology 01',
  },
  '02': {
    srcL: '/images/core-technology/02-l.jpg',
    srcS: '/images/core-technology/02-s.jpg',
    alt: 'Core technology 02',
  },
  '03': {
    srcL: '/images/core-technology/03-l.jpg',
    srcS: '/images/core-technology/03-s.jpg',
    alt: 'Core technology 03',
  },
  '04': {
    srcL: '/images/core-technology/04-l.jpg',
    srcS: '/images/core-technology/04-s.jpg',
    alt: 'Core technology 04',
  },
  '05': {
    srcL: '/images/core-technology/05-l.jpg',
    srcS: '/images/core-technology/05-s.jpg',
    alt: 'Core technology 05',
  },
  '06': {
    srcL: '/images/core-technology/06-l.jpg',
    srcS: '/images/core-technology/06-s.jpg',
    alt: 'Core technology 06',
  },
}

const CycleIcon: React.FC<{ className?: string }> = ({ className }) => {
  const { v2: theme } = useTheme()

  return (
    <picture>
      <source srcSet="/images/core-technology/cycle-l.svg" media={`(min-width: ${theme.breakpoint})`} />
      <img className={className} src="/images/core-technology/cycle-s.svg" alt="" />
    </picture>
  )
}

const CoreTechnologyContent: React.FC = () => {
  const { v2: theme } = useTheme()
  const [currentImageId, setCurrentImageId] = useState<keyof typeof coreTechnologyImages>('01')
  const currentImage = coreTechnologyImages[currentImageId]

  const handleImage01 = useCallback(() => {
    setCurrentImageId('01')
  }, [])

  const handleImage02 = useCallback(() => {
    setCurrentImageId('02')
  }, [])

  const handleImage03 = useCallback(() => {
    setCurrentImageId('03')
  }, [])

  const handleImage04 = useCallback(() => {
    setCurrentImageId('04')
  }, [])

  const handleImage05 = useCallback(() => {
    setCurrentImageId('05')
  }, [])

  const handleImage06 = useCallback(() => {
    setCurrentImageId('06')
  }, [])

  const styles = useMemo(() => {
    return {
      container: css`
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 30px;
        width: 100vw;
        overflow: hidden;

        @media (min-width: ${theme.breakpoint}) {
          gap: 40px;
          width: auto;
        }
      `,
      imageSection: css`
        overflow: auto;
        width: 100vw;

        @media (min-width: ${theme.breakpoint}) {
          width: auto;
        }
      `,
      controlSection: css`
        position: relative;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 15px 10px;
        padding: 0 36px;

        @media (min-width: ${theme.breakpoint}) {
          gap: 16px 20px;
          grid-template-columns: repeat(3, 1fr);
          width: 750px;
          padding: 0;
        }
      `,
      imageContainer: css`
        aspect-ratio: 688 / 262;
        min-height: 262px;
        padding: 0 36px 10px 36px;

        @media (min-width: ${theme.breakpoint}) {
          min-height: 0;
          padding: 0;
          width: 100%;
          aspect-ratio: 1004 / 382;
        }
      `,
      image: css`
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
        background-color: ${theme.baseColor.white};
        border-radius: 10px;
      `,
      button: css`
        ${theme.text.xxs};
        display: flex;
        width: 100%;
        padding: 0 10px;
        white-space: normal;

        * {
          vertical-align: middle;
          line-height: 1;
        }

        @media (min-width: ${theme.breakpoint}) {
          ${theme.text.xs};
        }
      `,
      icon: css`
        margin-left: 5px;
        position: relative;
        top: -1px;
      `,
      control01: css``,
      control02: css``,
      control03: css``,
      control04: css``,
      control05: css``,
      control06: css``,
    }
  }, [theme])

  return (
    <div css={styles.container}>
      <div css={styles.imageSection}>
        <div css={styles.imageContainer}>
          <picture>
            <source srcSet={currentImage.srcL} media={`(min-width: ${theme.breakpoint})`} />
            <img css={styles.image} src={currentImage.srcS} alt={currentImage.alt} />
          </picture>
        </div>
      </div>
      <div css={styles.controlSection}>
        <div css={styles.control01}>
          <RoundedButton css={styles.button} onClick={handleImage01}>
            <span>
              <Locale lang="ja">仕組み</Locale>
              <Locale lang="en">System</Locale>
            </span>
            <CycleIcon css={styles.icon} />
          </RoundedButton>
        </div>
        <div css={styles.control02}>
          <RoundedButton css={styles.button} onClick={handleImage02}>
            <span>
              <Locale lang="ja">サンゴ礁生態圏(※2)</Locale>
              <Locale lang="en">coral reef biosphere(*2)</Locale>
            </span>
            <CycleIcon css={styles.icon} />
          </RoundedButton>
        </div>
        <div css={styles.control03}>
          <RoundedButton css={styles.button} onClick={handleImage03}>
            <span>
              <Locale lang="ja">マングローブ生態圏</Locale>
              <Locale lang="en">mangrove biosphere</Locale>
            </span>
            <CycleIcon css={styles.icon} />
          </RoundedButton>
        </div>
        <div css={styles.control04}>
          <RoundedButton css={styles.button} onClick={handleImage04}>
            <span>
              <Locale lang="ja">アマモ生態圏</Locale>
              <Locale lang="en">eelgrass biosphere</Locale>
            </span>
            <CycleIcon css={styles.icon} />
          </RoundedButton>
        </div>
        <div css={styles.control05}>
          <RoundedButton css={styles.button} onClick={handleImage05}>
            <span>
              <Locale lang="ja">海藻共同体</Locale>
              <Locale lang="en">seaweed bed biosphere</Locale>
            </span>
            <CycleIcon css={styles.icon} />
          </RoundedButton>
        </div>
        <div css={styles.control06}>
          <RoundedButton css={styles.button} onClick={handleImage06}>
            <span>
              <Locale lang="ja">ゲンゴロウ中心生態圏</Locale>
              <Locale lang="en">Fresh water diving beetle biosphere</Locale>
            </span>
            <CycleIcon css={styles.icon} />
          </RoundedButton>
        </div>
      </div>
    </div>
  )
}

export const ProjectsAndServicesPage: React.FC = () => {
  const { v2: theme } = useTheme()

  const currentTitle = useTitle({
    ja: '皆さんとできること',
    en: 'What we do',
  })

  const styles = useMemo(() => {
    return {
      mainContents: css`
        display: flex;
        flex-direction: column;
        gap: 100px;
        margin-top: 55px;

        @media (min-width: ${theme.breakpoint}) {
          margin-top: 0;
        }
      `,
      mainContentSection: css`
        display: flex;
        flex-direction: column;
        gap: 100px;
      `,
      mainContent: css``,
      mainContentWithBorder: css`
        position: relative;

        &::after {
          content: '';
          position: absolute;
          display: block;
          height: 1px;
          background-color: ${theme.baseColor.blue};
          bottom: -51px;
          left: 36px;
          right: 36px;
        }

        @media (min-width: ${theme.breakpoint}) {
          &::after {
            left: 0;
            right: 0;
            width: 100%;
          }
        }
      `,
      mainContentHeading: css`
        margin: 0;
      `,
      imageContainer: css`
        border: 1px solid ${theme.baseColor.black};

        @media (min-width: ${theme.breakpoint}) {
          width: 100%;
          border-radius: 28.410795% / 50%;
          aspect-ratio: 667 / 379;
        }

        overflow: hidden;
      `,
      image: css`
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
        background-color: ${theme.baseColor.white};
      `,
      linkList: css`
        display: flex;
        flex-direction: column;
        gap: 20px;
      `,
      inSiteLink: css`
        ${theme.fontWeight.bold};
        color: ${theme.baseColor.blue};
        text-decoration: underline;
      `,
      notes: css`
        display: flex;
        flex-direction: column;
        gap: 20px;
        ${theme.text.xs};
        ${theme.fontWeight.medium};
        color: ${theme.baseColor.gray};
        list-style: none;
        padding: 0 36px;
        margin: 30px 0 0 0;

        @media (min-width: ${theme.breakpoint}) {
          padding: 0;
          text-align: center;
        }
      `,
      imageListContainer: css`
        width: 100vw;
        overflow: auto;

        @media (min-width: ${theme.breakpoint}) {
          width: auto;
        }
      `,
      imageList: css`
        box-sizing: border-box;
        width: 941px;
        width: max(941px, 100%);
        padding: 0 18px 10px 18px;

        @media (min-width: ${theme.breakpoint}) {
          padding: 0;
          width: 100%;
        }
      `,
    }
  }, [theme])

  return (
    <>
      <Helmet>
        <title>{currentTitle}</title>
      </Helmet>
      <Outer>
        <Header darkBgSelector={`.${darkBgClassName}`} />
        <div css={styles.mainContents}>
          <div css={styles.mainContentSection}>
            <MainContent
              css={styles.mainContentWithBorder}
              title={
                <Heading css={styles.mainContentHeading} subContent="What we do" customMargin={true}>
                  <Locale lang="ja">皆さんとできること</Locale>
                  <Locale lang="en">What we do</Locale>
                </Heading>
              }
              description={
                <>
                  <Locale lang="ja">
                    イノカは、人と自然の「共栄」を目指しています。海をはじめとした地域の環境が良くなれば、そこで活動する企業や生活者の持続的な発展が望めるからです。
                    <br />
                    そのために私たちは、独自の「
                    <a css={styles.inSiteLink} href="#technology">
                      環境移送技術®︎
                    </a>
                    」と、企業のアセットや技術を掛け合わせ、地域を良化していく研究開発に取り組みます。また、その成果を生活者の協力を得ながら社会実装まで持っていくサポートも行っています。
                  </Locale>
                  <Locale lang="en">
                    INNOQUA aims for Create a World where both people and nature can be better between people and
                    nature. This is because the better the environment, including the ocean.The more sustainable
                    development can be expected for the companies and local people who operate there. Ultimate goal, we
                    want to engage in research and development to improve the region by using our core technology
                    “biosphere transfer technology (®︎)”. We also provide support to bring the results to social
                    implementation with the cooperation of customers.
                  </Locale>
                </>
              }
            >
              <WhatWeDoContent />
            </MainContent>
            <MainContent
              css={styles.mainContentWithBorder}
              id="technology"
              title={
                <Heading css={styles.mainContentHeading} subContent="Core technology" customMargin={true}>
                  <Locale lang="ja">環境移送技術®︎</Locale>
                  <Locale lang="en">Biosphere Transfer Technology: ®︎</Locale>
                </Heading>
              }
              description={
                <>
                  <Locale lang="ja">
                    「環境移送技術®︎」とは、海をはじめとした水域の自然環境を、水槽を用いて陸地で再現する独自の技術コンセプトです。自社開発したAI
                    /
                    IoTデバイスを用いて、水質・水温・水流・照明環境・微生物を含む生物同士の関係性など、自然を構成する要素を構造化し、実際の環境に近い状況を作り出します。これにより、実験や解析に適した「標準的」かつ「安定・均一」な環境を、立地を選ばず構築することが可能となりました。2022年2月には、時期をコントロールしたサンゴの人工産卵に世界で初めて成功しています(※1)。
                  </Locale>
                  <Locale lang="en">
                    “Biosphere Transfer Technology ®︎” is a unique technological concept that uses aquariums to
                    reproduce the natural environment of oceans and other water ecosystem on land. Using AI / IoT
                    devices developed in-house, we structure the elements that make up nature, such as water quality,
                    water temperature, water flow, lighting environment, and relationships among organisms including
                    microorganisms, to create conditions that closely resemble the actual environment. From this
                    technology, we succeeded for the first time in the world in artificially spawning corals with
                    controlled timing (winter season) in February 2022(*1).
                  </Locale>
                </>
              }
              link={
                <>
                  <HalfRoundedButton href="/articles/dc-628g08ib9">
                    <Locale lang="ja">もっと詳しく知る</Locale>
                    <Locale lang="en">Learn more</Locale>
                  </HalfRoundedButton>
                </>
              }
            >
              <CoreTechnologyContent />
              <ul css={styles.notes}>
                <li>
                  <Locale lang="ja">
                    ※1)人工海水を使用した完全閉鎖環境下において、日本の自然界では6月に観測されるサンゴの産卵を2月にずらすことに成功。
                    <br />
                    当社の管理する水槽で3年以上飼育したサンゴを使用。
                  </Locale>
                  <Locale lang="en">
                    *1)Under a completely closed environment using artificial seawater, we succeeded in shifting the
                    coral spawning observed in June in the natural world in Japan to February. These Corals have been
                    reared in aquariums more than three years by technique of Innoqua.
                  </Locale>
                </li>
                <li>
                  <Locale lang="ja">
                    ※2)「生態圏」とは「固有の生き物・自然同士の関係(生態系)」を内包する環境全体のことを指します。
                  </Locale>
                  <Locale lang="en">
                    *2)The term “biosphere” refers to the entire environment that encompasses “the relationship between
                    creatures and nature (ecosystem).
                  </Locale>
                </li>
              </ul>
            </MainContent>
          </div>
          <div css={styles.mainContentSection}>
            <MainContent
              css={styles.mainContentWithBorder}
              id="project-01"
              title={
                <Heading css={styles.mainContentHeading} subContent="Project 01" customMargin={true}>
                  <Locale lang="ja">地域を良くしていく</Locale>
                  <Locale lang="en">Improving the community.</Locale>
                </Heading>
              }
              description={
                <>
                  <Locale lang="ja">
                    地球規模で人と自然が共栄していくためには、地域ごとに環境問題の改善と経済の持続的な発展を両立させることが必要です。イノカでは、地域の企業や自治体と連携したフィールド調査や研究活動に加え、教育プログラム等のイベントを通して環境問題の解決と地域の持続的な経済発展の両立をテーマにしたコミュニティを形成、社会実装を進めます。すなわち、個別のプレイヤーと部分的に協業するのではなく、環境問題の当事者である地域の関係者全員と、エリア全体を良くしていくアプローチに取り組んでいきます。
                  </Locale>
                  <Locale lang="en">
                    In order for create a World where both people and nature can be better on a global scale, it is
                    necessary for each region to balance the improvement of environmental problems with sustainable
                    economic development. In addition, Innoqua do field surveys and research activities with
                    collaboration with local companies and municipalities. We establish communities and promote social
                    implementation on the theme of balancing environmental problem solving and sustainable local
                    economic development through educational programs and other events. In other words, rather than
                    collaborating partially with individual players, we will work on an approach that improves the
                    entire area with all the people involved in the region who are parties to environmental issues.
                  </Locale>
                </>
              }
            >
              <div css={styles.imageListContainer}>
                <ImageList
                  css={styles.imageList}
                  horizontal={true}
                  items={useMemoOnce(() => [
                    {
                      id: 'p1-1',
                      srcL: '/images/project-01/01-l.jpg',
                      srcS: '/images/project-01/01-s.jpg',
                      alt: 'Project 01 - 1',
                    },
                    {
                      id: 'p1-2',
                      srcL: '/images/project-01/02-l.jpg',
                      srcS: '/images/project-01/02-s.jpg',
                      alt: 'Project 01 - 2',
                    },
                    {
                      id: 'p1-3',
                      srcL: '/images/project-01/03-l.jpg',
                      srcS: '/images/project-01/03-s.jpg',
                      alt: 'Project 01 - 3',
                    },
                  ])}
                />
              </div>
            </MainContent>
            <MainContent
              css={styles.mainContent}
              id="project-02"
              layout="2"
              title={
                <Heading css={styles.mainContentHeading} subContent="Project 02" customMargin={true}>
                  <Locale lang="ja">企業と良くしていく</Locale>
                  <Locale lang="en">Making it better with companies.</Locale>
                </Heading>
              }
              description={
                <>
                  <Locale lang="ja">
                    自然が持つ価値をいかに経済システムに組み込むかを示す概念「自然資本」の広がりや、2021年に国連主導で発足した「TNFD(自然関連財務情報開示タスクフォース)」など、企業にとって、自然関連の「依存・影響・リスク・機会」が、組織のビジネスモデル・戦略・財務計画に与える影響を把握しておくことが必要な時代がきています。イノカは、これらの潮流に積極的に対応する企業に対し、研究/教育のサービスを通じて、自然関連のプラスの影響とマイナスの影響を実証できる、あるいはその影響を図るための具体策を提供します。
                  </Locale>
                  <Locale lang="en">
                    With the spread of “natural capital,” a concept that shows how the value of nature can be integrated
                    into economic systems, and the UN-led Task Force on Nature-related Financial Disclosures (TNFD) to
                    be launched in 2021, companies need to understand the impact of nature-related “dependencies,
                    impacts, risks, and opportunities” on their business models, strategies, and financial plans. The
                    time has come for companies to understand the impact of nature-related “dependencies, impacts,
                    risks, and opportunities” on their business models, strategies, and financial plans. Through our
                    research and education services, Innoqua provides companies that are proactively responding to these
                    trends with concrete scientific measures that can demonstrate or measure the positive and negative
                    impacts of nature-related issues.
                  </Locale>
                </>
              }
            />
            <MainContent
              css={styles.mainContent}
              id="service-01"
              title={
                <Heading css={styles.mainContentHeading} subContent="Service 01" customMargin={true}>
                  <Locale lang="ja">研究開発事業</Locale>
                  <Locale lang="en">Research and Development</Locale>
                </Heading>
              }
              description={
                <>
                  <Locale lang="ja">
                    環境移送技術で水槽内に再現したサンゴ礁や藻場などの生態圏を用いて、企業や地域との共同研究に取り組みます。細かい天候条件の反映や、立ち入りには許可が必要な特定エリアの環境再現が可能です。水槽内に再現することで、毒性評価や、実際の海洋環境では未使用の製品・薬品を用いた実験も行うことができます。環境課題の解決に取り組む企業のアセットや技術を、具体的な解決施策につなげるための提案、実際の研究活動支援、実証した内容の分析レポート執筆まで、トータルソリューションを提供いたします。
                  </Locale>
                  <Locale lang="en">
                    We engage in joint research with companies and local communities using ecological areas such as
                    coral reefs and seaweed beds reproduced in aquarium tanks with our biosphere transfer technology. It
                    is possible to reflect detailed weather conditions and reproduce the environment of specific areas
                    where are restricted and limitedBy reproducing the environment in an aquarium tank, we can also
                    conduct toxicity assessments and experiments using products and chemicals that can not be used in
                    the actual marine environment. We provide total solutions, including proposals to link the assets
                    and technologies of companies working to solve environmental issues to specific solution measures,
                    support for actual research activities, and the writing of analysis reports on the demonstrated
                    content.
                  </Locale>
                </>
              }
            >
              <div css={styles.imageListContainer}>
                <ImageList
                  css={styles.imageList}
                  horizontal={true}
                  items={useMemoOnce(() => [
                    {
                      id: 's1-1',
                      srcL: '/images/service-01/01-l.jpg',
                      srcS: '/images/service-01/01-s.jpg',
                      alt: 'Service 01 - 1',
                    },
                    {
                      id: 's1-2',
                      srcL: '/images/service-01/02-l.jpg',
                      srcS: '/images/service-01/02-s.jpg',
                      alt: 'Service 01 - 2',
                    },
                    {
                      id: 's1-3',
                      srcL: '/images/service-01/03-l.jpg',
                      srcS: '/images/service-01/03-s.jpg',
                      alt: 'Service 01 - 3',
                    },
                  ])}
                />
              </div>
            </MainContent>
            <MainContent
              css={styles.mainContent}
              id="service-02"
              title={
                <Heading css={styles.mainContentHeading} subContent="Service 02" customMargin={true}>
                  <Locale lang="ja">
                    教育・イベント事業
                    <br />
                    (秘密研究機関イノカ)
                  </Locale>
                  <Locale lang="en">Education and Event Business</Locale>
                </Heading>
              }
              description={
                <>
                  <Locale lang="ja">
                    人と自然の共栄が長く維持されていくためには、今以上に、人々の自然や生き物への理解と愛着が育まれていく必要があると考えています。そこでイノカでは、生き物の面白さを知ってもらう教育イベントを展開しています。環境移送技術により実物を間近で見たり触れながら学べることと、独自に考案した「環境エデュテインメント(※)」という教育形式により、環境教育を楽しく主体的に学べるようにし、小学生から高校生まで幅広い年齢の学生に自然に興味を持ってもらうきっかけを作っています。
                  </Locale>
                  <Locale lang="en">
                    In order to create a World where both people and nature can be better to be maintained for a long
                    time, we believe that people need to develop a better understanding and attachment to nature and
                    living creatures than they have now. To this end, Innoqua is developing educational events to show
                    people how interesting living creatures are. Through the use of biosphere transfer technology that
                    allows people to learn by seeing and touching actual living things up close, and an educational
                    format called “environmental edutainment(*)” that we have developed ourselves. We make environmental
                    education fun and proactive, and create opportunities for students from elementary school to high
                    school, to become interested in nature.
                  </Locale>
                </>
              }
              link={
                <>
                  <HalfRoundedButton href="https://innoquacoralkidslab.innoqua.jp/">
                    <Locale lang="ja">ブランドサイトを見る</Locale>
                    <Locale lang="en">Learn more</Locale>
                  </HalfRoundedButton>
                </>
              }
            >
              <div css={styles.imageListContainer}>
                <ImageList
                  css={styles.imageList}
                  horizontal={true}
                  items={useMemoOnce(() => [
                    {
                      id: 's2-1',
                      srcL: '/images/service-02/01-l.jpg',
                      srcS: '/images/service-02/01-s.jpg',
                      alt: 'Service 02 - 1',
                    },
                    {
                      id: 's2-2',
                      srcL: '/images/service-02/02-l.jpg',
                      srcS: '/images/service-02/02-s.jpg',
                      alt: 'Service 02 - 2',
                    },
                    {
                      id: 's2-3',
                      srcL: '/images/service-02/03-l.jpg',
                      srcS: '/images/service-02/03-s.jpg',
                      alt: 'Service 02 - 3',
                    },
                  ])}
                />
              </div>
              <ul css={styles.notes}>
                <li>
                  <Locale lang="ja">※エデュテインメントとは「Entertainment」「Education」を掛け合わせた用語。</Locale>
                  <Locale lang="en">
                    *Edutainment is a term that is a cross between “Entertainment” and “Education”.
                  </Locale>
                </li>
              </ul>
            </MainContent>
          </div>
        </div>
        <ContactSection />
        <FooterSection className={darkBgClassName} />
      </Outer>
    </>
  )
}

export default ProjectsAndServicesPage
