import ReactGA from 'react-ga4'

const TRACKING_ID: string = String(process.env.REACT_APP_GA_TRACK_ID)

export function initializeAnalytics() {
  ReactGA.initialize(TRACKING_ID)
}

export function trackPageView(page: any) {
  ReactGA.send({ hitType: 'pageview', page: window.location.pathname + window.location.search, title: document.title })
}
