import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { css, useTheme } from '@emotion/react'
import { ClientPortal } from './client-portal'
import debounce from 'lodash.debounce'
import { AnimatePresence, motion, useAnimationControls } from 'framer-motion'
import clsx from 'clsx'
import { Locale } from './locale'
import { I18nLink } from '../components/i18n-link'
import { UnstyledButton } from './button'
import { MenuLink, Menu } from './menu'

const menuAnimationVariants = {
  hidden: {
    opacity: 0,
  },
  enter: {
    opacity: 1,
  },
  exit: {
    opacity: 0,
  },
}

const menuAnimationTransition = {
  opacity: {
    duration: 0.3,
  },
}

const headerAnimationVariants = {
  hidden: {
    opacity: 0,
  },
  enter: {
    opacity: 1,
  },
}

const headerAnimationTransition = {
  delay: 1.2,
  opacity: {
    duration: 1,
  },
}

export type HeaderProps = {
  darkBgSelector?: string
  hasDarkBgByDefault?: boolean
}

export const Header: React.FC<HeaderProps> = ({ darkBgSelector, hasDarkBgByDefault = false }) => {
  const { v1: theme } = useTheme()
  const [showMenu, setShowMenu] = useState(false)
  const animationControls = useAnimationControls()

  const initialAnimationLockRef = useRef(true)
  const headerRef = useRef<HTMLElement>(null)
  const headerInnerRef = useRef<HTMLDivElement>(null)
  const scrollParamsRef = useRef<{
    beforeDirection: 'up' | 'down' | 'none'
    beforeScrollTop: number
  }>({
    beforeDirection: 'none',
    beforeScrollTop: 0,
  })

  const hasDarkBgClass = '--has-dark-background'
  const [containerClass, setContainerClass] = useState<string | null>(hasDarkBgByDefault ? hasDarkBgClass : null)

  const runBgCheck = useMemo(
    () =>
      debounce(
        () => {
          if (darkBgSelector == null) {
            return
          }

          const el = headerInnerRef.current

          if (el == null) {
            return
          }

          const targets = Array.from(document.querySelectorAll(darkBgSelector))

          if (targets.length === 0) {
            return
          }

          const rect = el.getBoundingClientRect()

          const isOverwrapping = targets.some((target) => {
            const targetRect = target.getBoundingClientRect()
            const [lx, rx] = targetRect.x > rect.x ? [rect, targetRect] : [targetRect, rect]
            const [ly, ry] = targetRect.y > rect.y ? [rect, targetRect] : [targetRect, rect]
            const width = lx.x + lx.width - rx.x
            const height = ly.y + ly.height - ry.y

            return width > rect.width * 0.2 && height > rect.height * 0.2
          })

          if (isOverwrapping) {
            setContainerClass(hasDarkBgClass)
          } else {
            setContainerClass(null)
          }
        },
        30,
        {
          maxWait: 300,
        },
      ),
    [darkBgSelector],
  )

  const handleAnimation = useMemo(
    () =>
      debounce(
        (e: Event) => {
          const el = headerRef.current

          if (el == null) {
            return
          }

          if (initialAnimationLockRef.current) {
            return
          }

          const { height } = el.getBoundingClientRect()
          const { beforeScrollTop, beforeDirection } = scrollParamsRef.current
          const scrollTop = document.documentElement.scrollTop
          const direction = beforeScrollTop < scrollTop ? 'down' : 'up'

          if (direction === 'down' && scrollTop < height * 2) {
            return
          }

          scrollParamsRef.current.beforeDirection = direction
          scrollParamsRef.current.beforeScrollTop = scrollTop

          if (beforeDirection === direction) {
            return
          }

          const top = direction === 'down' ? -height : 0
          const duration = direction === 'down' ? 0.5 : 0.4

          animationControls.stop()

          const id = setInterval(direction === 'up' ? runBgCheck : () => {}, 30)

          animationControls
            .start({
              top: `${top}px`,
              opacity: 1,
              transition: {
                duration,
                ease: 'easeInOut',
              },
            })
            .then(() => {
              clearInterval(id)
              runBgCheck()
            })
        },
        200,
        {
          maxWait: 500,
        },
      ),
    [animationControls, runBgCheck],
  )

  const handleMenuOpen = useCallback<React.MouseEventHandler<HTMLButtonElement>>((e) => {
    e.preventDefault()

    setShowMenu(true)
  }, [])

  const handleMenuClose = useCallback(() => {
    setShowMenu(false)
  }, [])

  useEffect(() => {
    window.addEventListener('resize', runBgCheck)
    window.addEventListener('scroll', runBgCheck)

    return () => {
      window.removeEventListener('resize', runBgCheck)
      window.removeEventListener('scroll', runBgCheck)
    }
  }, [runBgCheck])

  useEffect(() => {
    window.addEventListener('scroll', handleAnimation)

    return () => {
      window.removeEventListener('scroll', handleAnimation)
    }
  }, [handleAnimation])

  useEffect(() => {
    initialAnimationLockRef.current = true

    animationControls.start('enter').then(() => {
      initialAnimationLockRef.current = false

      runBgCheck()
    })
  }, [animationControls, runBgCheck])

  return (
    <>
      <ClientPortal selector="#menu">
        <AnimatePresence>
          {showMenu && (
            <motion.div
              initial="hidden"
              animate="enter"
              exit="exit"
              transition={menuAnimationTransition}
              variants={menuAnimationVariants}
            >
              <Menu onClose={handleMenuClose} />
            </motion.div>
          )}
        </AnimatePresence>
      </ClientPortal>
      <motion.header
        ref={headerRef}
        initial="hidden"
        animate={animationControls}
        transition={headerAnimationTransition}
        variants={headerAnimationVariants}
        css={css`
          box-sizing: border-box;
          padding: 20px 16px 0 16px;
          position: fixed;
          top: 0;
          width: 100%;
          z-index: 10;

          @media (min-width: ${theme.breakpoint}) {
            padding: 56px 34px 0 34px;
          }
        `}
        className={clsx(containerClass)}
      >
        <div
          ref={headerInnerRef}
          css={css`
            box-sizing: border-box;
            display: flex;
            align-items: center;
            justify-content: space-between;
            position: relative;
            margin: 0 auto;
          `}
        >
          <h1
            css={css`
              margin: 0;
              padding: 0;
              line-height: 0;
              height: 30px;
              aspect-ratio: 567.280029296875 / 159.50001525878906;
              position: relative;

              @media (min-width: ${theme.breakpoint}) {
                height: 40px;
              }
            `}
          >
            <I18nLink
              css={css`
                display: block;
                width: 100%;
                height: 100%;
                background-image: url(/images/logo-black.svg);
                background-repeat: no-repeat;
                background-position: center;
                background-size: cover;

                .${hasDarkBgClass} & {
                  background-image: url(/images/logo-white.svg);
                }
              `}
              to="/"
            />
          </h1>
          <nav
            css={css`
              display: flex;
              align-items: center;
              gap: 26px;
            `}
          >
            <div>
              <Locale lang="ja">
                <MenuLink
                  variant="header"
                  locale="en"
                  css={css`
                    .${hasDarkBgClass} & {
                      color: ${theme.baseColor.white};
                    }
                  `}
                >
                  EN
                </MenuLink>
              </Locale>
              <Locale lang="en">
                <MenuLink
                  variant="header"
                  locale="ja"
                  css={css`
                    .${hasDarkBgClass} & {
                      color: ${theme.baseColor.white};
                    }
                  `}
                >
                  JA
                </MenuLink>
              </Locale>
            </div>
            <div>
              <UnstyledButton onClick={handleMenuOpen}>
                <span
                  css={css`
                    display: block;
                    width: 100%;
                    min-width: 29.495px;
                    min-height: 21.246px;
                    aspect-ratio: 29.495 / 21.246;
                    background-image: url(/images/menu-open-black.svg);
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: cover;

                    .${hasDarkBgClass} & {
                      background-image: url(/images/menu-open-white.svg);
                    }
                  `}
                />
              </UnstyledButton>
            </div>
          </nav>
        </div>
      </motion.header>
    </>
  )
}
